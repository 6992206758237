export function filterObject(object, search, key) {
  return (object[key] || "").toLowerCase().includes(search.toLowerCase());
}
export function encode(string) {
  return btoa(string);
}
export function decode(string) {
  return atob(string);
}

export const datetime = {
  add2Date(date, hours, minutes) {
    const dateInstance = new Date(date ?? "");
    dateInstance.setHours(dateInstance.getHours() + hours);
    dateInstance.setMinutes(dateInstance.getMinutes() + minutes);
    return dateInstance.toDateString();
  },
  isAfterDate(date, secondDate) {
    return new Date(date).getTime() > new Date(secondDate).getTime();
  },
};

export function textToSlug(text) {
  return text
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters except hyphens
    .replace(/\/+/g, "") // Remove all forward slashes
    .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
    .replace(/^-+|-+$/g, ""); // Trim hyphens from the start and end
}
