<template>
  <div class="d-flex align-items-center justify-content-center body">
    <div
      class="content-card col-10 col-lg-6 d-flex flex-column-reverse flex-column flex-lg-row"
    >
      <div class="left-section">
        <h1>Outstanding Subscription</h1>
        <div class="plan_cover" v-if="plan?.plan">
          <h3>Plan: {{ plan?.plan.plan_name }}</h3>
          <p v-if="plan?.type == 'Monthly' && plan?.custom_plan == false">
            Amount Due: {{ plan?.plan?.monthly_price }}
          </p>
          <p v-if="plan?.type == 'Yearly' && plan?.custom_plan == false">
            Amount Due: {{ plan?.plan?.yearly_price }}
          </p>
          <p v-if="plan?.custom_plan == true">
            Amount Due: {{ plan?.plan?.price }}
          </p>
          <ul class="features-list">
            <p>Plans Features</p>

            <li v-for="ft in plan?.plan?.features" :key="ft">
              {{ ft }}
            </li>
          </ul>
          <p>Due Date: {{ plan?.end_date }}</p>
          <!-- <p>Due Date: December 10, 2024</p> -->
          <!-- <p>Description:<br> {{ plan?.plan.description }}</p> -->
        </div>
        <div class="plan_cover" v-else>
          <h2>You are on trial period</h2>
        </div>
        <p>
          Ensure seamless healthcare service management by promptly completing
          your payment.
        </p>

        <br />
        <div class="noti-footer">
          <button class="request-button" @click="showSubscriptionModal()">
            Change plan
          </button>
        </div>
      </div>
      <div class="right-section">
        <div class="payment-options flex-column flex-md-row flex-lg-row">
          <button class="payment-btn active" data-target="bank-transfer">
            Bank Transfer
          </button>
          <button class="payment-btn" data-target="paystack">Paystack</button>
          <!-- <button class="payment-btn" data-target="card-payment">
            Card Payment
          </button> -->
        </div>
        <div id="card-payment" class="form-container" hidden>
          <h2>Card Payment</h2>
          <form>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                required
              />
            </div>
            <div class="form-group">
              <label for="card-info">Card Information</label>
              <input
                type="text"
                id="card-info"
                placeholder="1234 1234 1234 1234"
                required
              />
            </div>
            <div class="form-group">
              <label for="expiry-date">Expiry Date</label>
              <input
                type="text"
                id="expiry-date"
                placeholder="MM / YY"
                required
              />
            </div>
            <div class="form-group">
              <label for="cvc">CVC</label>
              <input type="text" id="cvc" placeholder="CVC" required />
            </div>
            <button type="submit" class="button">Pay Now</button>
          </form>
        </div>
        <div id="bank-transfer" class="form-container active">
          <h2>Bank Transfer</h2>
          <p>Please transfer the payment to the account details below:</p>
          <p><strong>Bank:</strong> Fidelity Bank</p>
          <p><strong>Account Name:</strong> StellaSync Ltd</p>
          <p><strong>Account Number:</strong> 5601358960</p>
          <p>
            After payment, upload proof of payment via our
            <a href="mailto:hello@stellasync.com">support page</a>.
          </p>
        </div>
        <div id="paystack" class="form-container">
          <h2>Paystack Payment</h2>
          <p>Click the button below to complete your payment using Paystack:</p>
          <button class="button" @click="reveal_modal()">
            Pay with Paystack
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="button back-button" @click="go_back_to_origin()">
    Back to Origin
  </button>

  <!-- modal -->
  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster under_dev"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <br />
          <div class="form-header">
            <h3>Feature Coming Soon</h3>
            <p>Under Development</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ReactiveFlags } from "vue";
import Loader from "../plugins/Loader.vue";

export default {
  name: "Suspension_Page",
  components: {
    Loader,
  },

  data() {
    return {
      plan: JSON.parse(localStorage.getItem("current_plan")),
      last_route_to_here: localStorage.getItem("last_route_from_sub"),
    };
  },

  methods: {
    showSubscriptionModal() {
      $(".sub_cont").modal("show");
    },
    reveal_modal() {
      $(".under_dev").modal("show");
    },
    go_back_to_origin() {
      window.location = this.last_route_to_here;
    },
  },

  mounted() {
    const paymentButtons = document.querySelectorAll(".payment-btn");
    const forms = document.querySelectorAll(".form-container");

    paymentButtons.forEach((btn) => {
      btn.addEventListener("click", () => {
        // Remove active class from all buttons and forms
        paymentButtons.forEach((b) => b.classList.remove("active"));
        forms.forEach((form) => form.classList.remove("active"));

        // Add active class to the clicked button and corresponding form
        btn.classList.add("active");
        document
          .getElementById(btn.getAttribute("data-target"))
          .classList.add("active");
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 20px;
      background-color: #f9f9f9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    } */
/* .body {
  min-height: 100vh;
} */
.content-card {
  background: #fff;
  border: 1px solid #ddd;
  /* padding: 20px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10%;
}

.content-card .left-section {
  background-color: #008c76;
  color: white;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 0px; */
  /* width: 40%; */
}

.content-card .left-section h1 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.content-card .left-section p {
  font-size: 1rem;
  margin: 5px 0;
}

.content-card .right-section {
  flex: 1.5;
  padding: 20px;
}

.payment-options {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.payment-options button {
  padding: 10px 15px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.payment-options button.active {
  background-color: #008c76;
  color: white;
  border: none;
}

.form-container {
  display: none;
}

.form-container.active {
  display: block;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.button {
  display: inline-block;
  width: 100%;
  padding: 15px;
  background-color: #008c76;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}
.back-button {
  display: inline-block;
  width: 20%;
  background-color: #008c76;
  border-radius: 15px;
  color: white;
  padding: 5px;
  margin-left: 40%;
  margin-top: 50px;
}
.button:hover {
  background-color: #007362;
}

.info {
  font-size: 0.9rem;
  color: #888;
  margin-top: 15px;
}

.features-list {
  /* list-style: none; */
  margin: 20px 0;
  margin-top: 5px;
  /* text-align: center; */
}

@media (max-width: 800px) {
  .back-button {
    width: 50%;
    padding: 20px;
    margin-left: 25%;
  }
}

@media (max-width: 620px) {
}
@media (max-width: 480px) {
  .back-button {
    width: 50%;
    margin-left: 25%;
    padding: 20px;
    margin-bottom: 20px;
  }
}
</style>
