import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Toaster from "@meforma/vue-toaster";
import { DatePicker } from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "animate.css";
// import antTableMixin from '@/mixins/antTableMixin'
// import Vue from 'vue';


// css files
import "./assets/assets/css/bootstrap.min.css";
import "./assets/assets/css/select2.min.css";
import "./assets/assets/css/feather.css";
import "./assets/assets/css/styleg.css";
import "./assets/assets/css/style.css";
import "./assets/assets/css/override.css";
import "./assets/assets/css/video.css";

// css plugins
import "./assets/assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/assets/plugins/fontawesome/css/all.min.css";
// import "./assets/assets/plugins/datatables/datatables.min.css";

// tinymce
import "../public/tinymce/tinymce.min.js"; // INFO: don't just move the folder some things are tied to it
// import "../public/mammoth.browser"; 
// import "../public/pdf_viewer"; 
// js plugins
import "./assets/assets/js/jquery-3.7.1.min.js";
import "./assets/assets/js/bootstrap.bundle.min.js";
import "./assets/assets/js/feather.min.js";
// import "./assets/assets/js/jquery.slimscroll.js";

//.... for home page
import "./assets/assets/js/select2.min.js";
import "./assets/assets/plugins/datatables/jquery.dataTables.min.js";
import "./assets/assets/plugins/datatables/datatables.min.js";

import "./assets/assets/js/jquery.waypoints.js";
import "./assets/assets/js/jquery.counterup.min.js";
import "./assets/assets/plugins/apexchart/apexcharts.min.js";
import "./assets/assets/plugins/apexchart/chart-data.js";

// specific to a page
// doctor dashboard
import "./assets/assets/js/circle-progress.min.js";

// edit and add doct dependencies
import "./assets/assets/css/bootstrap-datetimepicker.min.css";
import "./assets/assets/plugins/moment/moment.min.js";
import "./assets/assets/js/bootstrap-datetimepicker.min.js";

// Email dependencies

import "./assets/assets/plugins/summernote/summernote-bs5.min.css";
import "./assets/assets/plugins/summernote/summernote-bs5.min.js";

import "./assets/assets/plugins/fileupload/fileupload.min.js";

import "./assets/assets/js/app.js";

import VueApexCharts from "vue3-apexcharts";
import Antd from "ant-design-vue";
import Countries from "./components/plugins/Countries.vue";
import FilterTable from "./components/plugins/table/filter-table.vue";
import Summernote from "./components/plugins/Summernote.vue";
import NoteHistoryModal from "./components/plugins/NoteHistoryModal.vue";



const app = createApp(App);

app.component("note-history-modal", NoteHistoryModal);
app.component("summernote", Summernote);
app.component("filter-table", FilterTable);
// app.directive('single-row', antTableMixin);

app.component("countries", Countries);
app.use(VueApexCharts);
app.use(Antd);
app.use(store);
app.use(Toaster);
app.use(DatePicker);
app.use(router).mount("#app");
