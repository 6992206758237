<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/Video-call">App </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Video Call</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-xl-4 d-flex">
          <div class="card chat-box">
            <div class="chat-widgets">
              <div class="call-listing" id="chatList">
                <!-- imported -->
                <!-- Tabs -->
                <div class="tabs">
                  <button class="tab-button active" data-tab="contacts">
                    Appointments
                  </button>
                  <button class="tab-button" data-tab="history">
                    Call History
                    <span class="badge badge-danger" id="call_badge">2</span>
                  </button>
                </div>

                <!-- Search Bar -->
                <div class="search-bar">
                  <input type="text" id="searchInput" placeholder="Search..." />
                </div>

                <!-- Contacts Tab -->
                <div class="tab-content active" id="contacts">
                  <ul class="list" id="contactsList">
                    <li v-for="apt in appointmentList" :key="apt">
                      <img v-if="apt.patient.avatar != 'null'" :src="apt.patient.avatar" alt="User Image" />
                      <img v-else src="../../assets/assets/img/profiles/avatar-01.jpg" alt="User Image" />
                      <div class="info">
                        <h3>{{ apt.full_name }}</h3>
                        <p v-if="apt.patient_type != 'in_patient'">
                          <span class="btn btn-dark">{{
                            formatText(apt.patient_type)
                          }}</span>
                        </p>
                      </div>
                      <div class="actions">
                        <span class="call-action fa"><i class="fas fa-phone"></i></span>
                        <span class="call-action fa" @click="make_video_call(apt.patient.email)"><i
                            class="fas fa-video"></i></span>
                        <span @click="viewChat(apt.patient.email)" class="call-action message-btn"><i
                            class="fas fa-comment"></i></span>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Call History Tab -->
                <div class="tab-content" id="history">
                  <ul class="list" id="historyList">
                    <li>
                      <img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="User Image" />
                      <div class="info">
                        <h3>Stephanie J. Terry</h3>
                        <p>Last call: 10:30 AM</p>
                      </div>
                      <div class="actions">
                        <span class="call-btn fa" style="padding-top: 8px" @click="make_video_call()"><i
                            class="fas fa-video text-light"></i></span>
                        <span class="delete-btn">❌</span>
                      </div>
                    </li>
                    <li>
                      <img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="User Image" />
                      <div class="info">
                        <h3>Doyle J. Ackerson</h3>
                        <p>Missed call: 9:45 AM</p>
                      </div>
                      <div class="actions">
                        <span class="call-btn fa" style="padding-top: 8px" @click="make_video_call()"><i
                            class="fas fa-video text-light"></i></span>
                        <span class="delete-btn">❌</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- imported -->
              </div>
              <div id="chatPage">
                <div class="chat-header">
                  <span class="back-btn" @click="goBack()">&#8592;</span>
                  <div style="display: flex; align-items: center">
                    <img src="https://via.placeholder.com/40" alt="User" />
                    <h4>Chat with <span id="chatUser">Kate</span></h4>
                  </div>
                  <!-- <i class="fas fa-ellipsis-v"></i> -->
                </div>
                <div class="chat-content">
                  <div class="message incoming">
                    <p>
                      Hi Doctor! hope you are available, how do i take thesen
                      drugs!
                    </p>
                  </div>
                  <div class="message outgoing">
                    <p>Yes, sure!</p>
                  </div>
                  <div class="message incoming">
                    <p>Cool! What's your email address?</p>
                  </div>
                  <div class="message incoming">
                    <p>
                      Hi Doctor! hope you are available, how do i take thesen
                      drugs!
                    </p>
                  </div>
                  <div class="message outgoing">
                    <p>Yes, sure!</p>
                  </div>
                  <div class="message incoming">
                    <p>Cool! What's your email address?</p>
                  </div>
                </div>
                <div class="chat-footer">
                  <input type="text" placeholder="Enter your message..." />
                  <button><i class="fas fa-paper-plane"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card chat-box big-chat" id="big-chat">
            <div id="chatc">
              <div class="chat-header">
                <span class="back-btn" @click="goBack()">&#8592;</span>
                <div style="display: flex; align-items: center">
                  <img src="https://via.placeholder.com/40" alt="User" />
                  <h4>Chat with <span id="chatUser">Kate</span></h4>
                </div>
                <!-- <i class="fas fa-ellipsis-v"></i> -->
              </div>
              <div class="chat-content">
                <div class="message incoming">
                  <p>
                    Hi Doctor! hope you are available, how do i take thesen
                    drugs!
                  </p>
                </div>
                <div class="message outgoing">
                  <p>Yes, sure!</p>
                </div>
                <div class="message incoming">
                  <p>Cool! What's your email address?</p>
                </div>
                <div class="message incoming">
                  <p>
                    Hi Doctor! hope you are available, how do i take thesen
                    drugs!
                  </p>
                </div>
                <div class="message outgoing">
                  <p>Yes, sure!</p>
                </div>
                <div class="message incoming">
                  <p>Cool! What's your email address?</p>
                </div>
              </div>
              <div class="chat-footer">
                <input type="text" placeholder="Enter your message..." />
                <button><i class="fas fa-paper-plane"></i></button>
              </div>
            </div>
          </div>
          <div class="card chat-box" id="video-call-section">
            <div class="chat-search-group">
              <div class="chat-user-group clinic-user mb-0 d-flex align-items-center">
                <div class="img-users call-user" id="user_image">
                  <a href="#"><img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="img" /></a>
                  <!-- <span class="active-users bg-info"></span> -->
                </div>
                <div class="chat-users" v-if="call?.user?.name">
                  <div class="user-titles">
                    <h5>{{ call.user.name }}</h5>
                  </div>
                  <div class="user-text">
                    <p>Appointment Description</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- header end -->
            <!-- Join Call -->
            <div class="page-content">
              <div class="meeting">
                <div class="meeting-wrapper">
                  <div class="meeting-list">
                    <!-- Horizontal View -->

                    <div :class="`mt-4 largeVideo ${call.status == callStatus.joinedCall && 'callIsAnswered'
                      }`">
                      <div class="fullHeight d-flex align-items-center justify-content-center"
                        v-if="call.status == callStatus.neutral">
                        <div class="col-5 text-center">
                          <a-empty description="" />
                          <p class="fs-5">
                            To Initiate a Call <i class="fa fa-phone"></i>,
                            Click on the Call Button in the Appointment List by
                            the Side
                          </p>
                        </div>
                      </div>
                      <template v-else-if="call.status == callStatus.joinedCall">
                        <div class="backdrop" v-if="call.remoteBackdropText">
                          <span> {{ call.remoteBackdropText }} </span>
                        </div>
                        <video id="remoteVideo" autoplay=""></video>
                        <div class="smallVideo">
                          <video id="localVideo" autoplay="" muted></video>
                          <div class="backdrop" v-if="call.localBackdropText">
                            <span>
                              {{ call.localBackdropText }}
                            </span>
                          </div>
                        </div>
                        <div class="controls rightControls d-flex">
                          <button :class="`btn control ${call.cameraOff ? 'active' : ''
                            }`" @click="toggleCameraOff()">
                            <i class="fas fa-video-slash text-white"></i>
                          </button>
                          <button class="btn control" @click="kestrionConfig.switchCamera()">
                            <i class="fas fa-camera-rotate text-white"></i>
                          </button>
                          <button :class="`btn control ${call.muted ? 'active' : ''}`" @click="toggleMute()">
                            <i :class="`fas fa-microphone-slash text-white`"></i>
                          </button>
                          <button class="btn control danger" @click="endCall()">
                            <i class="fas fa-phone-volume text-white"></i>
                          </button>
                        </div>
                        <div class="controls topControls d-flex">
                          <div class="control d-flex gap-2 align-items-center">
                            <i class="fas fa-circle-dot text-danger recordingIcon fs-6"></i>
                            <span>{{ getTimeFromNumber() }}</span>
                            <span>{{ call.remoteAudio ? "" : "MUTED" }}</span>
                          </div>
                        </div>
                      </template>
                      <div class="fullHeight d-flex align-items-center justify-content-center" v-else>
                        <div class="text-center d-flex flex-column align-items-center gap-5">
                          <RadarBtn />
                          <h2 class="d-flex align-items-center gap-2">
                            <div class="d-flex align-items-center gap-2">
                              <span class="user-img" style="width: 40px; height: 40px">
                                <img :src="call.user?.avatar" />
                              </span>
                              <span class="text-primary">{{
                                call.user?.name
                              }}</span>
                            </div>
                            {{
                              call.status == callStatus.receivedRequest
                                ? "is trying to Join"
                                : "is calling you"
                            }}
                          </h2>
                          <div class="d-flex gap-3">
                            <button class="btn btn-primary" @click="answerCall()">
                              <i class="fas fa-phone text-white"></i>
                              &nbsp;Answer
                            </button>
                            <button class="btn btn-danger" @click="hangUpCall()">
                              <i class="fas fa-phone-slash text-white"></i>
                              &nbsp;Hang Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- /Horizontal View -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /Join Call -->
          </div>
          <!-- /Chat -->
        </div>
      </div>
    </div>

    <!-- initiate call modal -->
    <div id="initiate_call" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="text-center">Initiate a video call</h2>
          </div>

          <div class="modal-body">
            <p>
              You can either make a direct call to this patient or share lik for
              other to join.
            </p>

            <div class="link-section">
              <label>Link</label>
              <div class="input-group">
                <input type="text" id="callLink" :value="call.generatedLink" readonly />
                <button class="copy-btn" id="copyButton">
                  <span class="fa fa-copy"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="share-btn" id="shareButton">Share Call Link</button>
            <button class="direct-call-btn" @click="startDirectCallUser()">
              Start Direct Call
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { kestrionConfig } from "@/kestrionConfig";
import { store } from "@/store";
import { ref } from "vue";
import { onMounted } from "vue";
import RadarBtn from "../plugins/RadarBtn.vue";
import { reactive } from "vue";

const callStatus = {
  receivedCall: 1,
  receivedRequest: 2,
  joinedCall: 3,
  neutral: 0,
};
// states
const call = reactive({
  secondsIntervalId: null,
  muted: false,
  cameraOff: false,
  seconds: 0,
  localBackdropText: null,
  remoteBackdropTimeoutId: null,
  remoteBackdropText: null,
  remoteAudio: true,
  user: { uuid: null, name: null },
  status: callStatus.neutral,
  generatedLink: null,
  receivedRequest: false,
});
const appointmentList = ref({});
const inacall = ref({});

function getTimeFromNumber() {
  const minutes = Math.floor(call.seconds / 60);
  const seconds = call.seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

function make_video_call(userUUID) {
  call.user.uuid = userUUID;
  generateCallLink(userUUID);
  $("#initiate_call").modal("show");
}

function viewChat() {
  if (inacall.value == true) {
    const chatList = document.getElementById("chatList");
    const chatPage = document.getElementById("chatPage");
    chatList.style.display = "none";
    chatPage.style.display = "flex";
  }

  else {
    const chatPage = document.getElementById("big-chat");
    const video_call_section = document.getElementById("video-call-section");
    chatPage.style.display = "flex";
    video_call_section.style.display = "none";

  }
}

// doctor accepted appointemnt end point
function getDoctorApprovedAppontmentList() {
  store
    .dispatch("doctor/getDoctorApprovedAppontmentList")
    .then((response) => {
      if (response.data.status == true) {
        appointmentList.value = response.data.data;
        appointmentList.value = appointmentList.value.filter(
          (patient) => patient.patient_type == 'in_patient'
        );
      }
    })
    .catch((error) => {
      console.log(error);
      console.log("Error fetching data", error);
    });
}
function formatText(text) {
  const formatted_text =
    text.charAt(0).toUpperCase() +
    text.slice(1).replace("_", " ").replace("p", "P");
  return formatted_text;
}
function goBack() {
  if (inacall.value == true) {
    const chatList = document.getElementById("chatList");
    const chatPage = document.getElementById("chatPage");
    chatPage.style.display = "none";
    chatList.style.display = "block";
  }
  else {
    const video_call_section = document.getElementById("video-call-section");
    const chatPage = document.getElementById("big-chat");
    chatPage.style.display = "none";
    video_call_section.style.display = "block";

  }
}

/*** CALL METHODS ***********/
// hang up call
function hangUpCall() {
  kestrionConfig.rejectCall();
  call.user = { name: null, uuid: null };
  call.status = callStatus.neutral;
}
function initializeCall() {
  call.status = callStatus.joinedCall;
  setTimeout(() => {
    // Set the video elements
    kestrionConfig.localVideo = document.getElementById("localVideo");
    kestrionConfig.remoteVideo = document.getElementById("remoteVideo");
    // start broadcasting to use camera
    kestrionConfig.startBroadcasting();
  }, 1000);
}
function generateCallLink(userUUID) {
  const userName = localStorage.getItem("name");
  call.generatedLink = kestrionConfig.generateCallLink(
    { uuid: userUUID },
    `Stellasync Appointment with ${userName}`
  );
}
function startDirectCallUser() {
  if (!call.user.uuid) {
    return;
  }
  initializeCall();
  setTimeout(() => {
    kestrionConfig.callUser(call.user.uuid);
    $("#initiate_call").modal("hide");
  }, 1000);
}
// mute or unmute
function toggleMute() {
  kestrionConfig.toggleMute();
  call.muted = kestrionConfig.isMicMuted();
}
function toggleCameraOff() {
  kestrionConfig.toggleCamera();
  call.cameraOff = kestrionConfig.isCameraOff();
  if (call.cameraOff) {
    call.localBackdropText = "Camera Off";
  } else {
    call.localBackdropText = null;
  }
}
// end call
function endCall() {
  kestrionConfig.endCall();
}
// answer a call
function answerCall() {
  initializeCall();
  setTimeout(() => {
    if (call.receivedRequest) {
      kestrionConfig.acceptRequest();
    } else {
      kestrionConfig.acceptCall();
    }
  }, 1000);
  startCounting();
}
function startCounting() {
  clearInterval(call.secondsIntervalId);
  call.secondsIntervalId = setInterval(() => {
    call.seconds += 1;
  }, 1000);
}
async function requestPermissions() {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    console.log("Camera and audio permissions granted.");
  } catch (error) {
    console.error("Permission denied or error occurred:", error);
  }
}
onMounted(async () => {
  getDoctorApprovedAppontmentList();
  await requestPermissions();
  // kestrion events
  kestrionConfig.on("connect", ({ _userUUID }) => { });
  kestrionConfig.on("callReceived", ({ name, uuid }) => {
    call.user = { name, uuid };
    call.status = callStatus.receivedCall;
    call.remoteBackdropText = null;
  });
  kestrionConfig.on("acceptRequestReceived", ({ sender, sessionId }) => {
    call.user.name = sender.name;
    call.user.uuid = sender.uuid;
    call.receivedRequest = true;
    call.remoteBackdropText = null;

    call.status = callStatus.receivedRequest;
  });
  kestrionConfig.on("acceptRequestCanceled", () => {
    call.user.name = null;
    call.user.uuid = null;
    call.receivedRequest = true;
    call.status = callStatus.neutral;
  });
  kestrionConfig.on("userCameraOff", () => {
    call.remoteBackdropText = "Camera Off";
  });
  kestrionConfig.on("userCameraOn", () => {
    call.remoteBackdropText = null;
  });
  kestrionConfig.on("userAudioOn", () => {
    call.remoteAudio = true;
  });
  kestrionConfig.on("userAudioOff", () => {
    call.remoteAudio = false;
  });
  kestrionConfig.on("callAccepted", () => {
    startCounting();
  });
  kestrionConfig.on("callEnded", () => {
    call.seconds = 0;
    clearInterval(call.secondsIntervalId);
    call.status = callStatus.neutral;
  });

  kestrionConfig.on("userNetworkNotStable", () => {
    call.remoteBackdropText = "User Network Not Stable";
    //
    clearTimeout(call.remoteBackdropTimeoutId);
    call.remoteBackdropTimeoutId = setTimeout(() => {
      call.remoteBackdropText = null;
    }, 2000);
  });
  kestrionConfig.on("userDisconnected", () => {
    call.remoteBackdropText = "User Disconnected";
  });
  // imported##########################
  const tabs = document.querySelectorAll(".tab-button");
  const contents = document.querySelectorAll(".tab-content");
  const searchInput = document.getElementById("searchInput");

  // Tab Switching Logic
  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      tabs.forEach((t) => t.classList.remove("active"));
      contents.forEach((c) => c.classList.remove("active"));

      tab.classList.add("active");
      document.getElementById(tab.dataset.tab).classList.add("active");
    });
  });

  // Search Functionality
  searchInput.addEventListener("input", () => {
    const query = searchInput.value.toLowerCase();
    const activeTab = document.querySelector(".tab-content.active");
    const items = activeTab.querySelectorAll("li");

    items.forEach((item) => {
      item.style.display = item.textContent.toLowerCase().includes(query)
        ? "flex"
        : "none";
    });
  });

  // Action Buttons
  document.querySelectorAll(".delete-btn").forEach((button) => {
    button.addEventListener("click", (e) => {
      const listItem = e.target.closest("li");
      listItem.remove();
    });
  });
  // end of imported

  //code for coping initiated call link
  const copyButton = document.getElementById("copyButton");
  const callLinkInput = document.getElementById("callLink");
  // const alertMessage = document.getElementById("alertMessage");

  // Copy link to clipboard
  copyButton.addEventListener("click", () => {
    callLinkInput.select();
    document.execCommand("copy");
    // alert("Call link copied!");

    // Show alert message
    // alertMessage.classList.remove("hidden");
    // alertMessage.classList.add("visible");

    // Hide alert after 2 seconds
    // setTimeout(() => {
    //   alertMessage.classList.remove("visible");
    //   alertMessage.classList.add("hidden");
    // }, 2000);
  });

  // Share functionality (dummy alert)
  const shareButton = document.getElementById("shareButton");
  shareButton.addEventListener("click", () => {
    //     $toast.error("Call link shared!", {
    //   position: "top-right", // Override position for this toast
    // });
    // alert("Call link shared!");
  });
});
</script>

<style scoped>
/* importerd */
/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  width: 90%;
  max-width: 400px;
  margin: 20px auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Tabs */
.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  flex: 1;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  font-weight: bold;
  border-right: 1px solid #14a79e;
  border-top-right-radius: 15px;
}

.tab-button.active {
  border-bottom: 2px solid #14a79e;
  color: #14a79e;
}

/* Search Bar */
.search-bar {
  padding: 10px;
  /* border-bottom: 1px solid #ddd; */
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Tab Content */
.tab-content {
  display: none;
  padding: 10px;
}

.tab-content.active {
  display: block;
}

/* List Items */
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.list li img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.info h3 {
  margin: 0;
  font-size: 16px;
}

.info p {
  margin: 2px 0;
  font-size: 14px;
  color: #666;
}

.actions {
  margin-left: auto;
  display: flex;
  gap: 5px;
}

.actions * {
  color: #14a79e !important;
}

.actions span,
.actions a {
  color: #14a79e !important;
  border: 1px solid #14a79e;
  padding: 10px;
  border-radius: 8px;

  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions span.delete-btn {
  background: #14a79e;
}

#call_badge {
  border-radius: 50%;
}

#contacts {
  height: 480px;
  overflow-y: scroll;
}

/* imported */

/* call modal import */
/* .modal {
  width: 90%;
  max-width: 400px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-body {
  margin: 20px 0;
} */

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  display: block;
  text-align: center;
}

.modal-header p {
  /* margin-top: 5px; */
  font-size: 14px;
  color: #666;
  display: block;
  padding: 20px;
}

.link-section,
.dial-section {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.input-group input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input-group button {
  padding: 8px;
  background-color: #14a79e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-group button.call-btn {
  background-color: #28a745;
}

a.change-country {
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
  color: #14a79e;
  text-decoration: none;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal-footer button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.modal-footer .share-btn {
  background-color: #17a2b8;
  color: white;
}

.modal-footer .direct-call-btn {
  background-color: #14a79e;
  color: white;
}

/* Alert Message */
.alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.alert.hidden {
  visibility: hidden;
  opacity: 0;
}

.alert.visible {
  visibility: visible;
  opacity: 1;
}

/* call modal import end*/

/* Chat Page */
#chatPage {
  display: none;
  flex-direction: column;
  height: 100%;
  background: white;
  overflow-y: scroll;
}

.chat-header {
  display: flex;
  align-items: left;
  /* justify-content: space-between; */
  padding: 15px;
  background: linear-gradient(135deg, #14a79e, #a3fff9);
  color: white;
}

.chat-header .back-btn {
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
  font-weight: bold;
}

.chat-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-header h4 {
  margin: 0;
  margin-left: 10px;
}

.chat-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #f5f5f5;
}

.message {
  margin-bottom: 15px;
  display: flex;
}

.message.incoming {
  justify-content: flex-start;
}

.message.outgoing {
  justify-content: flex-end;
}

.message p {
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 0.9rem;
  max-width: 70%;
}

.message.incoming p {
  background: #eaeaea;
  color: #333;
}

.message.outgoing p {
  background: #14a79e;
  color: white;
}

.chat-footer {
  display: flex;
  align-items: center;
  padding: 15px;
  background: white;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.chat-footer button {
  background: #14a79e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
}

.big-chat {
  display: none;
}

/* Chat Page end*/

.vibrate {
  animation: vibrate 0.5s infinite;
}

@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, -2px);
  }

  80% {
    transform: translate(-2px, 2px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
    /* Fully visible */
  }

  50% {
    opacity: 0;
    /* Hidden */
  }
}

.recordingIcon {
  animation: blink 1s infinite;
  /* 1-second animation, repeats indefinitely */
}

#user_image {
  width: 50px;
  height: 50px;
  border-radius: 20%;
}
</style>
