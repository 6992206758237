<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->
  <!-- style="margin-top: 200px; margin-left: 100px" -->
  <div
    id="add-meidcal_history-modal"
    class="modal modal-xl animate__animated animate__zoomIn animate__faster add-meidcal_history-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="mb-4 modal-title">
            Add Note for <strong>{{ name }}</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- Tabs -->
          <ul class="nav nav-tabs" id="notesTab" role="tablist">
            <li class="nav-item">
              <button
                class="nav-link active"
                id="note-tab"
                data-bs-toggle="tab"
                data-bs-target="#note"
                role="tab"
              >
                Note <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="examination-tab"
                data-bs-toggle="tab"
                data-bs-target="#examination"
                role="tab"
              >
                Examination <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="diagnosis-tab"
                data-bs-toggle="tab"
                data-bs-target="#diagnosis"
                role="tab"
              >
                Diagnosis <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="prescriptions-tab"
                data-bs-toggle="tab"
                data-bs-target="#prescriptions"
                role="tab"
              >
                Prescriptions <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="lab-results-tab"
                data-bs-toggle="tab"
                data-bs-target="#lab-results"
                role="tab"
              >
                Laboratory Results <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="radiology-tab"
                data-bs-toggle="tab"
                data-bs-target="#radiology"
                role="tab"
              >
                Radiology Results <span class="blinker"></span>
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                id="referral-tab"
                data-bs-toggle="tab"
                data-bs-target="#referral"
                role="tab"
              >
                Doctor Referral Letter <span class="blinker"></span>
              </button>
            </li>
          </ul>

          <!-- Tab Contents -->
          <div class="tab-content mt-4" id="notesTabContent">
            <!-- Note Section -->
            <div class="tab-pane fade show active" id="note" role="tabpanel">
              <label for="note-textarea" class="form-label">Note</label>
              <textarea
                class="form-control input-field"
                id="note-textarea"
                rows="4"
                v-model="note"
              ></textarea>

              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="noteImages.length > 0"
                      >{{ noteImages.length }} file(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="note"
                      id="file"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'note')"
                    />
                    <label
                      for="file"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="noteImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in noteImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Examination Section -->
            <div class="tab-pane fade" id="examination" role="tabpanel">
              <label for="examination-textarea" class="form-label"
                >Examination</label
              >
              <textarea
                class="form-control input-field"
                id="examination-textarea"
                rows="4"
                v-model="examination"
              ></textarea>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file2"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="examinationImages.length > 0"
                      >{{ examinationImages.length }} file(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="examination"
                      id="file2"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'examination')"
                    />
                    <label
                      for="file2"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="examinationImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in examinationImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Diagnosis Section -->
            <div class="tab-pane fade" id="diagnosis" role="tabpanel">
              <label for="diagnosis-textarea" class="form-label"
                >Diagnosis</label
              >
              <textarea
                class="form-control input-field"
                id="diagnosis-textarea"
                rows="4"
                v-model="diagnosis"
              ></textarea>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file3"
                    style="background-color: #f1f2f3"
                  >
                    <!-- <span v-if="previews?.note.length == 0"
                          >Choose File...</span
                        > -->
                    <span v-if="diagnosisImages.length > 0"
                      >{{ diagnosisImages.length }} file(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="diagnosis"
                      id="file3"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'diagnosis')"
                    />
                    <label
                      for="file3"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="diagnosisImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in diagnosisImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Prescriptions Section -->
            <div class="tab-pane fade" id="prescriptions" role="tabpanel">
              <label for="prescriptions-textarea" class="form-label"
                >medication</label
              >
              <textarea
                class="form-control input-field"
                id="prescriptions-textarea"
                rows="4"
                v-model="medication"
              ></textarea>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file4"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="medicationImages.length > 0"
                      >{{ medicationImages.length }} files(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="medication"
                      id="file4"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'medication')"
                    />
                    <label
                      for="file4"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="medicationImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in medicationImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Laboratory Results -->
            <div class="tab-pane fade" id="lab-results" role="tabpanel">
              <label for="lab-results-textarea" class="form-label"
                >Laboratory Results</label
              >
              <textarea
                class="form-control input-field"
                id="lab-results-textarea"
                rows="4"
                v-model="lab_text"
              ></textarea>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file5"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="labImages.length > 0"
                      >{{ labImages.length }} files(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="lab"
                      id="file5"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'lab')"
                    />
                    <label
                      for="file5"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="labImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in labImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Radiology Results -->
            <div class="tab-pane fade" id="radiology" role="tabpanel">
              <label for="radiology-textarea" class="form-label"
                >Radiology Results</label
              >
              <textarea
                class="form-control input-field"
                id="radiology-textarea"
                rows="4"
                v-model="radiology_text"
              ></textarea>

              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file6"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="radiologyImages.length > 0"
                      >{{ radiologyImages.length }} files(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="radiology"
                      id="file6"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'radiology')"
                    />
                    <label
                      for="file6"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="radiologyImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in radiologyImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
            <!-- Doctor Referral Letter -->
            <div class="tab-pane fade" id="referral" role="tabpanel">
              <label for="referral-textarea" class="form-label"
                >Doctor Referral Letter</label
              >
              <textarea
                class="form-control input-field"
                id="referral-textarea"
                rows="4"
                v-model="referral"
              ></textarea>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file7"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="referralImages.length > 0"
                      >{{ referralImages.length }} files(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="referral"
                      id="file7"
                      multiple
                      hidden
                      @change="handleFileInput($event, 'referral')"
                    />
                    <label
                      for="file7"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="referralImages.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in referralImages"
                      :key="index"
                    >
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer :blob="file.avatar" :isTemp="file.isTemp" />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
              -
            </div>
          </div>
          <br />
          <div class="col-12">
            <div class="doctor-submit text-end">
              <span
                type="submit"
                class="btn btn-primary submit-form me-2 view-modal"
                @click="sendnote()"
                >Submit</span
              >
              <button
                type="submit"
                class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /.modal -->
  </div>
</template>

<script>
// import Inloader from "../plugins/inloader";

import FileViewer from "@/components/_General/FileViewer.vue";
import jquery from "jquery";
import { mapActions } from "vuex";

export default {
  name: "past_medical_history",
  components: { FileViewer },
  data() {
    return {
      note: null,
      examination: null,
      diagnosis: null,
      radiology_text: null,
      lab_text: null,
      referral: null,
      medication: null,
      previews: {
        note: [],
        examination: [],
        diagnosis: [],
        lab: [],
        radiology: [],
        referral: [],
        medication: [],
      },
      FileforUpload: [], // Consolidated array for server upload
    };
  },
  computed: {
    noteImages() {
      return this.FileforUpload.filter((file) => file.type == "note");
    },
    examinationImages() {
      return this.FileforUpload.filter((file) => file.type == "examination");
    },
    diagnosisImages() {
      return this.FileforUpload.filter((file) => file.type == "diagnosis");
    },
    medicationImages() {
      return this.FileforUpload.filter((file) => file.type == "medication");
    },
    labImages() {
      return this.FileforUpload.filter((file) => file.type == "lab");
    },
    radiologyImages() {
      return this.FileforUpload.filter((file) => file.type == "radiology");
    },
    referralImages() {
      return this.FileforUpload.filter((file) => file.type == "referral");
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      _sendnote: "doctor/sendnote",
    }),
    deleteFile(id) {
      // Find the correct files array and remove the file at the specified index
      const fileIndex = this.FileforUpload.findIndex((file) => file.id == id);
      if (fileIndex !== -1) {
        this.FileforUpload.splice(fileIndex, 1);
      }
    },

    handleFileInput(event, type) {
      const files = event.target.files;
      for (let file of files) {
        // Check if file is already uploaded
        if (
          this.FileforUpload.some(
            (uploadedFile) =>
              uploadedFile.type === type &&
              uploadedFile.avatar.name === file.name
          )
        ) {
          alert(`The file "${file.name}" is already uploaded.`);
          continue;
        }

        this.FileforUpload.push({
          type: type,
          avatar: file,
          isTemp: false,
          id: `${file.name}-${type}`,
        });
      }
    },
    sendnote() {
      console.log(this.FileforUpload, "avatars");
      $(".inloader").show();
      this._sendnote({
        id: this.id,
        payload: {
          note: this.note,
          examination: this.examination,
          lab: this.lab_text,
          diagnosis: this.diagnosis,
          radiology: this.radiology_text,
          avatars: this.FileforUpload,
          is_external: 1,
          referral: this.referral,
          medication: this.medication,
        },
      })
        .then((response) => {
          this.response = response.data;

          if (this.response["status"] == true) {
            // alert(this.response)
            $(".inloader").hide();
            $("#add-meidcal_history-modal").modal("hide");
            this.FileforUpload = [];
            this.$toast.success(this.response["message"]);
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },
  },

  mounted() {
    // this.loadFileInputs();

    // Add event listeners to text areas
    document.querySelectorAll(".input-field").forEach((textarea) => {
      if (
        textarea instanceof HTMLInputElement ||
        textarea instanceof HTMLTextAreaElement
      ) {
        textarea.addEventListener("input", function () {
          const tabId = textarea.id.replace("-textarea", "-tab"); // Match tab ID
          const parentTab = document.querySelector(`#${tabId}`); // Tab corresponding to the textarea
          const blinker = parentTab.querySelector(".blinker"); // Blinker inside the corresponding tab

          if (textarea.value.trim() !== "") {
            // Show blinker and set the correct color
            blinker.style.display = "inline-block";
            if (parentTab.classList.contains("active")) {
              blinker.style.backgroundColor = "black"; // Black for active tab

              document.querySelectorAll(".blinker").forEach((otherBlinker) => {
                const otherTab = otherBlinker.closest(".nav-link");
                if (otherTab.id !== tabId) {
                  otherBlinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
                }
              });
            } else {
              // Default color for inactive tabs
              // blinker.style.backgroundColor = '#14a79e';
            }
          } else {
            // Hide blinker if textarea is empty
            blinker.style.display = "none";
          }
        });
      } else {
        console.warn(
          "Expected an input or textarea element, but got:",
          textarea
        );
      }
    });

    // Add event listeners to tabs for click functionality
    document.querySelectorAll(".nav-link").forEach((tab) => {
      tab.addEventListener("click", function () {
        const tabId = tab.id; // Tab ID
        const textareaId = tabId.replace("-tab", "-textarea"); // Match textarea ID
        const textarea = document.querySelector(`#${textareaId}`); // Get corresponding textarea
        const blinker = tab.querySelector(".blinker"); // Blinker inside the tab

        if (
          textarea &&
          (textarea instanceof HTMLInputElement ||
            textarea instanceof HTMLTextAreaElement)
        ) {
          if (textarea.value.trim() !== "") {
            // Show blinker and set the correct color
            blinker.style.display = "inline-block";
            if (tab.classList.contains("active")) {
              blinker.style.backgroundColor = "black"; // Black for active tab

              document.querySelectorAll(".blinker").forEach((otherBlinker) => {
                const otherTab = otherBlinker.closest(".nav-link");
                if (otherTab.id !== tabId) {
                  otherBlinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
                }
              });
            } else {
              blinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
            }
          } else {
            // Hide blinker if textarea is empty
            blinker.style.display = "none";
          }
        } else {
          console.warn(
            `Expected a valid textarea element but found none for ID: ${textareaId}`
          );
        }
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: #f8f9fa;
}

.custom-container {
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link {
  background-color: #000;
  color: #f8f9fa;
  position: relative;
  margin-right: 5px;
  border-radius: 4px;
  border: none;
}

.nav-tabs .nav-link:hover {
  background-color: #000;
  color: #f8f9fa;
  position: relative;
  margin-right: 5px;
  border-radius: 4px;
  border: none;
}

/* Circular Blinker */
.nav-link .blinker {
  position: absolute;
  top: 2px;
  right: -2px;
  height: 8px;
  width: 8px;
  background-color: #14a79e;
  border-radius: 50%;
  display: none;
  /* Hidden by default */
  animation: blinker 1s infinite;
  z-index: 999;
}

textarea {
  margin-bottom: 20px;
}

@keyframes blinker {
  /* 0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  } */

  100% {
    transform: scale(2.5);
  }
}

.nav-link.active .blinker {
  display: none;
  /* Hide on active tabs */
}

.nav-tabs .nav-link.active {
  background-color: #14a79e;
  color: #fff;
}

/* preview design */
.preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.preview-item {
  position: relative;

  text-align: end;
}

.badge {
  position: absolute;
  top: 5px;
  left: 5px;
  background: #007bff;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
}

.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  line-height: 25px;
  z-index: 1;
}

.delete-btn:hover {
  background: darkred;
}

.file-name {
  margin-top: 10px;
  font-size: 12px;
  word-wrap: break-word;
}

@media (max-width: 800px) {
  .nav-tabs {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px !important;
  }

  .nav-item {
    width: 48% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }
}

@media (max-width: 620px) {
  .nav-tabs {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px !important;
  }

  .nav-item {
    width: 48% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }

  .modal-content {
    width: 565px !important;
  }
}

@media (max-width: 480px) {
  .nav-tabs {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    gap: 20px !important;
  }

  .nav-item {
    width: 100% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }

  .modal-content {
    width: auto !important;
  }
}
</style>
