<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div
      id="con-close-modal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster observation_chart_modal"
      style="margin-top:100px;margin-left:100px;margin-right"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Patient Observations</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div
                    class="input-block local-forms"
                    style="height: 40px"
                    id="tempor"
                  >
                    <label>Time Taken</label>
                    <input
                      class="form-control"
                      type="datetime-local"
                      v-model="bioData.time_taken"
                      placeholder="2024-12-04 16:31:08"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div
                    class="input-block local-forms"
                    style="height: 40px"
                    id="tempor"
                  >
                    <label>Temperature</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.temperature"
                      placeholder="°C"
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Pulse</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.pulse"
                      placeholder="BPM"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Weight</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.weight"
                      placeholder="cm"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Height</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.height"
                      placeholder="cm"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Respiration</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="cm3/min"
                      v-model="bioData.respiration"
                      maxlength="7"
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Oxygen (SPO<sub>2</sub>)</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.oxygen"
                      placeholder="%"
                    />
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Blood Group </label>
                    <a-select
                      class="w-100"
                      v-model:value="bioData.blood_group_id"
                    >
                      <a-select-option disabled>Select</a-select-option>
                      <a-select-option
                        :value="blood.id"
                        v-for="(blood, index) in blood_groups_list"
                        :key="index"
                      >
                        {{ blood.name }}
                      </a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Response to Call </label>
                    <a-select
                      class="w-100"
                      v-model:value="bioData.response_to_call"
                    >
                      <a-select-option disabled>Select</a-select-option>
                      <a-select-option value="Yes">Yes</a-select-option>
                      <a-select-option value="No">No</a-select-option>
                    </a-select>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Response to Stimuli </label>
                    <a-select
                      class="w-100"
                      v-model:value="bioData.response_to_stimuli"
                    >
                      <a-select-option disabled>Select</a-select-option>
                      <a-select-option value="Yes">Yes</a-select-option>
                      <a-select-option value="No">No</a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Blood Pressure (mmHg)</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="bioData.blood_pressure"
                      placeholder="000/000"
                      @input="separator()"
                      maxlength="7"
                      id="bp_modal"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Remarks </label>
                    <textarea
                      class="form-control"
                      rows="3"
                      cols="30"
                      v-model="bioData.remarks"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-6" id="subcance">
                  <div class="doctor-submit text-end">
                    <span
                      type="submit"
                      class="btn btn-primary submit-form me-2"
                      @click="submitForm()"
                    >
                      Submit
                    </span>
                    <span
                      class="btn btn-primary cancel_observation_chart bg-danger text-light"
                      >Cancel</span
                    >
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  props: { record: Object },
  emits: {
    /**
     * @param {String} id The Updated Biodata Id
     * @param {{}} data The New Biodata Data
     */
    updated: (id, data) => {},
  },
  data() {
    return {
      blood_groups_list: [],
      isUpdating: false,
      patientId: localStorage.getItem("this_patient_id"),
      bioData: {
        time_taken: "",
        blood_pressure: "",
        glucose_level: "",
        heart_rate: "",
        body_mass_index: "",
        height: 0,
        weight: 0,
        temperature: 0,
        fever: 0,
        calcium: 0,
        oxygen: 0,
        nitrogen: 0,
        hydrogen: 0,
        carbon: 0,
        lung: 0,
        stomach: 0,
        liver: 0,
        brain: 0,
        blood_count: 0,
        smoking_status: "",
        response_to_call: "",
        response_to_stimuli: "",
        pulse: "",
        respiration: "",
        blood_group_id: "",
      },
    };
  },

  props: {
    patient_id: null,
  },

  methods: {
    ...mapActions({
      _update_bioData: "patient/update_bioData",
      _add_bioData: "patient/add_bioData",
      _blood_groups: "patient/blood_groups",
    }),
    submitForm() {
      this.isUpdating
        ? this.updatePatientBiodata()
        : this.add_patient_bio_data();
    },
    updatePatientBiodata() {
      $(".inloader").show();

      this._update_bioData({
        payload: this.bioData,
        type: "observation_chart",
        bioDataId: this.bioData.id,
      })
        .then((response) => {
          this.$emit("updated", {
            id: this.bioData.id,
            data: response.data.data,
          });
          $(".inloader").hide();
          // this.$toast.success(response.data.message);
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (status != 500) {
              this.$toast.error(error.response.data.message);
            } else {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    add_patient_bio_data() {
      $(".inloader").show();
      $(".observation_chart_modal").modal("hide");

      // if (this.whoisLoggedin == "Doctor" || this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      // if (this.whoisLoggedin == "Front Desk" ||this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }
      // alert(this.patient_id);
      this._add_bioData({ id: this.patient_id, payload: this.bioData })
        .then((response) => {
          this.$emit("saved");
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // this.$router.push({ name: "Patients" });
            location.reload();
            this.$emit("proceed", "proceed");
            // localStorage.setItem('biodata_added',1)
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    goto(route) {
      window.location = route;
      // this.$router.push({ name: route })
    },
    //     separator() {
    //   // Remove all non-numeric characters except the first "/"
    //   this.blood_pressure = this.blood_pressure.replace(/[^0-9/]/g, "");

    //   // Split the input into systolic and diastolic parts
    //   const parts = this.blood_pressure.split("/");

    //   // Ensure systolic part is at most 3 digits
    //   if (parts[0]?.length > 3) {
    //     parts[0] = parts[0].slice(0, 3);
    //   }

    //   // If diastolic part exists, ensure it is at most 3 digits
    //   if (parts[1]?.length > 3) {
    //     parts[1] = parts[1].slice(0, 3);
    //   }

    //   // If the input has a valid systolic part but no "/", insert it automatically when the length exceeds 3
    //   if (parts.length === 1 && parts[0]?.length > 3) {
    //     this.blood_pressure = parts[0].slice(0, 3) + "/" + parts[0].slice(3);
    //   } else {
    //     // Recombine the systolic and diastolic parts
    //     this.blood_pressure = parts.join("/");
    //   }
    // },

    // separator() {
    //   var input = document.getElementById("bp_modal");
    //   input.value = input.value.replace(/[^\d/]/g, "");
    //   if (input.value.length > 3 && input.value.length < 5) {
    //     if (input.value.charAt(3) !== "/") {
    //       input.value = input.value.slice(0, 3) + "/" + input.value.slice(2);
    //     }
    //   }
    // },
    separator() {
      var input = document.getElementById("bp_modal");

      // Remove all non-digit and non-slash characters
      input.value = input.value.replace(/[^\d/]/g, "");

      // If the input has more than 3 characters and the 4th character is not a slash
      if (input.value.length > 3 && input.value.charAt(3) !== "/") {
        // Split into parts and insert the slash at the correct position
        input.value = input.value.slice(0, 3) + "/" + input.value.slice(3);
      }

      // Prevent duplicate slashes by keeping only the first valid structure
      if (input.value.length > 5) {
        input.value = input.value.slice(0, 7);
      }
    },

    blood_groups() {
      this._blood_groups()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.blood_groups_list = this.response.data;
            console.log(this.blood_groups_list);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.bioData = newVal;
          this.isUpdating = true;
        } else {
          this.isUpdating = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.patientId = localStorage.getItem("this_patient_id");

    this.blood_groups();
    $(".cancel_observation_chart").click(function () {
      $(".observation_chart_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 480px) {
  #subcance {
    width: 100% !important;
  }

  #hrtrat {
    margin-top: 3%;
  }

  #con-close-modal {
    margin-left: 0px !important;
    margin-top: 90px !important;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 68vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  #sunandcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-end {
    gap: 20px;
    text-align: center !important;
  }

  .modal-title {
    margin-bottom: 3%;
  }

  #tempor {
    margin-top: 10%;
  }
}
</style>
