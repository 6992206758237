<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Payments</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="idomina">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Payment List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end" @click="search_the_list()"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="/Add-Payment"
                            class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 ms-auto">
                    <div class="">
                      <a-select
                        class="w-100"
                        v-model:value="target"
                        @change="select_target(target)"
                      >
                        <!-- showSearch :filterOption="(input, option) => filterObject(option, input, 'key')" -->
                        <a-select-option disabled key="a"
                          >Select Service</a-select-option
                        >
                        <a-select-option
                          v-for="(target, index) in targets"
                          v-bind:key="index"
                          :value="target"
                          >{{ target }}</a-select-option
                        >
                      </a-select>
                    </div>

                    <!-- <div class="btn-group selectbtn">
                      <button type="button" class="btn dropdown-toggle btn-primary btn-xl" data-bs-toggle="dropdown">
                        {{ target_name || "Payment Targets" }}
                      </button>
                      <div class="dropdown-menu dm">
                        <span class="dropdown-item text-bg-secondary">Select List</span>
                        <span class="dropdown-item" v-for="(target, index) in targets" v-bind:key="index"
                          @click="select_target(target)">{{ target }}</span>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-2 ms-auto">
                    <!-- <div class="btn-group selectbtn">
                      <button type="button" class="btn dropdown-toggle btn-primary btn-xl" data-bs-toggle="dropdown">
                        {{ type_name || "Transaction Type" }}
                      </button>
                      <div class="dropdown-menu dm">
                        <span class="dropdown-item text-bg-secondary">Select List</span>
                        <span class="dropdown-item" v-for="(type, index) in types" v-bind:key="index" :class="getTypeClass(type)"
                          @click="select_type(type)">{{ type }}</span>
                      </div>
                    </div> -->

                    <div class="">
                      <a-select
                        class="w-100"
                        v-model:value="type"
                        @change="select_type()"
                      >
                        <a-select-option disabled
                          >Select Target</a-select-option
                        >
                        <a-select-option
                          v-for="(type, index) in types"
                          v-bind:key="index"
                          :class="getTypeClass(type)"
                          :value="type"
                          >{{ type }}</a-select-option
                        >
                      </a-select>
                    </div>
                  </div>

                  <div class="col-2 ms-auto">
                    <a-space direction="vertical" :size="12">
                      <a-range-picker
                        v-model:value="filterDate"
                        :placement="placement"
                        style="height: 40px"
                        @change="select_date(filterDate)"
                      >
                      </a-range-picker>
                    </a-space>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" style="overflow: visible">
            <a-table
              :dataSource="main_data"
              :columns="columns"
              :loading="isloading"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'invoice_number'">
                  <span
                    @click="go_to_invoice(record.invoice)"
                    style="cursor: pointer; color: #14a79e"
                  >
                    {{ record.reference }}
                  </span>
                </template>
                <template v-if="column.key == 'patient'">
                  <span>
                    {{ record.invoice.patient }}
                  </span>
                </template>
                <template v-if="column.key == 'method'">
                  <span
                    class="custom-badge status-green"
                    v-if="record.payment_method == 'Cash'"
                    >{{ record.payment_method }}</span
                  >
                  <span
                    class="custom-badge status-purple"
                    v-if="record.payment_method == 'Balance'"
                    >{{ record.payment_method }}</span
                  >
                  <span
                    class="custom-badge status-purple"
                    v-if="record.payment_method == 'Pos'"
                    >{{ record.payment_method }}</span
                  >
                </template>
                <template v-if="column.key == 'type'">
                  <span>
                    <span
                      class="custom-badge status-green"
                      v-if="record.type == 'Credit'"
                      >{{ record.type }}</span
                    >
                    <span
                      class="custom-badge status-red"
                      v-if="record.type == 'Debit'"
                      >{{ record.type }}</span
                    >
                  </span>
                </template>
                <template v-if="column.key == 'amount'">
                  <span> ₦ {{ formatNumberWithCommas(record.amount) }} </span>
                </template>
                <template v-if="column.key == 'logged_date'">
                  <span>
                    {{ getDatetime(record.date) }}
                  </span>
                </template>
                <template v-if="column.key == 'date'">
                  <span>
                    {{ record.payment_date }}
                  </span>
                </template>
                <template v-if="column.key == 'target'">
                  <span>
                    {{ record.invoice.target }}
                  </span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
const columns = [
  {
    title: "Invoice Number",
    key: "invoice_number",
  },
  {
    title: "Patient",
    key: "patient",
  },
  {
    title: "Payment Method",
    key: "method",
  },
  {
    title: "Transaction Type",
    key: "type",
  },
  {
    title: "Payment Date",
    key: "date",
  },
  {
    title: "Paid Amount",
    key: "amount",
  },
  {
    title: "Purpose",
    key: "target",
  },
  {
    title: "Logged Date|Time",
    key: "logged_date",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },
  data() {
    return {
      reponse: "",
      main_data: [],
      no_of_trans: 0,
      search: "",
      main_data_reserve: [],
      columns,
      targets: [],
      target_name: null,
      types: [],
      type_name: null,
      filterDate: null,
      target: "Payment Target",
      type: "Payment Type",
      isloading: false,
    };
  },

  methods: {
    ...mapActions({
      _fin_list: "finance/fin_list",
    }),
    getDatetime(date) {
      if (date) {
        let datetime = new Date(date);
        return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
      }
    },
    fin_list() {
      this.isloading = true;
      this._fin_list()
        .then((response) => {
          this.isloading = false;
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            this.main_data = this.main_data;
            // this.main_data_reserve = this.main_data;
            this.main_data_reserve = this.main_data;
            this.no_of_trans = this.response.data.length;
            // console.log(this.main_data);
            this.targets = [
              ...new Set(this.main_data.map((item) => item.target)),
            ];
            this.types = [...new Set(this.main_data.map((item) => item.type))];
          }
        })
        .catch((error) => {
          this.isloading = false;
          console.log(error);
          console.log("Error fetching data", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatNumberWithCommas(number) {
      if (number) {
        // Convert the number to a string
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var Sname = this.main_data[i].name.toLowerCase();
          var Stype = this.main_data[i].type.toLowerCase();
          if (Sname.includes(searchLetter) || Stype.includes(searchLetter)) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
    go_to_invoice(invoice) {
      window.location = "/View-Invoice";
      localStorage.setItem("invoice", JSON.stringify(invoice));
    },
    date_seperate(dateTimeString) {
      if (dateTimeString) {
        const dateTimee = new Date(dateTimeString);
        const date = dateTimee.toISOString().split("T")[0];
        return date;
      }
    },
    getTypeClass(type) {
      return {
        "text-red": type === "debt",
        "text-green": type === "credit",
        // Add more classes for other types if needed
      };
    },

    select_target(target) {
      // alert(this.target)
      this.target_name = this.target;
      this.type_name = null;
      const data_here = this.main_data_reserve;
      this.main_data = data_here.filter((item) => item.target === this.target);
    },
    select_type() {
      this.type_name = this.type;
      this.target_name = null;

      const data_here = this.main_data_reserve;
      this.main_data = data_here.filter((item) => item.type === this.type);
    },
    select_date(filterDate) {
      const data_here = this.main_data_reserve;
      this.type_name = null;
      this.target_name = null;
      const start = new Date(this.date_seperate(filterDate?.[0]));
      const end = new Date(this.date_seperate(filterDate?.[1]));

      this.main_data = data_here.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.fin_list();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  /* inline-size: 100%; */
  block-size: 35px;
}

.dropdown-menu {
  inline-size: 100%;
}

.dropdown-item {
  cursor: pointer;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

@media (max-width: 800px) {
  #idomina {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
  }

  .ms-auto {
    margin-left: 0px !important;
  }

  .col-2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #idomina {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
  }

  .ms-auto {
    margin-left: 0px !important;
  }

  .col-2 {
    width: 100%;
  }
}
</style>
