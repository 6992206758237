<template>
  <a-card class="imgcontsm fileViewer">
    <div v-if="isLoaded">
      <span @click="downloadFile()" class="download-btn" v-if="url && downloadable">
        <i class="fa fa-download"></i>
      </span>
      <div @click="openModal()">
        <img class="image" :src="fileOutput.url" alt="" v-if="isImage" />
        <div v-else class="text-center">
          <i class="fa fa-file" style="font-size: 80px;color:#bbb"></i><br />
          <br />
          <span> <a-tag color="success">{{ fileType }}</a-tag> | {{ fileOutput.size }} </span>
          <div>
            <small>Click To View</small>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <a-skeleton></a-skeleton>
    </div>
    <!-- modal -->
  </a-card>
  <div :id="fileName" class="modal-lg modal fade fileViewer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="text-center w-100">StellaSync File Reader</h2>
        </div>

        <div class="modal-body">
          <div v-show="fileOutput.previewLoaded">
            <template v-if="isImage">
              <img :src="fileOutput.url" :alt="fileOutput.name" class="preview-image" />
            </template>
            <template v-else-if="isPdf">
              <div class="card pdf-reader">
                <div class="card-header text-center">
                  <h5 class="mb-0">PDF Preview</h5>
                </div>
                <div class="card-body">
                  <div>
                    <canvas :id="`${fileName}-pdf-canvas-${page}`" class="preview-pdf m-2 shadow-sm"
                      v-show="page === fileOutput.currentPage" v-for="(page) in fileOutput.pdfPages" :key="page">
                    </canvas>
                  </div>
                </div>

              </div>

            </template>
            <template v-else-if="isWord">
              <div v-html="fileOutput.content" class="word-content"></div>
            </template>
            <template v-else>
              <div class="d-flex w-100 justify-content-center align-items-center" style="height:76vh">
                <h3 class="text-center py-5"> Cannot Preview This File</h3>
              </div>

            </template>
          </div>
          <div v-if="!fileOutput.previewLoaded" class="d-flex justify-content-center align-items-center w-100"
            style="height: 80vh">
            <div class="text-center">

              <a-spin size="large"></a-spin>
              <h4>Loading File</h4>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <div class="d-flex w-100 justify-content-center" v-if="isPdf">
            <button class="btn btn-primary btn-sm me-2" :disabled="fileOutput.currentPage === 1"
              @click="goToPreviousPage">
              Previous
            </button>
            <small>Page {{ fileOutput.currentPage }} of {{ fileOutput.pdfPages.length }}</small>
            <button class="btn btn-primary btn-sm ms-2"
              :disabled="fileOutput.currentPage === fileOutput.pdfPages.length" @click="goToNextPage">
              Next
            </button>
          </div>
          <div class="doctor-submit text-end">
            <span class="btn btn-danger cance_fluid_chart_out bg-danger text-light" data-bs-dismiss="modal">Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { activities } from '@/constants';
import { store } from '@/store';
import { textToSlug } from '@/utils/helpers';

export default {
  name: "Loader",
  props: {
    url: {
      type: String,
      required: false
    },
    blob: {
      type: Object,
      required: false
    },
    isTemp: Boolean,
    downloadable: true,
    downloadTrackDetails: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      fileOutput: {
        url: '',
        type: '',
        content: '',
        name: '',
        previewLoaded: false,
        loaded: false,
        pdfPages: [], currentPage: 1
      },
      file: null,
      fileUrl: ""
    }
  },
  computed: {
    isLoaded() {
      return this.fileOutput.loaded
    },

    fileName() {
      const parts = (this.fileUrl || "").split('/');
      return textToSlug(parts[parts.length - 1] || '');
    },
    isWord() {
      return this.fileOutput.name.includes('docx');
    },
    isImage() {
      return this.fileOutput.type.includes('image');
    },
    isPdf() {
      return this.fileOutput.name.includes('pdf');
    },
    fileType() {
      return this.isImage ? 'Image' : this.isPdf ? 'PDF' : this.isWord ? 'Word' : this.file.type
    }
  },
  watch: {
    url() {
      if (!this.isTemp) {
        this.loadFile()
      }
    },
    blob() {
      if (!this.isTemp) {
        this.loadFile()
      }
    },

  },
  methods: {
    goToNextPage() {
      if (this.fileOutput.currentPage < this.fileOutput.pdfPages.length) {
        this.fileOutput.currentPage++;
      }
    },
    goToPreviousPage() {
      if (this.fileOutput.currentPage > 1) {
        this.fileOutput.currentPage--;
      }
    },
    async openModal() {
      await this.loadPreview()
      $(`#${this.fileName}`).modal('show');
    },
    extractFileNameFromUrl() {
      return this.fileUrl.substring(this.fileUrl.lastIndexOf('/') + 1);
    },
    formatFileSize(size) {
      const units = ["Bytes", "KB", "MB", "GB", "TB"];
      let unitIndex = 0;

      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }

      return `${size.toFixed(2)} ${units[unitIndex]}`;
    }
    ,
    async loadFile() {
      if (this.blob) {
        this.file = new File([this.blob], this.extractFileNameFromUrl(this.fileUrl), {
          type: this.blob.type,
        });
        this.fileOutput.name = this.blob?.name;
        this.fileOutput.type = this.blob?.type;
        this.fileOutput.size = this.formatFileSize(this.blob?.size);
        this.fileUrl = this.blob?.name;
        this.fileOutput.loaded = true;


      } else {
        this.fileUrl = this.url;
        try {
          const response = await fetch(this.fileUrl);

          if (!response.ok) {
            throw new Error(`Failed to fetch file from URL: ${response.statusText}`);
          }

          const blob = await response.blob();
          this.file = new File([blob], this.extractFileNameFromUrl(this.fileUrl), {
            type: blob.type,
          });

          this.fileOutput.name = this.fileName;
          this.fileOutput.type = blob.type;
          this.fileOutput.size = this.formatFileSize(blob.size);
          this.fileOutput.loaded = true;

        } catch (error) {
          console.error("Error loading file from URL:", error);
        }
      }
      if (this.isImage) {
        this.loadPreview();
      }
    },
    downloadFile() {
      if (this.downloadTrackDetails) {
        store.dispatch('recordActivity', { name: activities.fileDownload, action: activities.downloaded, patientId: this.downloadTrackDetails.patientId, details: this.downloadTrackDetails })
      }
      if (this.file) {
        const url = URL.createObjectURL(this.file);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.file.name || 'download'; // Use the file's name or default to 'download'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the object URL
      } else {
        console.error("No file available to download.");
      }
    },
    async loadPreview() {

      const file = this.file;

      const reader = new FileReader();

      if (this.isImage) {
        if (this.blob) {
          reader.onload = (e) => {
            this.fileOutput.url = e.target.result;
            this.fileOutput.previewLoaded = true;
          }
          reader.readAsDataURL(this.blob);
        } else {
          this.fileOutput.url = this.url;
          this.fileOutput.previewLoaded = true;

        }

      } else if (this.isPdf) {
        reader.onload = (e) => {
          const pdfData = e.target.result;

          this.fileOutput.content = null;

          // Render PDF using PDF.js
          const loadingTask = pdfjsLib.getDocument({ data: pdfData });
          loadingTask.promise.then(async (pdf) => {
            this.fileOutput.pdfPages = Array.from({ length: pdf.numPages }, (_, i) => i + 1);

            setTimeout(async () => {
              this.fileOutput.pdfPages.map((pageNo) => {

                pdf.getPage(pageNo).then(async (page) => {
                  const canvas = document.getElementById(`${this.fileName}-pdf-canvas-${pageNo}`);

                  const context = canvas.getContext('2d');
                  const viewport = page.getViewport({ scale: 1.5 });

                  canvas.width = viewport.width;
                  canvas.height = viewport.height;
                  await page.render({
                    canvasContext: context,
                    viewport: viewport
                  });
                  if (pageNo == this.fileOutput.pdfPages.length) {
                    this.fileOutput.previewLoaded = true;
                  }
                });

              })
            }, 5000)
          }).catch((error) => {
            console.error('Error rendering PDF:', error);
          });
        };
        reader.readAsArrayBuffer(file);
      } else if (this.isWord) {
        reader.onload = (e) => {
          mammoth.convertToHtml({ arrayBuffer: e.target.result })
            .then((result) => {

              this.fileOutput.content = result.value;
              this.fileOutput.previewLoaded = true;

            });
        };
        reader.readAsArrayBuffer(file);
      } else {
        this.fileOutput.previewLoaded = true
        console.log('Unsupported file type:', file);
      }
    },
  },
  mounted() {
    if (!this.isTemp) {
      this.loadFile()
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fileViewer .modal-body {
  overflow: scroll;
  height: 80vh;
}

.fileViewer .imgcontsm {
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: relative;
}

.fileViewer .download-btn {
  position: absolute;
  top: -2px;
  right: 0px;
  padding: 6px 10px;
  border-radius: 0;
  cursor: pointer;
  background: var(--bs-primary);
  font-size: 13px;
  color: #fff;
  border-radius: 0 8px 0 0;
}

.fileViewer .image {
  height: 160px;
  width: 180px;
  object-fit: cover;
}

.fileViewer .imgcont {
  overflow-x: scroll;
  width: auto;
  overflow-y: hidden;
  display: flex;
  height: auto;
}

.fileViewer .preview-word,
.fileViewer .preview-pdf,
.fileViewer .preview-image {
  width: 100%;
  /* height: 10vh; */
  object-fit: cover
}

/* pdf */
.fileViewer .pdf-reader {
  max-width: 800px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0 !important;
}

.fileViewer .pdf-reader .preview-pdf {
  width: 100%;
  /* Adjust max width for canvas */
  /* height: 80vh; */
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: #f9f9f9;
  object-fit: contain;
}

.fileViewer .pdf-reader .preview-pdf:hover {
  border-color: var(--bs-primary);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.fileViewer .word-content {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  white-space: pre-wrap;
  /* Preserve formatting */
  padding: 10px;
  /* background-color: #f9f9f9; */
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 800px;
  margin: auto;
  color: #000;
}

.fileViewer tbody,
.fileViewer td,
.fileViewer tfoot,
.fileViewer th,
.fileViewer thead,
.fileViewer tr {
  border-width: 1px !important;
}
</style>
