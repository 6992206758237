<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Laboratory</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Laboratory Test Type</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <SettingsMenu /> -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>List of Laboratory Tests</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <!-- <span class="btn btn-primary doctor-refresh ms-2" hidden=''
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-auto text-end float-end ms-auto download-grp">
                        <a href="javascript:;" class="me-2"
                          ><img
                            src="../../assets/assets/img/icons/pdf-icon-01.svg"
                            alt=""
                        /></a>
                        <a href="javascript:;" class="me-2"
                          ><img
                            src="../../assets/assets/img/icons/pdf-icon-02.svg"
                            alt=""
                        /></a>
                        <a href="javascript:;" class="me-2"
                          ><img
                            src="../../assets/assets/img/icons/pdf-icon-03.svg"
                            alt=""
                        /></a>
                        <a href="javascript:;"
                          ><img
                            src="../../assets/assets/img/icons/pdf-icon-04.svg"
                            alt=""
                        /></a>
                      </div> -->
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="test_types" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'cost'">
                      <span> ₦ {{ formatNumberWithCommas(record.cost) }} </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit-close-modal"
                            @click="setSelectedLabTestType(record)"><i class="fa fa-pen-to-square m-r-5"></i>
                            Edit</a>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Laboratory Test Type</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Lab Type <span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g Gastro test" v-model="test" />
              </div>
            </div>

            <div class="col-6 col-md-6 col-xl-6">
              <div class="input-block local-forms">
                <label>Department <span class="login-danger"></span></label>
                <select class="form-control" type="text" name="cat" v-model="department">
                  <option value="" disabled>Select Department</option>
                  <option :value="d.id" v-for="d in depts" v-bind:key="d.id">
                    {{ d.category }}
                  </option>
                  <!-- <option value="new_dept">Request new department</option>
                  @change="request_lab_test()"
                    -->
                </select>
              </div>
            </div>
            <div class="col-6 col-md-6 col-xl-6 text-center">
              <span type="submit" class="btn btn-primary btn_r" @click="request_lab_test()">
                Request new department
              </span>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Amount <span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g 5000" v-model="cost" />
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="doctor-submit text-end">
              <span type="submit" class="btn btn-primary submit-form me-2" @click="add_lab_type()">
                Submit
              </span>
              <span class="btn btn-primary cancel-form bg-danger text-light">
                Cancel
              </span>
            </div>
          </div>
          <!-- </div> -->

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- Edit Lab Test Type -->
  <div id="edit-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Laboratory Test Type</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Lab Type <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="e.g Gastro test" v-model="selected_test" />
                </div>

                <div class="input-block local-forms">
                  <label>Department<span class="login-danger"></span></label>
                  <select class="form-control" type="text" name="cat" v-model="selectedLabTestType.lab_category_id">
                    <option value="" disabled>Select Department</option>
                    <option :value="department.id" v-for="department in depts" v-bind:key="department.id">
                      {{ department.category }}
                    </option>
                  </select>
                </div>

                <div class="input-block local-forms">
                  <label>Amount <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="e.g 5000" v-model="selectedLabTestType.cost" />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span type="submit" class="btn btn-primary submit-form me-2" @click="edit_lab_type()">
                    Submit
                  </span>
                  <span class="btn btn-primary edit-cancel-form bg-danger text-light cancel-form">
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- request new lab test -->
  <div id="request_lab_test" class="modal modal-lg animate__animated animate__zoomIn animate__faster" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Request Laboratory Category</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <!-- <div class="col-12"></div> -->
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Lab Type <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="Department name" v-model="department_name" />
                </div>

                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Description </label>
                    <textarea class="form-control" rows="3" cols="30" v-model="about_department"
                      placeholder="About department">
                  </textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span type="submit" class="btn btn-primary submit-form me-2" @click="request_Category()">
                    Submit
                  </span>
                  <span class="btn btn-primary cancel-form bg-danger text-light cancel-form" data-bs-dismiss="modal"
                    aria-label="Close">
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Lab_Setting_Menu";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Test Type",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Department",
    dataIndex: "lab_category",
    key: "lab_category",
  },
  {
    title: "Amount",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Date Added",
    dataIndex: "date_added",
    key: "date_added",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: false,
      test_types: [],
      test: "",
      cost: "",
      lab_category_id: 0,
      type: "lab",
      depts: [],
      reserve_test_types: [],
      department: "",
      search: "",
      selectedLabTestType: {},
      columns,
      department_name: "",
      about_department: "",
      selected_test: ''
    };
  },

  methods: {
    ...mapActions({
      _get_test_type: "lab/getTestType",
      _add_lab_type: "lab/add_lab_type",
      _edit_lab_type: "lab/edit_lab_type",
      _get_depts: "lab/getDepartment",
      _addCategory: "general/add_global_category",
    }),
    // represent(id) {
    //   this.lab_category_id = id;
    //   alert(this.lab_category_id);
    // },

    get_test_type() {
      this.isLoading = true;
      this._get_test_type()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.test_types = this.response.data;
            this.test_types = this.test_types;
            this.reserve_test_types = this.test_types;
            // console.log(this.test_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },
    add_lab_type() {
      if (this.department != "" && this.test != "" && this.cost != "") {
        $(".inloader").show();
        this._add_lab_type({
          test: this.test,
          cost: this.cost,
          type: this.type,
          lab_category_id: this.department,
        })
          .then((response) => {
            $(".inloader").hide();
            $("#con-close-modal").modal("hide");
            this.department = "";
            this.description = "";
            this.get_test_type();
            this.response = response.data;
            // console.log(response);
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
            }
          })
          .catch((error) => {
            $(".inloader").hide();
            console.log(error);
            this.$toast.error(error);
          });
      } else {
        this.$toast.error("Fill all the inputs to proceed");
      }
    },

    setSelectedLabTestType(SelectedLabTestType) {
      this.selectedLabTestType = SelectedLabTestType;
      this.selected_test = this.selectedLabTestType.test
    },
    edit_lab_type() {
      $(".inloader").show();
      this._edit_lab_type({
        id: this.selectedLabTestType.id,
        payload: {
          test: this.selected_test,
          cost: this.selectedLabTestType.cost,
          type: this.selectedLabTestType.type,
          lab_category_id: this.selectedLabTestType.lab_category_id,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          $("#edit-close-modal").modal("hide");
          this.department = "";
          this.description = "";
          this.get_test_type();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          this.$toast.error(error);
        });
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    get_depts() {
      this._get_depts()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.depts = this.response.data;
            console.log(this.depts);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    search_the_list() {
      if (this.search == "") {
        this.test_types = this.reserve_test_types;
      } else {
        this.test_types = this.reserve_test_types;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.test_types.length; i++) {
          var test = this.test_types[i].test.toLowerCase();
          var lab_category = this.test_types[i].lab_category.toLowerCase();
          if (
            test.includes(searchLetter) ||
            lab_category.includes(searchLetter)
          ) {
            searchResult.push(this.test_types[i]);
          }
          // if (searchResult == []) {
          //   this.test_types = this.test_types;
          // }
        }
      }
      if (this.search == "") {
        this.test_types = this.reserve_test_types;
      } else {
        this.main_data = searchResult;
      }
    },
    request_lab_test() {
      // if (this.department == "new_dept") {
      $("#request_lab_test").modal("show");
      $("#con-close-modal").modal("hide");
      // }
    },

    request_Category() {
      $(".inloader").show();
      this._addCategory({
        category: this.department_name,
        description: this.about_department,
        type: "Lab",
      })
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.response = response.data;
          // console.log(response);
          $(".inloader").hide();
          $("#request_lab_test").modal("hide");

          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            this.about_department = "";
            this.department_name = "";
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
  },

  mounted() {
   setTimeout(()=>{
     $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    $(".edit-cancel-form").click(function () {
      $("#edit-close-modal").modal("hide");
    });

    this.get_test_type();
    this.get_depts();
   }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#request_lab_test {
  margin-top: 100px;
  margin-left: 100px;
}

.n1 {
  background-color: #14a79e;
}

.btn_r {
  height: 50px;
  padding-top: 15px;
  width: 80%;
}

#con-close-modal {
  margin-top: 100px;
  margin-left: 100px;
}

#edit-close-modal {
  margin-top: 100px;
  margin-left: 100px;
}

@media (max-width: 800px) {
  #con-close-modal {
    margin-left: 20px !important;
  }

  .modal-dialog {
    margin-left: 0px !important;
  }

  .modal-content {
    width: 750px !important;
  }

  #request_lab_test {
    margin-left: 20px !important;
  }

  #edit-close-modal {
    margin-left: 20px !important;
  }
}

@media (max-width: 480px) {

  /* .doctor-search-blk .add-group {
    margin-top: 0px !important;
  }
  .doctor-search-blk {
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
  } */
  #con-close-modal {
    margin-left: 3px !important;
  }

  .modal-dialog {
    margin-left: 0px !important;
  }

  .modal-content {
    width: 100% !important;
  }

  .btn_r {
    width: 100%;
    font-size: 12px;
    margin-top: 5%;
  }

  .local-forms.input-block {
    margin-top: 2%;
  }

  #request_lab_test {
    margin-left: 3px !important;
  }

  #edit-close-modal {
    margin-left: 3px !important;
  }
}
</style>
