<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />
  <Waiter class="waiter" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Appointment</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Category Appointment List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Appointments</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden="">
                            <img src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="main_data" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'name'">
                      <span style="cursor: pointer;" @click="
                        gotoDashboard(
                          record.patient_id,
                          record.full_name,
                          record.id,
                          record.patient_type,
                          record.is_collaboration,
                          record.patient_result_id
                        )
                        ">
                        {{ record.full_name }}
                      </span>
                    </template>
                    <template v-if="column.key == 'patient_type'">
                      <span>
                        {{ edit_text(record.patient_type) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'type'">
                      <button class="custom-badge badge-primary">
                        {{ record.type }}
                      </button>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span>
                        {{ formattedDate(record.date) }}
                      </span>
                    </template>

                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu">
                          <span class="dropdown-item" title="View Patient" @click="
                            gotoDashboard(
                              record.patient_id,
                              record.full_name,
                              record.id,
                              record.patient_type,
                              record.is_collaboration,
                              record.patient_result_id
                            )
                            ">
                            <i class="fa fa-tachometer m-r-5"></i> View
                            Dashboard</span>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Waiter from "../plugins/Waiter.vue";

import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Patient Type",
    key: "patient_type",
  },
  {
    title: "Appointment Type",
    key: "type",
  },
  // {
  //   title: "From",
  //   key: "from",
  // },
  {
    title: "Date",
    key: "date",
  },
  {
    // title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Waiter,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      reponse: "",
      main_data: [],
      main_data1: [],
      no_of_appointments: 0,
      search: "",
      main_data_reserve: [],
      columns,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      _getCategoryGOPDList: "doctor/getCategoryGOPDList",
    }),
    getCategoryGOPDList() {
       this.isLoading = true;
      this._getCategoryGOPDList()
        .then((response) => {
          // $(".waiter").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            this.main_data = this.main_data;
            this.main_data_reserve = this.main_data;
            console.log(this.main_data);
            // 
            // this.main_data = this.main_data.slice(0, this.entry)

            this.no_of_patients = this.response.data.length;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;

        });
    },

    gotoDashboard(
      id,
      full_name,
      aptid,
      type,
      collaboration,
      patient_result_id
    ) {
      localStorage.setItem("patient_name", full_name);
      localStorage.setItem("apt_id", aptid);
      localStorage.setItem("this_patient_id", id);
      localStorage.setItem("patient_type", type);
      localStorage.setItem("patient_result_id", patient_result_id);
      localStorage.setItem("collaboration", collaboration);

      window.location = "My-Patient-Dashboard";
    },
    edit_text(text) {
      const formatted_text =
        text.charAt(0).toUpperCase() +
        text.slice(1).replace("_", " ").replace("p", "P");
      return formatted_text;
    },

    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var full_name = this.main_data[i].full_name.toLowerCase();
          var type = this.main_data[i].type.toLowerCase();
          var patient_type = this.main_data[i].patient_type.toLowerCase();
          var appointment_type =
            this.main_data[i].appointment_type.toLowerCase();
          var date = this.main_data[i].date;
          if (
            full_name.includes(searchLetter) ||
            type.includes(searchLetter) ||
            patient_type.includes(searchLetter) ||
            appointment_type.includes(searchLetter) ||
            date.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    setTimeout(()=>{
    this.getCategoryGOPDList();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
