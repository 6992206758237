<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Templates </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <filter-table
          :filter-by="filterTableBy"
          :search-keys="['name', 'category']"
          :columns="columns"
          :data="templates"
          title="Template List"
          :loading="isLoading"
        >
          <template #rightContent="_data">
            <div
              class="col-2 ms-auto d-flex gap-2 align-items-center col-2"
              id="requnw"
            >
              <button
                class="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#templateRequestModal"
              >
                Request New
              </button>
            </div>
          </template>
          <template #addGroup>
            <span
              class="bntn btn-primary add-pluss ms-2"
              @click="openModal()"
              v-if="loggedAdmin == 'Super Admin'"
              ><img src="../../assets/assets/img/icons/plus.svg" alt=""
            /></span>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'last_updated'">
              <span>
                {{ getDatetime(record.updated_at) }}
              </span>
            </template>
            <template v-if="column.key == 'action'">
              <div class="d-flex justify-content-end gap-2">
                <span
                  class="btn btn-primary ms-2"
                  @click="previewTemplate(record.content)"
                  >Preview</span
                >
                <div
                  class="dropdown dropdown-action"
                  v-if="loggedAdmin == 'Super Admin'"
                >
                  <a
                    href="#"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="fa fa-ellipsis-v"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <span
                      class="dropdown-item"
                      title="Collect Sample"
                      @click="openModal(record)"
                    >
                      <i class="fa fa-edit m-r-5"></i>Edit</span
                    >

                    <span
                      class="dropdown-item"
                      title="Collect Sample"
                      @click="deleteTemplate(record.id)"
                    >
                      <i class="fa fa-trash m-r-5"></i>Delete</span
                    >
                    <!-- <span class="dropdown-item" @click="completed(record.id)"><i class="fa fa-check m-r-5"></i>
                    Completed</span> -->
                  </div>
                </div>
              </div>
            </template>
          </template>
        </filter-table>
      </div>
    </div>

    <!-- modals -->

    <div
      id="templateRequestModal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Request New Template</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <div class="row">
              <div class="col-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Avatar <span class="login-danger">*</span></label
                  >
                  <div
                    ref="fileUpload"
                    class="settings-btn upload-files-avator"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="file"
                      class="hide-input"
                      @change="handleFileUpload($event.target.files[0])"
                    />
                    <label for="file" class="upload">Choose File</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <a-image
                  v-if="imagePreview"
                  class="rounded w-100"
                  :src="imagePreview"
                  style="height: 100px"
                />
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="doctor-submit text-end">
                <button
                  @click="requestTemplate"
                  type="submit"
                  class="btn btn-primary submit-form me-2"
                >
                  Submit
                </button>
                <button
                  type="submit"
                  class="btn btn-primary cancel-form bg-danger text-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
            <!-- body ends here -->
          </div>
        </div>
      </div>
    </div>

    <div
      id="templatePreview"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Template Preview</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <div class="row">
              <div v-html="previewContent"></div>
            </div>

            <div class="col-12 mt-2">
              <div class="doctor-submit text-end">
                <span
                  type="submit"
                  class="btn btn-primary cancel-form bg-danger text-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >Close</span
                >
              </div>
            </div>
            <!-- body ends here -->
          </div>
        </div>
      </div>
    </div>

    <div
      id="templateModal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ isUpdating ? "Update" : "Add New" }} Template
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="input-block local-forms">
                  <label>Name<span class="login-danger">*</span></label>
                  <input
                    class="form-control inputs"
                    type="text"
                    v-model="templateInstance.name"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="input-block local-forms">
                  <label>Category <span class="login-danger">*</span></label>
                  <input
                    class="form-control inputs"
                    type="text"
                    v-model="templateInstance.category"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="input-block local-forms">
                  <label>Content <span class="login-danger">*</span></label>
                  <Summernote
                    v-model="templateInstance.content"
                    :initial="templateInstance.initialContent"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="doctor-submit text-end">
                <button
                  @click="submit"
                  type="submit"
                  class="btn btn-primary submit-form me-2"
                >
                  Submit
                </button>
                <span
                  type="submit"
                  class="btn btn-primary cancel-form bg-danger text-light"
                  >Cancel</span
                >
              </div>
            </div>
            <!-- body ends here -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Summernote from "../plugins/Summernote.vue";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { formatAmount } from "@/utils/formatter";
import { capitalize } from "vue";
import Swal from "sweetalert2";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Last Updated",
    key: "last_updated",
  },
  {
    title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Summernote,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },
  data() {
    return {
      isLoading: false,
      reponse: "",
      no_of_trans: 0,
      search: "",
      previewContent: "",
      templateRequest: {
        picture: null,
      },
      templateInstance: {
        content: null,
        category: null,
        name: null,
      },
      columns,
      imagePreview: null,
      formatAmount,
      capitalize,
      isUpdating: false,
      loggedAdmin: localStorage.getItem("role"),
    };
  },
  computed: {
    filterTableBy() {
      const uniqueCategories = Array.from(
        new Set(this.templates.map((template) => template.category))
      ).map((category) => ({
        title: category,
        key: "category",
        match: category,
      }));

      return uniqueCategories;
    },
    templates() {
      return this.$store.state.template.templates;
    },
  },
  methods: {
    ...mapActions({
      _fetchTemplates: "template/fetchTemplates",
      _editTemplate: "template/editTemplate",
      _addTemplate: "template/addTemplate",
      _deleteTemplate: "template/deleteTemplate",
      _requestTemplate: "template/requestNewTemplate",
    }),
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    downloadBookingReport(id) {
      this._downloadBookingReport(id);
    },
    async fetchTemplates() {
      try {
        await this._fetchTemplates();
      } finally {
        this.isLoading = false;
      }
    },
    deleteTemplate(id) {
      this._deleteTemplate(id);
    },
    requestTemplate() {
      this._requestTemplate(this.templateRequest).then((resp) => {
        $("#templateRequestModal").modal("hide");
        Swal.fire({
          title: "Template Request Submitted!",
          text: "Thank you for your request. Our team will begin creating your template shortly and will notify you once it is ready.",
          icon: "success",
        });
      });
    },
    submit() {
      if (this.isUpdating) {
        this._editTemplate({
          id: this.templateInstance.id,
          payload: this.templateInstance,
        }).then((resp) => {
          $("#templateModal").modal("hide");
          this.fetchTemplates();
          this.imagePreview = "";
        });
      } else {
        this._addTemplate(this.templateInstance).then((resp) => {
          $("#templateModal").modal("hide");
          this.fetchTemplates();
          this.imagePreview = "";
        });
      }
    },
    openModal(template2Edit = null) {
      if (template2Edit) {
        this.templateInstance = {
          ...template2Edit,
          initialContent: template2Edit.content,
        };
        this.isUpdating = true;
      } else {
        this.templateInstance = {
          initialContent: "",
        };

        this.isUpdating = false;
      }
      $("#templateModal").modal("show");
    },
    handleFileUpload(file) {
      if (file) {
        this.templateRequest.picture = file;
        this.$refs.fileUpload.style.setProperty("--filename", `"${file.name}"`);

        // Generate an image preview URL
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    previewTemplate(content) {
      $("#templatePreview").modal("show");
      this.previewContent = content;
    },
  },
  mounted() {
    setTimeout(()=>{
      this.fetchTemplates();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  inline-size: 100%;
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.dropdown-menu {
  inline-size: 100%;
}

.dropdown-item {
  cursor: pointer;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

@media (max-width: 480px) {
  #requnw {
    width: 100% !important;
  }
}
</style>
