<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Service Specialization </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Requests</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <filter-table :filter-by="filterTableBy" :search-keys="['patient_name', 'service_name']" :columns="columns"
          :data="serviceRequests" title="Service Specialization Requests" :loading="isLoading">
          <template #rightContent="data"> </template>
          <template #addGroup> </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'sample_number'">
              <div class="tooltip-container">
                <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                <div>
                  <span class="tooltip-text">
                    <span>Patient Name - {{ record.patient_name }}</span>
                    <span>Moblie Number -
                      {{ record.mobile_number }}</span>

                    <span>Patient Age - {{ record.patient_age }}</span>
                    <span>Patient Gender - {{ record.patient_sex }}</span>
                  </span>
                </div>
              </div>
            </template>
            <template v-if="column.key == 'amount'">
              <span> ₦ {{ formatAmount(record.amount) }} </span>
            </template>
            <template v-if="column.key == 'date'">
              <span>
                {{ getDatetime(record.created_at) }}
              </span>
            </template>
            <template v-if="column.key == 'action'">
              <div class="d-flex gap-2">
                <span class="btn btn-primary w-100" title="Make Report" @click="collectSpecimen(record.id)"
                  v-if="!record.collected">
                  <i class="fa fa-pen m-r-5"></i>Collect Specimen</span>
                <router-link class="btn btn-primary w-100" title="Make Report"
                  :to="`/Specialization-Requests/${encode(record.id)}`" v-else>
                  <i class="fa fa-pen m-r-5"></i>{{ record.note ? "Edit Report" : "Make Report" }}</router-link>

                <div class="dropdown dropdown-action" v-if="record.note">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="fa fa-ellipsis-v"></i></a>
                  <div class="dropdown-menu">
                    <span class="dropdown-item" title="Collect Sample" @click="completed(record.id)">
                      <i class="fa fa-check m-r-5"></i>Completed</span>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </filter-table>
      </div>
    </div>
  </div>
  <PatientDetails :patient_details_data_recieved="send_record" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
import { formatAmount } from "@/utils/formatter";
import { encode } from "@/utils/helpers";
import PatientDetails from "./plugin/patient_details.vue";

const columns = [


  {
    title: "Sample Number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Patient",
    dataIndex: "patient_name",
  },
  {
    title: "Service",
    dataIndex: "service_name",
  },
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    PatientDetails
  },
  data() {
    return {
      isLoading: false,
      serviceRequests: [],
      columns,
      formatAmount,
      encode,
      filterTableBy: [
        { title: "Paid Bookings", key: "status", exclude: "Unpaid" },
        { title: "Unpaid Bookings", key: "status", match: "Unpaid" },
      ],
      send_record: {}

    };
  },
  computed: {
    serviceRequests() {
      return this.$store.state.specializations.serviceRequests.map((serviceReq) => ({
        ...serviceReq,
        patient_name: serviceReq.patient.name,
        service_name: serviceReq.service.name,
        patient_age: `${serviceReq.patient.age} Yrs.`,
        patient_sex: serviceReq.patient.sex,
        mobile_number: serviceReq.patient.mobile_number,
      }));
    },
  },
  methods: {
    ...mapActions({
      _fetchRequests: "specializations/fetchRequests",
      _collectRequestSpecimen: "specializations/collectRequestSpecimen",
      _completedRequestSpecimen: "specializations/completedRequestSpecimen",
      _editRequestReport: "specializations/editRequestReport",
      _downloadBookingReport: "quickService/downloadBookingReport",
    }),
    view_patient_details(record) {
      this.send_record = record;
      $(".patient_details-modal").modal("show");
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    // async fetchRequests() {
    //   await this._fetchRequests();
    //   this.isLoading = false;
    // },
    async fetchRequests() {
  try {
    await this._fetchRequests();
  } finally {
    this.isLoading = false;
  }
},
    collectSpecimen(serviceId) {
      this._collectRequestSpecimen(serviceId);
    },
    completed(serviceId) {
      this._completedRequestSpecimen(serviceId).then((response) => {
        // this.response = response.data;
        if (response.data.status == true) {
          // this.response = this.response.data
          // console.log(this.response)
          this.$toast.success("Test completed");
          this._fetchRequests();
        }
      })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });;
    },

    editReport(serviceId) {
      this._editRequestReport(serviceId);
    },
    downloadBookingReport(quickServiceId) {
      this._downloadBookingReport(quickServiceId);
    },
    getTypeClass(type) {
      return {
        "text-red": type === "debt",
        "text-green": type === "credit",
        // Add more classes for other types if needed
      };
    },
  },
  mounted() {
    setTimeout(()=>{
      this.fetchRequests();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  inline-size: 100%;
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.dropdown-menu {
  inline-size: 100%;
}

.dropdown-item {
  cursor: pointer;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}
</style>
