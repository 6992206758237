import { createStore } from "vuex";
import {
  general,
  finance,
  doctor,
  patient,
  gopd,
  pharmacy,
  staff,
  lab,
  radiology,
  roomAllotment,
  dashboards,
  patient_chart,
  chat,
  subscription,
  splash_screen,
} from "./adminStore";
import router from "@/router";
import { api, baseURL, url } from "@/api";
import { quickService, specializations } from "./quick-services";
import { template } from "./template";

export const store = createStore({
  state: {
    isAuth: localStorage.getItem("accessToken") ? true : false,
    adminDetails: JSON.parse(localStorage.getItem("ADMIN_DETAILS") || "{}"),
    adminPermissions: JSON.parse(
      localStorage.getItem("ADMIN_PERMISSIONS") || "[]"
    ),
    pendingRequests: 0,
    isOnFlutter: navigator.userAgent == "StellaSync Mobile App",
    has_active_subscription:
      localStorage.getItem("has_active_subscription") === "true" ? true : false,
    role_name: localStorage.getItem("role_name_special"),
  },
  mutations: {
    SET_ADMIN_DETAILS: (state, payload) =>
      localStorage.setItem("ADMIN_DETAILS", JSON.stringify(payload)),
    /** This Saves the Admin Permissions */
    SET_ADMIN_PERMISSIONS: (state, payload) =>
      localStorage.setItem("ADMIN_PERMISSIONS", JSON.stringify(payload)),
  },
  getters: {
    /** This Gets the Permission that A Url has by get it's route meta.permission*/
    getRoutePermission: (state) => (routeLink) => {
      /** Find the route */
      let route = router
        .getRoutes()
        .find((route) => route.path.match(routeLink));
      return route ? route.meta.permission : false;
    },
    /** This Checks if an Admin has a Permission */
    adminHasPermission: (state) => (permission) => {
      // Return True For Super Admin
      if (state.adminDetails.role_name == "Super Admin") {
        return true;
      }
      // Checking if the admin has the permission
      let permissionSearch = state.adminPermissions.find(
        (adminPermission) => adminPermission == permission
      );
      // Super Admin
      return permissionSearch ? true : false;
    },
    canAccess: (state) => (permissions) => {
      let canAccess = permissions.find((permission) =>
        store.getters.adminHasPermission(permission)
      );
      return canAccess ? true : false;
    },
  },
  actions: {
    async getAdminDetails({ commit, state }) {
      if (state.isAuth) {
        let response = await api.get(`${baseURL}profile`);
        let data = response.data.data;
        // localStorage.setItem("hospital", data.hospital_name);
        commit("SET_ADMIN_DETAILS", data);
        commit("SET_ADMIN_PERMISSIONS", data.permissions);
      }
    },
    recordActivity(
      {},
      { name, action, patientId, details } = { details: [], patientId: null }
    ) {
      api.post(url(`record-activity`), {
        name,
        action,
        details,
        patient_id: patientId,
      });
    },
    fetchAdminProfile({ dispatch }, { redirect } = { redirect: false }) {
      api
        .get(url(`profile`))
        .then((response) => {
          dispatch("loginAdmin", {
            profileData: {
              ...response.data.data,
              bearer_token: localStorage.getItem("accessToken"),
            },
            redirect,
          });
        })
        .catch((error) => {});
    },
    loginAdmin(
      { context, commit, dispatch },
      { profileData, redirect } = { redirect: false }
    ) {
      $("#btntext").show();

      var token = profileData.bearer_token;
      // pasword is saved in local storage it will be hashed
      // localStorage.setItem("password", password);
      localStorage.setItem("decrypted_pin", profileData.decrypted_pin);
      localStorage.setItem("accessToken", token);
      localStorage.setItem("back_up_accessToken", token);
      commit("SET_ADMIN_DETAILS", profileData);
      commit("SET_ADMIN_PERMISSIONS", profileData.permissions);

      localStorage.setItem(
        "name",
        `${profileData.first_name + " " + profileData.last_name}`
      );
      localStorage.setItem("email", profileData.email);
      localStorage.setItem("phone_number", profileData.phone_number);
      localStorage.setItem("role", profileData.role_name);
      localStorage.setItem("doc_id", profileData.id);
      localStorage.setItem("hospital", profileData.hospital);
      localStorage.setItem("hospital_id", profileData.hospital_id);
      localStorage.setItem("pin", profileData.pin);
      localStorage.setItem("password_change", profileData.password_change);
      localStorage.setItem("institution", profileData.institution);
      localStorage.setItem("trial_period", profileData.trial_period);
      localStorage.setItem("isInstitution", profileData.isInstitution);
      localStorage.setItem("settings", profileData.settings);
      localStorage.setItem(
        "settings_info",
        JSON.stringify(profileData.settings_info)
      );

      localStorage.setItem("checker", 1);
      localStorage.setItem(
        "has_active_subscription",
        profileData.has_active_subscription
      );

      if (redirect) {
        dispatch("take2Dashboard");
      }
    },
    take2Dashboard({}) {
      const role = localStorage.getItem("role");
      const institution = localStorage.getItem("institution");
      if (role == "Super Admin" || role == "Admin" || role == "Hospital") {
        window.toast.success("Login successful");
        window.location = "/Hospital-Dashboard";
      } else if (role == "Doctor" || role == "doctor") {
        window.toast.success("Login successful");
        window.location = "/Doctor-Dashboard";
        // console.log(profileData.permissions);
      } else if (role == "Finance") {
        window.toast.success("Login successful");
        window.location = "/Finance-Dashboard";
      } else if (role == "GOPD") {
        window.toast.success("Login successful");
        window.location = "/GOPD-Dashboard";
      } else if (role == "Pharmacy") {
        window.toast.success("Login successful");
        window.location = "/Pharmacy-Dashboard";
      } else if (role == "Lab") {
        window.toast.success("Login successful");
        window.location = "/Laboratory-Dashboard";
      } else if (role == "Radiology") {
        window.toast.success("Login successful");
        window.location = "/Radiology-Dashboard";
      } else if (role == "Nurse") {
        window.toast.success("Login successful");
        window.location = "/Nurse-Dashboard";
      }
      // special case for institution
      else if (role == "Front Desk") {
        window.toast.success("Login successful");
        if (institution == "Admin") {
          window.location = "/FrontDesk-Dashboard";
        } else if (institution == "Clinic") {
          window.location = "/Clinic-FrontDesk-Dashboard";
        }
      } else if (role == "Clinic") {
        window.toast.success("Login successful");
        window.location = "/Clinic-Dashboard";
      } else {
        window.toast.success("Login successful");
        window.location = "/Clinic-Dashboard";
      }
    },
  },

  modules: {
    general,
    finance,
    doctor,
    patient,
    pharmacy,
    gopd,
    staff,
    lab,
    radiology,
    roomAllotment,
    dashboards,
    patient_chart,
    chat,
    subscription,
    specializations,
    quickService,
    template,
    splash_screen,
  },
});
