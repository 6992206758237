import kestrion from "@/assets/kestrion/kestrion.min.js";

let kestrionConfig = new kestrion({
  isDev: false,
  userUUID: "",
  appKey: "e73a80bsf088b38af856d3553556e9ddbl55c7eb92",
  localVideo: "",
  remoteVideo: "",
  audioOnly: false,
});

export { kestrionConfig };
