<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Patient Biodata</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">
                {{ chart_view }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="obserchartview">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>{{ chart_view }}</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 col-md-12 col-sm-12 float-end"
                    id="nextlineobserve"
                    style="margin-top: 20px"
                  >
                    <a-select
                      class="float-end"
                      style="width: 70%"
                      v-model:value="chart_view"
                      @change="switch_table()"
                    >
                      <a-select-option value="Observation Chart"
                        >Observation Chart</a-select-option
                      >
                      <a-select-option value="Fluid Balance Chart_in"
                        >Fluid Balance (in)</a-select-option
                      >
                      <a-select-option value="Fluid Balance Chart_out"
                        >Fluid Balance (out)</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
              <!-- /Table Header table-bordered-->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="data" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'fluid_intake_time'">
                      <span style="font-weight: bold">
                        {{ convertTo12HourFormat(record.fluid_intake_time) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'fluid_output_time'">
                      <span style="font-weight: bold">
                        {{ convertTo12HourFormat(record.fluid_output_time) }}
                      </span>
                    </template>

                    <template v-if="column.key == 'date'">
                      <span
                        style="
                          font-weight: bold;
                          width: max-content !important;
                          display: block;
                        "
                      >
                        {{ formatDate(record.created_at, true) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'time_taken'">
                      <span
                        style="
                          font-weight: bold;
                          width: max-content !important;
                          display: block;
                        "
                      >
                        {{ formatDate(record.time_taken, true, true) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="d-flex align-items-center gap-2">
                        <button
                          class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          title="Edit Record"
                          @click="openModal(record)"
                        >
                          <i class="fa fa-edit m-r-5"></i>Update
                        </button>

                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span
                              class="dropdown-item"
                              title="View History"
                              @click="showHistory(record.id)"
                            >
                              <i class="fa fa-history m-r-5"></i>View History</span
                            >

                            <!-- <span class="dropdown-item" @click="completed(record.id)"><i class="fa fa-check m-r-5"></i>
                    Completed</span> -->
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <ObservationChart
      @updated="(id, data) => refreshUpdatedRecord(id, data)"
      :record="selectedRecord.observation_chart"
    />
    <AddFluidChartIn
      :record="selectedRecord.fluid_balance_intake"
      @updated="(id, data) => refreshUpdatedRecord(id, data)"
    />
    <AddFluidChartOut
      :record="selectedRecord.fluid_balance_output"
      @updated="(id, data) => refreshUpdatedRecord(id, data)"
    />
    <!-- history modal -->
    <div
      class="modal modal-lg animate__animated animate__zoomIn animate__faster history_modal"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>History</h3>
            </div>
            <div class="table-responsive" style="overflow: visible">
              <a-table
                :dataSource="selectedRecord.updateHistory"
                :columns="historyColumn"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key == 'fluid_intake_time'">
                    <span style="font-weight: bold">
                      {{ convertTo12HourFormat(record.fluid_intake_time) }}
                    </span>
                  </template>
                  <template v-if="column.key == 'fluid_output_time'">
                    <span style="font-weight: bold">
                      {{ convertTo12HourFormat(record.fluid_output_time) }}
                    </span>
                  </template>

                  <template v-if="column.key == 'date'">
                    <span
                      style="
                        font-weight: bold;
                        width: max-content !important;
                        display: block;
                      "
                    >
                      {{ formatDate(record.created_at, true) }}
                    </span>
                  </template>
                  <template v-if="column.key == 'time_taken'">
                    <span
                      style="
                        font-weight: bold;
                        width: max-content !important;
                        display: block;
                      "
                    >
                      {{ formatDate(record.time_taken, true, true) }}
                    </span>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="doctor-submit text-end">
              <span
                class="btn btn-danger cance_fluid_chart_out bg-danger text-light"
                data-bs-dismiss="modal"
                >Close</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jquery from "jquery";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import { formatDate } from "@/utils/formatter";
import AddFluidChartIn from "./plugin/Fluid_Chart_in.vue";
import AddFluidChartOut from "./plugin/Fluid_Chart_out.vue";
import ObservationChart from "./plugin/Observation Chart.vue";

const observationChartColumns = [
  {
    title: "Entry Time",
    key: "date",
  },
  {
    title: "Time Taken",
    key: "time_taken",
  },
  {
    title: "Temperature (°C)",
    dataIndex: "temperature",
    key: "temperature",
  },
  {
    title: "Pulse (BPM)",
    dataIndex: "pulse",
  },

  {
    title: "Respiration (cm3/min)",
    key: "respiration",
    dataIndex: "respiration",
  },

  {
    title: "Blood Pressure (mmHg)",
    dataIndex: "blood_pressure",
  },
  {
    title: "Oxygen",
    dataIndex: "oxygen",
  },

  {
    title: "Response to Call",
    dataIndex: "response_to_call",
  },
  {
    title: "Response to Stimuli",
    dataIndex: "response_to_stimuli",
  },
  {
    title: "Action",
    key: "action",
  },
];

const fluidIntakeColumns = [
  {
    title: "Entry Time",
    key: "date",
  },
  // {
  //   title: "Time Taken",
  //   key: "time_taken",
  // },
  {
    title: "Time Taken",
    dataIndex: "fluid_intake_time",
    key: "fluid_intake_time",
  },

  {
    title: "Route",
    dataIndex: "fluid_intake_route",
    key: "fluid_intake_route",
  },
  {
    title: "Amount",
    dataIndex: "fluid_intake_amount",
    key: "fluid_intake_amount",
  },
  {
    title: "Type of Fluid",
    dataIndex: "fluid_intake_type",
    key: "fluid_intake_type",
  },
  {
    title: "Action",
    key: "action",
  },
];

const fluidOutputColumns = [
  {
    title: "Entry Time",
    key: "date",
  },
  // {
  //   title: "Time Taken",
  //   key: "time_taken",
  // },
  {
    title: "Time Taken",
    dataIndex: "fluid_output_time",
    key: "fluid_output_time",
  },

  {
    title: "Urine",
    dataIndex: "fluid_output_urine",
    key: "fluid_output_urine",
  },
  {
    title: "Drain",
    dataIndex: "fluid_output_drain",
    key: "fluid_output_drain",
  },
  {
    title: "NG Tube",
    dataIndex: "fluid_output_ng_tube",
    key: "fluid_output_ng_tube",
  },
  {
    title: "Vomit",
    dataIndex: "fluid_output_vomit",
    key: "fluid_output_vomit",
  },
  {
    title: "Feaces",
    dataIndex: "fluid_output_faeces",
    key: "fluid_output_faeces",
  },
  {
    title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",

  data() {
    return {
      data: [],
      isLoading: true,
      chart_view: "Observation Chart",
      columns: observationChartColumns,
      observationChartColumns,
      fluidIntakeColumns,
      fluidOutputColumns,
      id: localStorage.getItem("this_patient_id"),
      formatDate,
      patientData: {
        observation_chart: [],
        fluid_balance_intake: [],
        fluid_balance_output: [],
      },
      selectedRecord: {
        updateHistory: [],
        observation_chart: null,
        fluid_balance_intake: null,
        fluid_balance_output: null,
      },
    };
  },
  components: {
    ObservationChart,
    AddFluidChartIn,
    AddFluidChartOut,
  },
  computed: {
    historyColumn() {
      return [
        // append updated by
        {
          title: "Updated By",
          dataIndex: "changed_by",
        },
        ...this.columns.filter((column) => column.key != "action"), // remove action column
      ];
    },
    currentlyViewedType() {
      return this.chart_view == "Observation Chart"
        ? "observation_chart"
        : this.chart_view == "Fluid Balance Chart_in"
        ? "fluid_balance_intake"
        : "fluid_balance_output";
    },
    modalSelector() {
      return this.chart_view == "Observation Chart"
        ? "observation_chart_modal"
        : this.chart_view == "Fluid Balance Chart_in"
        ? "fluid_chart_modal_in"
        : "fluid_chart_modal_out";
    },
  },
  methods: {
    ...mapActions({
      _get_bioData: "patient/get_bioData",
      _get_updateHistory: "patient/get_updateHistory",
    }),
    showHistory(biodataId) {
      this._get_updateHistory({ biodataId, type: this.currentlyViewedType }).then(
        (response) => {
          this.selectedRecord.updateHistory = response.data.data;
          $(".history_modal").modal("show");
        }
      );
    },
    openModal(record) {
      this.selectedRecord[this.currentlyViewedType] = record;
      $(`.${this.modalSelector}`).modal("show");
    },
    refreshUpdatedRecord(updatedRecordId, updatedData) {
      $(`.${this.modalSelector}`).modal("hide");

      this.patientData[this.currentlyViewedType].findIndex(
        (record) => record.id == updatedRecordId
      );
      this.patientData[this.currentlyViewedType] = updatedData;
    },

    getObservationChart() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "observation_chart",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patientData.observation_chart = this.response.data;
            this.data = this.patientData.observation_chart;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getFluidIntake() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "fluid_balance_intake",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patientData.fluid_balance_intake = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getFluidOutput() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "fluid_balance_output",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patientData.fluid_balance_output = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    switch_table() {
      if (this.chart_view == "Observation Chart") {
        this.columns = this.observationChartColumns;
        this.data = this.patientData.observation_chart;
      } else if (this.chart_view == "Fluid Balance Chart_in") {
        this.columns = this.fluidIntakeColumns;
        this.data = this.patientData.fluid_balance_intake;
      } else if (this.chart_view == "Fluid Balance Chart_out") {
        this.columns = this.fluidOutputColumns;
        this.data = this.patientData.fluid_balance_output;
      }
    },
    convertTo12HourFormat(time) {
      if (!time) {
        return;
      }
      const [hours, minutes] = time.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
    },
  },

  mounted() {
    this.getObservationChart();
    this.getFluidIntake();
    this.getFluidOutput();

    // this.switch_table()
  },
};
</script>

<style scoped>
@media (max-width: 1280px) {
  .doctor-table-blk {
    margin-bottom: 20px;
  }
  .top-nav-search form {
    width: 750px;
  }
}

@media (max-width: 800px) {
  #obserchartview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .top-nav-search form {
    width: 520px;
  }

  .doctor-search-blk .top-nav-search {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #obserchartview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }

  #nextlineobserve {
    width: 100% !important;
  }

  .top-nav-search form {
    width: 100%;
  }

  .doctor-search-blk .top-nav-search {
    width: 100%;
  }
}
</style>
