<template>
  <form action="javaScript:void(0)">
    <!-- <form> -->
    <div class="row">
      <!-- <div class="col-12">
        <div class="form-heading">
          <h4>Add Medicine</h4>
        </div>
      </div> -->
      <!-- {{ formData }} -->
      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Medicine Name <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            v-model="formData.drug_name"
          />
        </div>
      </div>
      <!-- <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Category <span class="login-danger">*</span></label>
          <select
            class="form-control"
            type="text"
            name="cat"
            v-model="formData.drug_category"
          >
            <option value="">Select Category</option>
            <option
              :value="drug_category.id"
              v-for="drug_category in drug_categories"
              :key="drug_category.id"
            >
              {{ drug_category.category }}
            </option>
          </select>
        </div>
      </div> -->

      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Company Name </label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            v-model="formData.company_name"
          />
        </div>
      </div>

      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Mass (kg) </label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.mass"
          />
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Expiry Date <span class="login-danger">*</span></label>
          <input
            id="edate"
            class="form-control"
            type="date"
            placeholder=""
            v-model="formData.expiry_date"
          />
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Medicine Type<span class="login-danger">*</span></label>
          <a-select
            class=""
            type="text"
            name="cat"
            v-model:value="formData.drug_type"
            @change="clearer()"
          >
            <a-select-option value="" disabled>Select Type</a-select-option>
            <a-select-option value="Tablets">Tablets</a-select-option>
            <a-select-option value="Liquids">Liquids</a-select-option>
            <a-select-option value="Injectables">Injectables</a-select-option>
            <a-select-option value="Inhalers">Inhalers</a-select-option>
            <!-- <option value="">Select Type</option>
            <option
              :value="drug_type.type"
              v-for="drug_type in drug_types"
              :key="drug_type.id"
            >
              {{ drug_type.type }}
            </option>
            <option value="Syrup">Syrup</option> -->
          </a-select>
        </div>
      </div>

      <div
        class="col-12 col-md-4 col-xl-4"
        v-if="formData.drug_type == 'Tablets'"
      >
        <div class="input-block local-forms">
          <label>Drug Container Type<span class="login-danger">*</span></label>
          <a-select
            class=""
            type="text"
            name="cat"
            v-model:value="formData.tablet_type"
          >
            <a-select-option value="" disabled
              >Select Drug Container Type</a-select-option
            >
            <a-select-option value="pack">Pack</a-select-option>
            <a-select-option value="bottle">Bottle</a-select-option>
            <!-- <option value="Syrup">Syrup</option> -->
          </a-select>
        </div>
      </div>

      <!-- drug quantities -->
      <div class="col-12 col-md-4 col-xl-4" v-else>
        <div class="input-block local-forms">
          <label>Quantity <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.quantity"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-4 col-xl-4"
        v-if="formData.drug_type != 'Tablets'"
      >
        <div class="input-block local-forms">
          <label>Selling Price <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.price"
          />
        </div>
      </div>
      <!-- <div
        class="col-12 col-md-4 col-xl-4"
        v-if="
          formData.tablet_type == 'pack' ||
          (formData.tablet_type == 'bottle' && formData.drug_type == 'Tablets')
        "
      >
        <div class="input-block local-forms">
          <label v-if="formData.tablet_type == 'pack'">
            Pack(s) <span class="login-danger">*</span></label
          >
          <label v-if="formData.tablet_type == 'bottle'">
            Bottle(s) <span class="login-danger">*</span></label
          >
          <input
            class="form-control"
            type="number"
            placeholder="Number of pack(s)"
            v-model="formData.quantity"
            id="pack_q"
          />
        </div>
      </div> -->

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="
          formData.tablet_type == 'pack' ||
          (formData.tablet_type == 'bottle' && formData.drug_type == 'Tablets')
        "
      >
        <div class="input-block local-forms">
          <label v-if="formData.tablet_type == 'pack'">
            Pack Price <span class="login-danger">*</span></label
          >
          <label v-if="formData.tablet_type == 'bottle'">
            Bottle Price <span class="login-danger">*</span></label
          >
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.price"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="formData.tablet_type == 'pack' && formData.drug_type == 'Tablets'"
      >
        <div class="input-block local-forms">
          <label>Card(s) <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder="Number of cards"
            v-model="formData.card_per_pack"
          />
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="formData.tablet_type == 'pack' && formData.drug_type == 'Tablets'"
      >
        <div class="input-block local-forms">
          <label>Card Price <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.card_price"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="
          formData.tablet_type == 'pack' ||
          (formData.tablet_type == 'bottle' && formData.drug_type == 'Tablets')
        "
      >
        <div class="input-block local-forms">
          <label>Tablet(s) <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            :placeholder="
              formData.tablet_type == 'pack'
                ? 'Number of tablets per Card'
                : 'Number of tablets per bottle'
            "
            v-model="formData.tablet_per_card"
          />
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="formData.drug_type == 'Tablets'"
      >
        <div class="input-block local-forms">
          <label>Tablet Price <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.tablet_price"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="
          formData.tablet_type == 'pack' ||
          (formData.tablet_type == 'bottle' && formData.drug_type == 'Tablets')
        "
      >
        <div class="input-block local-forms">
          <label v-if="formData.tablet_type == 'pack'">
            Available Pack(s)<span class="login-danger">*</span></label
          >
          <label v-if="formData.tablet_type == 'bottle'">
            Available Bottle(s) <span class="login-danger">*</span></label
          >
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.quantity"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="
          formData.tablet_type == 'pack' &&
          formData.drug_type == 'Tablets' &&
          formData.quantity == 0
        "
      >
        <div class="input-block local-forms">
          <label>Available Card(s) <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.available_card"
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-xl-6"
        v-if="formData.drug_type == 'Tablets' && formData.quantity == ''"
      >
        <div class="input-block local-forms">
          <label>Available Tablet(s)<span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            placeholder=""
            v-model="formData.available_tablet"
          />
        </div>
      </div>

      <!-- <div class="col-12 col-md-6 col-xl-6">
        <div class="input-block local-forms">
          <label>Content <span class="login-danger">*</span></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            v-model="formData.content"
          />
        </div>
      </div> -->

      <div class="col-12 col-md-12 col-xl-12">
        <div class="input-block local-forms">
          <label>Description </label>
          <textarea
            class="form-control"
            rows="3"
            cols="30"
            v-model="formData.description"
          ></textarea>
        </div>
      </div>

      <div class="col-12 col-md-4 col-xl-4">
        <div class="input-block local-forms">
          <label>Image </label>
          <input
            class="form-control"
            type="file"
            placeholder=""
            accept="*"
            @change="handleImageChange"
          />
        </div>
      </div>

      <div v-if="formData.drug_type == 'Tablets'">
        <h3>Drug Summary</h3>
        <div>
          <span class="sh">Packs or Bottles : </span>
          <span class="sv"> {{ formData.quantity || 0 }}</span>
        </div>
        <div :hidden="formData.tablet_type != 'pack'">
          <span class="sh">Card Per Pack : </span>
          <span class="sv"> {{ this.formData.card_per_pack || 0 }}</span>
        </div>
        <div>
          <span class="sh">Tablets Per Card : </span>
          <span class="sv"> {{ this.formData.tablet_per_card || 0 }}</span>
        </div>
        <div v-if="this.formData.available_card">
          <span class="sh">Excess Cards : </span>
          <span class="sv"> {{ this.formData.available_card || 0 }}</span>
        </div>
        <div v-if="formData.available_tablet">
          <span class="sh">Excess Tablets : </span>
          <span class="sv"> {{ this.formData.available_tablet || 0 }}</span>
        </div>
        <h3>
          <span class="sh">Total Tablets : </span>
          <span class="sv">{{ T_tablet_Q || 0 }} </span>
        </h3>
      </div>

      <div class="col-12">
        <div class="doctor-submit text-end">
          <button
            type="submit"
            class="btn btn-primary submit-form me-2"
            @click="edit_imported_Drug()"
          >
            Submit
          </button>
          <span
            type="submit"
            class="btn btn-primary cancel-form bg-danger text-light"
            @click="closeEditModal()"
            >Cancel</span
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drug_categories: [],
      drug_types: [],
      //  formData:this.edit_data_recieved,
      // tablet_per_card: null??'',
      T_tablet_Q: null ?? "",
      tablet: null ?? "",
      card: null ?? "",
      pack: null ?? "",
    };
  },

  methods: {
    clearer() {
      if (this.formData.drug_type != "Tablets") {
        this.formData.tablet_type = "";
      }
    },
    ...mapActions({
      _getDrugCategories: "pharmacy/getDrugCategories",
      _edit_imported_Drug: "pharmacy/edit_imported_Drug",
      _getdrug_types: "pharmacy/getdrug_types",
    }),

    handleImageChange(event) {
      this.formData.avatar = event.target.files[0];
    },

    edit_imported_Drug() {
      $(".inloader").show();
      // let formData = new FormData();
      // formData.append("drug_name", this.formData.drug_name);
      // formData.append("drug_category", this.formData.drug_category);
      // formData.append("drug_type", this.formData.drug_type);
      // // formData.append("original_price", this.formData.original_price);
      // formData.append("price", this.formData.price);
      // formData.append("expiry_date", this.formData.expiry_date);
      // formData.append("quantity", this.formData.quantity);
      // formData.append("company_name", this.formData.company_name);
      // formData.append("description", this.formData.description);
      // formData.append("content", this.formData.content);
      // formData.append("mass", this.formData.mass);
      // formData.append("avatar", this.formData.avatar);
      // formData.append("tablet_type", this.formData.tablet_type);
      // formData.append("tablet_per_card", this.formData.tablet_per_card);
      // // formData.append("quantity", this.formData.quantity);
      // formData.append("card_per_pack", this.formData.card_per_pack);
      // formData.append("tablet_price", this.formData.tablet_price);
      // // formData.append("price", this.formData.price);
      // formData.append("card_price", this.formData.card_price);
      // formData.append("tablet_type", this.formData.tablet_type);

      this._edit_imported_Drug({
        drugId: this.formData.id,
        payload: this.formData,
      })
        .then((response) => {
          window.location = "/Medicine-Draft-List";
          $(".inloader").hide();
          $("#con-close-modal").modal("hide");
          // window.location.reload();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
            this.formData = {
              drug_name: "",
              drug_category: "",
              original_price: "",
              price: "",
              expiry_date: "",
              drugype: "",
              quantity: "",
              company_name: "",
              description: "",
              content: "",
              mass: "",
              avatar: null ?? "",
            };
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          // } else {
          //   this.$toast.error("An unexpected error occurred.");
          // }
        });
    },

    getDrugCategories() {
      this._getDrugCategories()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_categories = this.response.data;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getdrug_types() {
      this._getdrug_types()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_types = this.response.data;
            // this.$toast.success(this.response["message"]);
            console.log(this.drug_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    closeEditModal() {
      $("#edit-close-modal").modal("hide");
      this.edit_data = {};
    },

    disabler() {
      if (
        this.formData.tablet_type == "pack" ||
        this.formData.tablet_type == "bottle"
      ) {
        $("#pack_q").prop("disabled", true);
      }
    },

    calculateTabletQuantity() {
      if (this.formData.tablet_type == "pack") {
        if (this.formData.quantity == 0) {
          this.T_tablet_Q =
            this.formData.available_card * this.formData.tablet_per_card +
            this.formData.available_tablet;
        }else{
          this.T_tablet_Q =
            Number(this.formData.card_per_pack) *
            Number(this.formData.quantity) *
            Number(this.formData.tablet_per_card);
        }
      } else {
        this.T_tablet_Q =
          Number(this.formData.quantity) *
          Number(this.formData.tablet_per_card);
      }
    },

    calculateTabletQuantity1() {
      if (this.formData.tablet_type == "pack") {
        // if (this.formData.quantity == 0) {
        //   this.pack = this.formData.available_quantity;
        // } else {
        //   this.pack = this.formData.quantity;
        // }

        if (
          this.formData.available_card == 0 ||
          this.formData.available_card == null
        ) {
          this.card = this.formData.available_card;
        } else {
          this.card = this.formData.card_per_pack;
        }

        if (
          this.formData.tablet_per_card == 0 ||
          this.formData.tablet_per_card == null
        ) {
          this.tablet = this.formData.available_tablet;
        } else {
          this.tablet = this.formData.tablet_per_card;
        }

        this.T_tablet_Q =
          Number(this.card) * Number(this.pack) * Number(this.tablet);
      } else {
        this.T_tablet_Q =
          Number(this.formData.quantity) *
          Number(this.formData.tablet_per_card);
      }
    },
  },
  watch: {
    "formData.tablet_per_card": {
      handler(newVal) {
        this.calculateTabletQuantity();
      },
      immediate: true,
    },
    "formData.quantity": {
      handler(newVal) {
        if(this.formData.quantity > 0){
          this.formData.available_card = null;
          this.formData.available_tablet = null;
        }
        this.calculateTabletQuantity();
      },
      immediate: true,
    },
    "formData.card_per_pack": {
      handler(newVal) {
        this.calculateTabletQuantity();
      },
      immediate: true,
    },
    "formData.available_card": {
      handler(newVal) {
        this.calculateTabletQuantity();
      },
      immediate: true,
    },
    "formData.available_tablet": {
      handler(newVal) {
        this.calculateTabletQuantity();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getDrugCategories();
    this.getdrug_types();
    const currentDate = new Date().toISOString().split("T")[0];
    // Set the minimum date for the date input
    document.getElementById("edate").min = currentDate;
    this.disabler();
  },
};
</script>

<style soped>
@media (max-width: 1280px) {
  .modal-content {
    overflow: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    max-height: 600px;
    border: 5px solid white;
  }
}

@media (max-width: 800px) {
  #edit-close-modal {
    margin-left: 0px !important;
  }

  .modal-dialog {
    margin-left: 30px !important;
  }

  .modal-content {
    width: 750px !important;
  }
}

@media (max-width: 480px) {
  #edit-close-modal {
    margin-left: 0px !important;
  }

  .modal-dialog {
    margin-left: 15px !important;
  }

  .modal-content {
    width: 100% !important;
  }

  .col-12 {
    margin-top: 5% !important;
  }
}
</style>
