<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />
  <Waiter class="waiter" />
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Dashboard </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Patient Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <div class="good-morning-blk">
	<div class="row">
	<div class="col-md-6">
	<div class="morning-user">
	<h2>Good Morning, <span>{{dash_name}}</span></h2>
	<p>Have a nice day at work</p>
	</div>
	</div>
	<div class="col-md-6 position-blk">
	<div class="morning-img">
	<img src="../../assets/assets/img/morning-img-03.png" alt="">
	</div>
	</div>
	</div>
	</div> -->
      <div class="row">
        <div
          class="patient_name col-xl-6 col-12"
          style="font-size: 26px; margin-bottom: 20px"
        >
          <!-- {{ patient_name }} -->
          <sup>
            <span
              class="badge badge-outline-primary topbadge"
              v-if="stat == 'Admitted'"
              >{{ stat }}</span
            >
            <span
              class="badge badge-outline-success topbadge"
              v-if="stat == 'Discharged'"
              >{{ stat }}</span
            >
            <span
              class="badge badge-outline-dark topbadge"
              v-if="stat == 'Out Patient'"
              >{{ stat }}</span
            >
          </sup>
        </div>
        <!-- <div class="stat col-2 col-md-2 col-lg-2 col-xl-2 text-end"></div> -->
        <!-- <div class="stat col-2 col-md-2 col-lg-2 col-xl-2">
          <span class="badge badge-danger topbadge" v-if="collab == 'true'"
            >In Collaboration</span
          >
        </div> -->
        <div class="stat col-12 col-md-12 col-lg-12 col-xl-6" id="addington">
          <!-- <router-link
            to="/Patient-Timeline"
            class="waves-effect waves-light btn btn-primary topbadge1"
          >
            <i class="fa fa-history m-r-5"></i> View Timeline
          </router-link> -->

          <div class="dropdown dropdown-action">
            <a
              href="#"
              class="waves-effect waves-light btn btn-primary topbadge1 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-plus m-r-5"></i> Add Biodata
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <span class="dropdown-item open_vital" title="Add Biodata" hidden>
                <i class="fa fa-heartbeat m-r-5"></i> Patient Vital Signs</span
              >
              <span
                class="dropdown-item open_observation_chart"
                title="Add Observation Chart"
              >
                <i class="fa fa fa-stethoscope m-r-5"></i>Observation
                Chart</span
              >
              <span
                class="dropdown-item open_pre_fluid_chart"
                title="Add Fluid Balance Chart"
              >
                <i class="fa fa-hospital m-r-5"></i> Fluid Balance Chart</span
              >
            </div>
          </div>

          <!-- first one -->
          <div class="dropdown dropdown-action">
            <a
              href="#"
              class="waves-effect waves-light btn btn-primary topbadge1 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-plus m-r-5"></i> Add Record
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link
                to="Patient-Note"
                class="dropdown-item"
                title="Add Note"
              >
                <span
                  v-if="whoisLoggedin == 'Doctor' || whoisLoggedin == 'Admin'"
                >
                  <i class="fa fa-sticky-note-o m-r-5" aria-hidden="true"
                    >&#xf24a;</i
                  >
                  Add Note
                </span>
                <span v-if="whoisLoggedin == 'Nurse'"
                  ><i class="fa fa-pills m-r-5"></i> Administer Drugs</span
                >
              </router-link>
              <!-- <span class="dropdown-item open_vital" title="Add Biodata">
                <i class="fa fa-heartbeat m-r-5"></i> Add Biodata</span> -->
              <span class="dropdown-item open_billing" title="Add Billing">
                <!-- <i class="fa fa-money m-r-5"></i> Add Service Charges -->
                <i class="fa fa-money m-r-5">&#xf0d6;</i> Billing
              </span>
              <span
                v-if="whoisLoggedin == 'Nurse' || whoisLoggedin == 'Admin'"
                class="dropdown-item add_nurse_note"
                title="Add Nurse Note"
                ><i class="fa fa-sticky-note m-r-5"></i> Nurse Summary</span
              >

              <span
                v-if="whoisLoggedin == 'Admin' || whoisLoggedin == 'Doctor'"
                class="dropdown-item add_operation_note"
                title="Add Operation Note"
                ><i class="fa fa-sticky-note m-r-5"></i> Operation Notes</span
              >

              <!-- <router-link to="Patient-Note" class="dropdown-item" title="Add Biodata">
                <i class="fa fa-pills m-r-5"></i> Administer Drugs</router-link> -->
            </div>
          </div>

          <router-link
            to="/Patient-Timeline"
            class="waves-effect waves-light btn btn-primary topbadge1"
          >
            <i class="fa fa-history m-r-5"></i> View Timeline
          </router-link>
        </div>
      </div>

      <div class="row" id="addingtontwo">
        <div class="col-12" id="allowide">
          <div class="row">
            <div class="profile-container">
              <div class="profile-header">
                <div class="patienttablefirst" style="width: 100%">
                  <table class="patient-info-table">
                    <tr>
                      <th rowspan="3">
                        <img
                          v-if="
                            patient_dash_data?.patient_info?.profile_picture
                          "
                          :src="
                            patient_dash_data?.patient_info?.profile_picture
                          "
                          alt="Patient Photo"
                          class="profile-photo"
                        />

                        <i
                          v-else
                          class="fa fa-user profile-photo_font"
                          v-if="avatar == '' || avatar == null"
                        ></i>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <h2>{{ patient_name }}</h2>
                      </td>
                      <td>
                        <p v-if="patient_dash_data?.patient_info">
                          DOB: {{ patient_dash_data?.patient_info?.sex }}
                          {{ patient_dash_data?.patient_info?.dob }} ({{
                            patient_dash_data?.patient_info?.age
                          }}
                          old)
                        </p>
                      </td>
                      <td>
                        <p>
                          Patient I.D:
                          {{ patient_dash_data?.patient_info?.account_number }}
                        </p>
                      </td>
                      <td>
                        <h3 v-if="patient_dash_data?.patient_info?.balance">
                          Balance:
                          <span class="credit"
                            >₦
                            {{
                              formatNumberWithCommas(
                                patient_dash_data?.patient_info?.balance
                              )
                            }}</span
                          >
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>BP</strong><br />{{
                            patient_dash_data.blood_pressure || 0
                          }}
                          mmHg
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Pulse</strong><br />{{
                            patient_dash_data.pulse || 0
                          }}
                          bpm
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Body Temp</strong><br /><span
                            :style="temperatureStyle"
                            >{{ patient_dash_data.temperature || 0 }} °C</span
                          >
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Blood Group</strong><br /><span>{{
                            patient_dash_data?.patient_info?.blood_group || "-"
                          }}</span>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- <img
                  src="https://litecode-hub.netlify.app/_nuxt/img/head_image.f9bdf7e.jpg"
                  alt="Patient Photo"
                  class="profile-photo"
                /> -->

                <!-- <div class="prof-infoone">
                  <div class="profile-info">
                    <h2>{{ patient_name }}</h2>

                    <p v-if="patient_dash_data?.patient_info?.sex">
                      {{ patient_dash_data?.patient_info?.sex }},
                      {{ patient_dash_data?.patient_info?.dob }} ({{
                        patient_dash_data?.patient_info?.age
                      }}
                      years old)
                    </p>
                    <p>
                      Patient I.D:
                      {{ patient_dash_data?.patient_info?.account_number }}
                    </p>
                  
                    <h3 v-if="patient_dash_data?.patient_info?.balance">
                      Balance:
                      <span class="credit"
                        >₦
                        {{
                          formatNumberWithCommas(
                            patient_dash_data?.patient_info?.balance
                          )
                        }}</span
                      >
                    </h3>
                    
                  </div>

                  <div class="vitals">
                    <p>
                      <strong>BP</strong><br />{{
                        patient_dash_data.blood_pressure || 0
                      }}
                      mmHg
                    </p>

                    <p>
                      <strong>Pulse</strong><br />{{
                        patient_dash_data.heart_rate || 0
                      }}
                      bpm
                    </p>

                    <p>
                      <strong>Body Temp</strong><br /><span
                        :style="temperatureStyle"
                        >{{ patient_dash_data.temperature || 0 }} °C</span
                      >
                    </p>
                  </div>
                </div> -->
              </div>

              <div class="tabs">
                <button class="tab-button active" @click="showTab('clinical')">
                  Clinical Info
                </button>
                <button class="tab-button" @click="showTab('personal')">
                  Personal Info
                </button>
                <button
                  class="tab-button"
                  @click="showTab('growth')"
                  id="alertbutt"
                >
                  Alert
                </button>
              </div>

              <div id="clinical" class="tab-content active">
                <!-- <div class="vitals">
                  <p>
                    <strong>BP</strong><br />{{
                      patient_dash_data.blood_pressure || 0
                    }}
                    mmHg
                  </p>
                  <p>
                    <strong>Pulse</strong><br />{{
                      patient_dash_data.heart_rate || 0
                    }}
                    bpm
                  </p>
                  <p>
                    <strong>Body Temp</strong><br /><span
                      :style="temperatureStyle"
                      >{{ patient_dash_data.temperature || 0 }} °C</span
                    >
                  </p>
                </div> -->

                <div class="section">
                  <p class="section-title">Allergies</p>
                  <div class="profile_action_btns">
                    <span
                      class="add-link"
                      @click="add_clinical_record('Allergies', 'add')"
                      >+ Add</span
                    >
                  </div>
                  <div class="section_body">
                    <span
                      v-if="
                        !patient_dash_data?.patient_info?.medical_conditions
                      "
                      >No info yet</span
                    >

                    <ul
                      v-for="conditon in patient_dash_data?.patient_info
                        ?.medical_conditions"
                      :key="conditon"
                      v-else
                    >
                      <li v-if="conditon.allergy == true">
                        {{ conditon.name }}
                        <i
                          class="fa fa-edit"
                          @click="edit_clinical_record('Allergies', conditon)"
                        ></i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="section">
                  <p class="section-title">Current Medical Conditions</p>
                  <div class="profile_action_btns">
                    <span
                      class="add-link"
                      @click="
                        add_clinical_record('current_medical_conditions', 'add')
                      "
                      >+ Add</span
                    >
                  </div>
                  <div class="section_body">
                    <span
                      v-if="
                        !patient_dash_data?.patient_info?.medical_conditions
                      "
                      >No info yet</span
                    >

                    <ul
                      v-for="conditon in patient_dash_data?.patient_info
                        ?.medical_conditions"
                      :key="conditon"
                      v-else
                    >
                      <li v-if="conditon.current == true">
                        {{ conditon.name }}
                        <i
                          class="fa fa-edit"
                          @click="
                            edit_clinical_record(
                              'current_medical_conditions',
                              conditon
                            )
                          "
                        ></i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="section">
                  <p class="section-title">Past Medical Conditions</p>
                  <div class="profile_action_btns">
                    <span
                      class="add-link"
                      @click="
                        add_clinical_record('past_medical_conditions', 'add')
                      "
                      >+ Add</span
                    >
                  </div>
                  <div class="section_body">
                    <span
                      v-if="
                        !patient_dash_data?.patient_info?.medical_conditions
                      "
                      >No info yet</span
                    >

                    <ul
                      v-for="conditon in patient_dash_data?.patient_info
                        ?.medical_conditions"
                      :key="conditon"
                      v-else
                    >
                      <li v-if="conditon.current == false">
                        {{ conditon.name }}
                        <i
                          class="fa fa-edit"
                          @click="
                            edit_clinical_record(
                              'past_medical_conditions',
                              conditon
                            )
                          "
                        ></i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="section">
                  <p class="section-title">Active Medications</p>

                  <div class="section_body">
                    <span v-if="!patient_dash_data?.administered_drugs"
                      >No info yet</span
                    >

                    <div class="sm_info_table" v-else>
                      <div class="sm_info_table-header">
                        <div class="sm_info_table-row">
                          <div class="sm_info_table-cell">Drug Name</div>
                          <div class="sm_info_table-cell">Dispensed</div>
                          <div class="sm_info_table-cell">Frequency</div>
                          <div class="sm_info_table-cell">Interval</div>
                          <div class="sm_info_table-cell">Quantity</div>
                          <!-- <div class="sm_info_table-cell">Administered By</div> -->
                          <!-- <div class="sm_info_table-cell">Created At</div> -->
                          <div class="sm_info_table-cell">
                            Last Administered
                          </div>
                        </div>
                      </div>
                      <div class="sm_info_table-body">
                        <div
                          class="sm_info_table-row"
                          v-for="drug in patient_dash_data?.patient_info
                            ?.administered_drugs"
                          :key="drug.id"
                        >
                          <div class="sm_info_table-cell">
                            {{ drug.drug_name }}
                          </div>
                          <div class="sm_info_table-cell">
                            {{ drug.dispensed ? "Yes" : "No" }}
                          </div>
                          <div class="sm_info_table-cell">
                            {{ drug.frequency || "N/A" }}
                          </div>
                          <div class="sm_info_table-cell">
                            {{ drug.interval || "N/A" }}
                          </div>
                          <div class="sm_info_table-cell">
                            {{ drug.quantity }}
                          </div>
                          <!-- <div class="sm_info_table-cell">{{ drug.administration_type }}</div> -->
                          <div class="sm_info_table-cell">
                            {{ drug.last_administer || "N/A" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="section">
                  <p class="section-title">Past Medications</p>

                  <div class="section_body">
                    <span>No info yet</span>

                    <!-- <ul>
                      <li>5 sacherts of Penicilin</li>
                      <li>one card of paracetamol</li>
                      <li>3 sachets of ibruprophane</li>
                    </ul> -->
                  </div>
                </div>

                <router-link to="/Patient-Timeline" class="link"
                  >Open Comprehensive Medical History</router-link
                >
                <!-- fixed link -->
                <span class="add-link_fixed open_medical_info">+</span>
              </div>

              <div id="personal" class="tab-content">
                <div class="story-section section_body">
                  <h3>About patient</h3>
                  <span>No info yet</span>
                </div>
              </div>

              <div id="growth" class="tab-content">
                <p>Nothing yet.</p>
              </div>
            </div>
          </div>
        </div>

        <!--patient anatomy img--->
        <div class="col-12" id="gearon" style="margin-top: 3%">
          <div class="row" id="onguard">
            <div class="col-12 col-md-6 col-xl-6">
              <!-- add table here -->
              <BiodataTable />
            </div>
            <div class="col-xl-6 col-lg-6 col-12">
              <!-- timeline goes here -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card timeline_card">
                    <!-- added -->
                    <div class="card-body">
                      <div class="doctor-search-blk">
                        <div
                          class="top-nav-search table-search-blk"
                          style="width: 100%"
                        >
                          <form style="width: 100%" hidden>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="Patient-Note"
                            class="btn btn-primary add-pluss ms-2"
                          >
                            <img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                        </div>
                      </div>
                      <!-- added end-->

                      <div class="activity timelineSection">
                        <div class="activity-box">
                          <ul class="activity-list">
                            <li
                              v-for="(data, index) in patientResults"
                              :key="index"
                            >
                              <div
                                v-if="
                                  data?.note ||
                                  data?.prescriptions ||
                                  data?.examination ||
                                  data?.diagnosis ||
                                  data?.lab
                                "
                              >
                                <div class="activity-user">
                                  <a
                                    href="#"
                                    title=""
                                    data-bs-toggle="tooltip"
                                    class="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="../../assets/assets/img/user-06.jpg"
                                      class="img-fluid rounded-circle"
                                    />
                                  </a>
                                </div>
                                <div
                                  class="activity-content timeline-group-blk"
                                >
                                  <div class="timeline-group flex-shrink-0">
                                    <h4>{{ formatDate(data.created_at) }}</h4>
                                    <!-- <span class="time">{{
                                      formatDate(created_at)
                                    }}</span> -->
                                  </div>
                                  <div class="comman-activitys flex-grow-1">
                                    <h3>
                                      {{ data?.doctor_name }}
                                      <!-- && data?.note=='' -->
                                      <!-- <span v-if="data?.patient_result_image">  Uploaded some photo(s)</span> -->
                                      <span
                                        v-if="
                                          data?.note ||
                                          data?.prescriptions ||
                                          data?.examination ||
                                          data?.diagnosis ||
                                          data?.lab
                                        "
                                      >
                                        Added {{ getTitle(data) }}</span
                                      >
                                    </h3>
                                    <div v-if="data?.note.note_text != null">
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Note</span
                                      >
                                      {{ data?.note.note_text }}

                                      <div
                                        class="imgcont"
                                        v-if="
                                          data?.note.note_images.length !== 0
                                        "
                                      >
                                        <div
                                          class="imgcontsm"
                                          v-for="(img, index) in data?.note
                                            .note_images"
                                          :key="index"
                                        >
                                          <FileViewer :url="img.avatar" />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-if="data?.prescriptions.length !== 0"
                                    >
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Prescription</span
                                      >
                                    </div>

                                    <div class="prescription">
                                      <ul
                                        v-for="p in data?.prescriptions"
                                        v-bind:key="p.index"
                                        style="margin-bottom: 20px"
                                      >
                                        <li><span>Note:</span> {{ p.note }}</li>
                                        <li>
                                          <span>Drug Name :</span> {{ p.drug }}
                                        </li>
                                        <li v-if="p.interval == null">
                                          <span>Duration: </span
                                          >{{ p.duration }} days |
                                          <span>Quantity: </span
                                          >{{ p.quantity }} |
                                          <span>Frequency: </span
                                          >{{ p.frequency }} times daily|
                                          <span>When to take: </span
                                          >{{ p.intake_time }}
                                        </li>
                                        <li v-else-if="p.frequncy == null">
                                          <span>Duration: </span
                                          >{{ p.duration }} days |
                                          <span>Quantity: </span
                                          >{{ p.quantity }} |
                                          <span>Interval: </span>every
                                          {{ p.interval }} hours|
                                          <span>When to take: </span
                                          >{{ p.intake_time }}
                                        </li>

                                        <template
                                          v-for="(p, index) in p?.status"
                                          :key="index"
                                        >
                                          <div v-if="p?.status">
                                            <span
                                              style="
                                                color: #14a79e;
                                                display: block;
                                                font-size: 14px;
                                              "
                                              >Status</span
                                            >
                                            <span
                                              >This medication was {{ p.type }}d
                                              on
                                              <b>{{ formatDate(p.created) }}</b>
                                              by {{ p.admin }}</span
                                            >
                                          </div>
                                        </template>
                                      </ul>
                                      <br />
                                    </div>

                                    <div
                                      v-if="data?.summary.summary_text != null"
                                    >
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Summary</span
                                      >
                                      <pre
                                        v-html="data?.summary.summary_text"
                                      ></pre>
                                    </div>

                                    <div
                                      v-if="
                                        data?.operation.operation_text != null
                                      "
                                      style="background-color: #fed5e0"
                                    >
                                      <span style="display: block"
                                        >Operation Note</span
                                      >
                                      <pre
                                        v-html="data?.operation.operation_text"
                                      ></pre>
                                    </div>

                                    <div
                                      v-if="
                                        data?.diagnosis.diagnosis_text != null
                                      "
                                    >
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Diagnosis</span
                                      >
                                      {{ data?.diagnosis.diagnosis_text }}

                                      <div
                                        class="imgcont"
                                        v-if="
                                          data?.diagnosis.diagnosis_images
                                            .length !== 0
                                        "
                                      >
                                        <div
                                          class="imgcontsm"
                                          v-for="(img, index) in data?.diagnosis
                                            .diagnosis_images"
                                          :key="index"
                                        >
                                          <FileViewer :url="img.avatar" />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        data?.examination.examination_text !=
                                        null
                                      "
                                    >
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Examination</span
                                      >
                                      {{ data?.examination.examination_text }}

                                      <div
                                        class="imgcont"
                                        v-if="
                                          data?.examination.examination_images
                                            .length !== 0
                                        "
                                      >
                                        <div
                                          class="imgcontsm"
                                          v-for="(img, index) in data
                                            ?.examination.examination_images"
                                          :key="index"
                                        >
                                          <FileViewer :url="img.avatar" />
                                        </div>
                                      </div>
                                    </div>

                                    <!-- <div class="prescription">
                                  <ul style="margin-bottom: 20px;">

                                    <li>
                                      <span>Drug Name :</span> {{ data.drug }}
                                    </li>
                                    <li>
                                      <span>Dosage :</span> {{ data.dosage }}
                                    </li>

                                  </ul>
                                  <br />
                                </div> -->
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <!-- ADMINISTERED Prescription -->
                          <ul class="activity-list">
                            <li
                              v-for="(
                                data, index
                              ) in administeredPrescriptions.slice(0, 2)"
                              :key="index"
                            >
                              <div
                                v-if="
                                  data?.administered_by ||
                                  data?.drug ||
                                  data?.dosage
                                "
                              >
                                <div class="activity-user">
                                  <a
                                    href="#"
                                    title=""
                                    data-bs-toggle="tooltip"
                                    class="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="../../assets/assets/img/user-06.jpg"
                                      class="img-fluid rounded-circle"
                                    />
                                  </a>
                                </div>
                                <div
                                  class="activity-content timeline-group-blk"
                                >
                                  <div class="timeline-group flex-shrink-0">
                                    <h4>{{ formatDate(data.created_at) }}</h4>
                                  </div>
                                  <div class="comman-activitys flex-grow-1">
                                    <h3>
                                      {{ data?.administered_by }}
                                      <span> Administered A Prescription </span>
                                    </h3>

                                    <div>
                                      <span
                                        style="color: #14a79e; display: block"
                                        >Administered Prescription</span
                                      >
                                    </div>

                                    <div class="prescription">
                                      <ul style="margin-bottom: 20px">
                                        <li>
                                          <span>Drug Name :</span>
                                          {{ data.drug }}
                                        </li>
                                        <li>
                                          <span>Dosage :</span>
                                          {{ data.dosage }}
                                        </li>

                                        <!-- <li></li> -->
                                      </ul>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <!-- <hr /> -->
                          <ul class="activity-list">
                            <li v-for="(lab, index) in lapReports" :key="index">
                              <div v-if="lab?.note">
                                <div class="activity-user">
                                  <a
                                    href="#"
                                    title=""
                                    data-bs-toggle="tooltip"
                                    class="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="../../assets/assets/img/user-06.jpg"
                                      class="img-fluid rounded-circle"
                                    />
                                  </a>
                                </div>
                                <div
                                  class="activity-content timeline-group-blk"
                                >
                                  <div class="timeline-group flex-shrink-0">
                                    <h4>{{ formatDate(lab.created_at) }}</h4>
                                    <!-- <span class="time">{{
                                      formatDate(created_at)
                                    }}</span> -->
                                  </div>
                                  <div class="comman-activitys flex-grow-1">
                                    <h3>
                                      {{ lab?.doctor_id }}

                                      <span v-if="lab?.note">
                                        Added a Laboratory note</span
                                      >
                                    </h3>

                                    <pre
                                      v-html="lab?.note"
                                      v-if="lab?.note"
                                    ></pre>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul class="activity-list">
                            <li
                              v-for="(radiology, index) in radiologyReport"
                              :key="index"
                            >
                              <div v-if="radiology?.note">
                                <div class="activity-user">
                                  <a
                                    href="#"
                                    title=""
                                    rrad-bs-toggle="tooltip"
                                    class="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="../../assets/assets/img/user-06.jpg"
                                      class="img-fluid rounded-circle"
                                    />
                                  </a>
                                </div>
                                <div
                                  class="activity-content timeline-group-blk"
                                >
                                  <div class="timeline-group flex-shrink-0">
                                    <h4>
                                      {{ formatDate(radiology.created_at) }}
                                    </h4>
                                    <!-- <span class="time">{{
                                      formatDate(created_at)
                                    }}</span> -->
                                  </div>
                                  <div class="comman-activitys flex-grow-1">
                                    <h3>
                                      {{ radiology?.doctor_id }}

                                      <span v-if="radiology?.note">
                                        Added a Radiology note.</span
                                      >
                                    </h3>

                                    <pre
                                      v-html="radiology?.note"
                                      v-if="radiology?.note"
                                    ></pre>
                                    <!-- <div
                                      v-if="rrad?.note"
                                      style="font-size: 15px"
                                    >
                                      {{ rrad?.note }}
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <!-- v-if="data?.note || data?.prescription || data?.examination || data?.diagnosis" -->
                        </div>

                        <!-- <div
                      style="text-align: center; margin-top: 12%; padding-bottom: 20px"
                      v-if="result == '' || result == null"
                    >
                      <i
                        class="fas fa-box-open"
                        style="font-size: 120px; color: #14a79e; opacity: 0.4"
                      ></i>
                      <div class="font-size:24px">No data yet</div>
                    </div> -->
                      </div>
                      <br />
                      <div
                        class="view_more"
                        v-if="main_data != '' && main_data != null"
                      >
                        <router-link to="/Patient-Timeline"
                          >View More</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" hidden>
        <div class="col-12 col-md-4 col-xl-4 d-flex">
          <div class="card report-blk">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>
                  <img
                    src="../../assets/assets/img/icons/report-icon-01.svg"
                    class="me-2"
                    alt=""
                  />
                  Heart Rate
                </h4>
                <div class="input-block mb-0 ranger ib">
                  <a-space direction="vertical">
                    <a-range-picker
                      v-model:value="heartRateDate"
                      :placement="placement"
                      @change="fetchHeartRate()"
                    >
                    </a-range-picker>
                  </a-space>

                  <!--  -->
                </div>
              </div>

              <!-- <div id="heart-rate"></div> -->
              <apexchart
                height="250"
                type="bar"
                :options="chart3.options"
                :series="chart3.series"
              ></apexchart>

              <div class="dash-content">
                <h5>{{ patient_dash_data.heart_rate ?? 0 }}<span>bpm</span></h5>
                <!-- <p>
                  <span class="passive-view"
                    ><i class="feather-arrow-up-right me-1"></i>40%</span
                  >
                  vs last month
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-3 col-xl-3 d-flex"> -->
        <div class="col-12 col-md-4 col-xl-4 d-flex">
          <div class="card report-blk">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>
                  <img
                    src="../../assets/assets/img/icons/report-icon-02.svg"
                    class="me-2"
                    alt=""
                  />
                  Temperature
                </h4>

                <div class="input-block mb-0 ranger ib">
                  <a-space direction="vertical" :size="12">
                    <a-range-picker
                      v-model:value="temperatureDate"
                      :placement="placement"
                      @change="fetchTempchart()"
                    >
                    </a-range-picker>
                  </a-space>
                </div>
              </div>

              <apexchart
                height="250"
                type="bar"
                :options="chart2.options"
                :series="chart2.series"
              ></apexchart>
              <div class="dash-content">
                <h5>{{ patient_dash_data.temperature ?? 0 }} <span>c</span></h5>
                <!-- <p>
                  <span class="negative-view"
                    ><i class="feather-arrow-down-right me-1"></i>-20%</span
                  >
                  vs last month
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-xl-4 d-flex">
          <div class="card report-blk">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>
                  <img
                    src="../../assets/assets/img/icons/report-icon-03.svg"
                    class="me-2"
                    alt=""
                  />
                  Blood Pressure
                </h4>

                <div class="input-block mb-0 ranger ib">
                  <a-space direction="vertical" :size="12">
                    <a-range-picker
                      v-model:value="bloodPressureDate"
                      :placement="placement"
                      @change="fetchBlood_pressurechart()"
                    >
                    </a-range-picker>
                  </a-space>
                </div>
              </div>

              <apexchart
                height="250"
                type="bar"
                :options="chart4.options"
                :series="chart4.series"
              ></apexchart>

              <div class="dash-content">
                <h5>
                  {{ patient_dash_data.blood_pressure ?? 0 }} <span>mmHg</span>
                </h5>
                <!-- <p>
                  <span class="negative-view"
                    ><i class="feather-arrow-down-right me-1"></i>-40%</span
                  >
                  vs last month
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 col-xl-3 d-flex" style="display: none">
          <div class="card report-blk" style="display: none">
            <div class="card-body">
              <div class="chart-title patient-visit mb-0">
                <h4>
                  <img
                    src="../../assets/assets/img/icons/report-icon-04.svg"
                    class="me-2"
                    alt=""
                  />
                  sleep
                </h4>

                <div class="input-block mb-0 ranger ib">
                  <a-space direction="vertical" :size="12">
                    <a-range-picker
                      v-model:value="value2"
                      :placement="placement"
                    />
                  </a-space>
                </div>
              </div>
              <div id="sleep-chart"></div>
              <div class="dash-content">
                <h5>7<span>h</span> 30 <span>m</span></h5>
                <!-- <p>
                  <span class="negative-view"
                    ><i class="feather-arrow-down-right me-1"></i>-10%</span
                  >
                  vs last month
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-xl-4">
          <div class="row">
            <div
              class="col-12 col-md-12 col-xl-12"
              v-if="whoisLoggedin == 'Doctor' || 'Admin'"
            >
              <div class="card top-departments">
                <div class="card-header pb-0">
                  <h4 class="card-title mb-0">Manage Patient</h4>
                </div>
                <div class="card-body pt-1">
                  <div class="note-checkit">
                    <div class="note-checkit">
                      <label class="custom_check">
                        <input type="checkbox" value="" id="admit" />
                        <span class="checkmark"></span>
                        <span class="admimsg">{{ admit_message }}</span>
                      </label>
                      <div style="display: inline-block; float: inline-end">
                        <div
                          id="toggleContainer-admitted"
                          class="toggle-container on discharge_check"
                          v-if="stat == 'Admitted'"
                          @click="clic_fun()"
                        >
                          <div class="toggle-circle discharge_check"></div>
                        </div>
                        <div
                          id="toggleContainer-discharged"
                          class="toggle-container off admit_check"
                          v-else
                        >
                          <div class="toggle-circle admit_check"></div>
                        </div>
                      </div>
                    </div>
                    <label class="custom_check specialist_add">
                      <input type="checkbox" v-model="isChecked1" />
                      <span class="checkmark"></span> Add a specialist -
                      <span class="request_count">
                        ({{ patient_dash_data.collaboration_count ?? 0 }})</span
                      >
                      <!-- Number of request(s) -->
                    </label>
                  </div>

                  <div class="note-checkit">
                    <label class="custom_check make_a_request">
                      <input type="checkbox" v-model="isChecked2" />
                      <span class="checkmark"></span> Request -
                      <span class="request_count">
                        ({{ patient_dash_data.lab_rad_count || 0 }})</span
                      >
                    </label>
                  </div>
                  <!-- <div class="note-checkit">
                    <label class="custom_check mb-0">
                      <input type="checkbox" class="discharge_check" id="discharge" />
                      <span class="checkmark"></span>
                      <span class="dischargemsg">Discharge patient</span>
                    </label>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-md-6 col-xl-7 d-flex" hidden>
              <div class="card wallet-widget general-health" hidden>
                <div class="circle-bar circle-bar2">
                  <div class="circle-graph20" data-percent="66">
                    <b><img src="../../assets/assets/img/icons/health-img.svg" alt="" /></b>
                  </div>
                </div>
                <div class="main-limit">
                  <p>General Health</p>
                 
                  <h4>No data yet</h4>
                  <div class="income-value mt-2">
                    <p>
                      <span class="passive-view-"><i class="feather-arrow-up-right me-1"></i>-</span>
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-8">
          <div class="col-12 col-md-12 col-xl-12">
            <div class="card">
              <div class="card-header" id="jekins">
                <h4 class="card-title d-inline-block">Requests</h4>
                <router-link
                  to="Requests-History"
                  class="patient-views float-end"
                  >Show all</router-link
                >
              </div>
              <div class="card-body p-0 table-dash">
                <div class="table-responsive" style="overflow: visible">
                  <table class="table mb-0 border-0 patient-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Request Type</th>
                        <th>Date</th>
                        <th></th>
                        <!-- <th></th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="data in request_data" :key="data.id">
                        <td>
                          <!-- <img
                              width="28"
                              height="28"
                              class="rounded-circle"
                              src="../../assets/assets/img/profiles/avatar-05.jpg"
                              alt=""
                            /> -->

                          <span v-if="data.staff_name == ''">No Name</span>
                          <span v-else>{{ data.staff_name }}</span>
                        </td>
                        <td>{{ data.department }}</td>
                        <td style="color: black">{{ data.request_type }}</td>
                        <td>{{ formatDate(data.created_at) }}</td>
                        <td></td>
                        <td class="text-end" hidden>
                          <div class="dropdown dropdown-action">
                            <a
                              href="#"
                              class="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              ><i class="fa fa-ellipsis-v"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a class="dropdown-item" href="#"
                                ><i class="fa fa-pen-to-square m-r-5"></i>
                                Edit</a
                              >
                              <a
                                class="dropdown-item"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_appointment"
                                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                      <!--added end -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sample modal content ;overflow-y:scroll-->

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:80px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h4 class="modal-title">Send patient to </h4> -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12 col-sm-12">
              <img id="previewer" src="" alt="" width="100%" height="400px" />
            </div>
          </div>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- second modal for confirmation -->

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster admit_modal"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Confirm admission of patient</h3>
            <p>Are you sure want to admit this patient?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn"
                  @click="admit_patient()"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn cancel bg-danger text-light"
                  @click="cancel_check()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster discharge_modal"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="discharge_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Confirm discharge of patient</h3>
            <p>Are you sure want to discharge this patient?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn"
                  @click="discharge_patient()"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn bg-danger text-light"
                  @click="cancel_check()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- last modal for comfirmation  -->

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster request_option"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Make a request</h3>
            <p>Where do you want to make a request?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn goto_lab"
                >
                  Laboratory
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn goto_radiology"
                >
                  Radiology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster pre_fluid_chart_modal"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Add Patient Fluid Chart</h3>
            <p>Which Fluid chart do you want to add?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn open_fluid_chart_in"
                >
                  Intake
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn open_fluid_chart_out"
                >
                  Output
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- second modal for confirmation -->
  <!-- <div class="modal modal-lg animate__animated animate__zoomIn animate__faster where_to_request" role="dialog"
    id="where_to_request">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Choose action</h3>
            <p>Where do you want to request from?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <span @click="select_institution('internal')" class="w-100 btn btn-primary paid-continue-btn">
                  Internal
                </span>
              </div>
              <div class="col-6">
                <span @click="select_institution('external')" class="w-100 btn btn-primary paid-continue-btn">
                  External
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- /.modal -->
  <!-- what do you want to request modal -->

  <LabRequest :institutution_choice="institutution_choice" />
  <RadiologyRequest :institutution_choice="institutution_choice" />
  <RequestCollaboration />
  <AddBiodata />
  <AddObservationChart @saved="fetchData()" />
  <AddFluidChartIn />
  <AddFluidChartOut />
  <Billing @saved="fetchData()" />
  <Nurse_Note />
  <Operation_Note />
  <AddmedicalInfo :target="clinic_record_target" />
  <EditmedicalInfo
    :edit_data="{ target: clinic_record_target, obj: clinic_record_obj }"
  />
</template>
<script>
import { formatDate } from "@/utils/formatter";

import Waiter from "../plugins/Waiter.vue";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import RequestCollaboration from "./plugin/Collaboration_plugin";
import LabRequest from "./plugin/Lab_request.vue";
import RadiologyRequest from "./plugin/Radiology_request";
import AddBiodata from "./plugin/Add_Biodata.vue";
import AddObservationChart from "./plugin/Observation Chart.vue";
import AddmedicalInfo from "./plugin/add_medical_info.vue";
import EditmedicalInfo from "./plugin/edit_medical_info.vue";
import AddFluidChartIn from "./plugin/Fluid_Chart_in.vue";
import AddFluidChartOut from "./plugin/Fluid_Chart_out.vue";
import Billing from "./plugin/Billing.vue";
import BiodataTable from "./plugin/biodata_table.vue";
import Nurse_Note from "./plugin/nurse_note.vue";
import Operation_Note from "./plugin/operation_note.vue";
// import SelectChart from "./plugin/datepicker";

import { Temperature_chart } from "./chart_modules/temperature_chart";
import { Heart_rate_chart } from "./chart_modules/heart_rate_chart";
import { Blood_pressure_chart } from "./chart_modules/blood_pressure_chart";

import { mapActions } from "vuex";
import { activities } from "@/constants";
import FileViewer from "../_General/FileViewer.vue";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    RequestCollaboration,
    LabRequest,
    RadiologyRequest,
    AddBiodata,
    Waiter,
    Billing,
    AddObservationChart,
    AddFluidChartIn,
    AddFluidChartOut,
    BiodataTable,
    Nurse_Note,
    Operation_Note,
    AddmedicalInfo,
    EditmedicalInfo,
    FileViewer,
  },

  data() {
    return {
      formatDate,
      patient_name: localStorage.getItem("patient_name"),
      patientResults: [],
      lapReports: [],
      radiologyReport: [],
      result: [],
      lab: [],
      rad: [],
      // checkin:[],
      administeredPrescriptions: [],
      patientId: localStorage.getItem("this_patient_id"),
      appointmentId: localStorage.getItem("apt_id"),
      admit_message: "",
      type: localStorage.getItem("patient_type"),
      stat: "Out Patient",
      whoisLoggedin: localStorage.getItem("role"),
      main_data: "",
      main_data_reserve: "",
      patient_dash_data: "",
      request_data: [],
      isChecked1: false,
      isChecked2: false,
      average_bmi: 0,
      chart: {
        chartOptions: {
          chart: {
            id: "apexchart-example",
          },
          title: {
            // text: "Fruit distribution",
            align: "center",
            // margin: 40,
            style: {
              fontSize: "20px",
              fontWeight: "bold",
              color: "#263238",
              width: 300,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: +{
                chart: {
                  width: 300,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          // colors: ["#2E93fA", "#46DA62", "#242E8A", "#E91E63", "#FF9800"],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "bottom",
          },
          labels: ["Oxygen", "Carbon", "Hydrogen", "Nitrogen", "Lung"],
        },
        series: [],
      },

      chart2: Temperature_chart([], []),
      chart3: Heart_rate_chart([], []),
      chart4: Blood_pressure_chart([], [], []),

      temperatureDate: [],
      heartRateDate: [],
      bloodPressureDate: [],
      clinic_record_target: null,
      clinic_record_obj: null,
      institutution_choice: null,
    };
  },

  methods: {
    // select_institution(choice) {
    //   this.institutution_choice = choice
    //   $(".request_option").modal("show");
    //   $(".where_to_request").modal("hide");

    // },
    getTitle(data) {
      const availableTexts = [
        data?.note?.note_text && "Note",
        data?.prescriptions?.length > 0 && "Prescriptions",
        data?.examination?.examination_text && "Examination",
        data?.diagnosis?.diagnosis_text && "Diagnosis",
        data?.operation?.operation_text && "Operation Note",
        data?.summary?.summary_text && "Nurse Summary",
      ].filter(Boolean); // Filter out any falsey values

      if (availableTexts.length === 0) return "No available data";

      // Join available texts into a single string
      const title =
        availableTexts.length === 1
          ? availableTexts[0]
          : `${availableTexts
              .slice(0, -1)
              .join(", ")} and ${availableTexts.slice(-1)}`;

      // Determine the correct prefix based on the first letter
      const prefix = /^[aeiouAEIOU]/.test(title) ? "an" : "a";

      return `${prefix} ${title}`;
    },
    updateSelectedRange(value) {
      alert(value);
    },
    fetchHeartRate() {
      this.heart_rate_chart(this.heartRateDate);
    },
    fetchTempchart() {
      this.temp_chart(this.temperatureDate);
    },
    fetchBlood_pressurechart() {
      this.blood_pressure(this.bloodPressureDate);
    },
    ...mapActions({
      _admit_patient: "patient/admit_patient",
      _patientBoidata: "patient/patientBoidata",
      _getPatientDashboardData: "patient/getPatientDashboardData",
      _temp_chart: "patient_chart/temp_chart",
      _blood_pressure: "patient_chart/blood_pressure",
      _heart_rate_chart: "patient_chart/heart_rate_chart",
    }),
    // chart datas
    temp_chart(temperatureDate) {
      $(".waiter").show();

      this._temp_chart({
        id: this.patientId,
        params: {
          startDate: this.date_seperate(temperatureDate?.[0]),
          endDate: this.date_seperate(temperatureDate?.[1]),
        },
      })
        .then((response) => {
          $(".waiter").hide();

          this.response = response.data;
          const temperature_data = this.response.data;
          const label = [];
          const interval = [];
          temperature_data.forEach((obj) => {
            label.push(obj.time);
            interval.push(obj.temperature);
          });
          // interval= interval.map((value) => (value === null ? 0 : value))

          this.chart2 = Temperature_chart(interval, label);
          // console.log(temperature_data,'kl')
        })
        .catch((error) => {
          $(".waiter").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            }
          }
        });
    },
    blood_pressure(bloodPressureDate) {
      $(".waiter").show();

      this._blood_pressure({
        id: this.patientId,
        params: {
          startDate: this.date_seperate(bloodPressureDate?.[0]),
          endDate: this.date_seperate(bloodPressureDate?.[1]),
        },
      })
        .then((response) => {
          $(".waiter").hide();

          this.response = response.data;
          const bloodPressure_data = this.response.data;
          const label = [];
          const blood_pressure_higher = [];
          const blood_pressure_lower = [];
          bloodPressure_data.forEach((obj) => {
            label.push(obj.time);
            blood_pressure_higher.push(obj.blood_pressure_higher);
            blood_pressure_lower.push(obj.blood_pressure_lower);
          });

          this.chart4 = Blood_pressure_chart(
            blood_pressure_higher,
            blood_pressure_lower,
            label
          );
          // this.chart4 = Blood_pressure_chart(
          //       this.blood_pressure_higher,
          //       this.blood_pressure_lower,
          //       this.label
          //     );

          console.log(bloodPressure_data, "kl");
        })
        .catch((error) => {
          $(".waiter").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            }
          }
        });
    },

    heart_rate_chart(heartRateDate) {
      $(".waiter").show();

      this._heart_rate_chart({
        id: this.patientId,
        params: {
          startDate: this.date_seperate(heartRateDate?.[0]),
          endDate: this.date_seperate(heartRateDate?.[1]),
        },
      })
        .then((response) => {
          $(".waiter").hide();

          this.response = response.data;
          const heart_rate_data = this.response.data;
          const label = [];
          const interval = [];
          heart_rate_data.forEach((obj) => {
            label.push(obj.time);
            interval.push(obj.heart_rate);
          });
          // interval= interval.map((value) => (value === null ? 0 : value))

          this.chart3 = Heart_rate_chart(interval, label);

          // console.log(temperature_data,'kl')
        })
        .catch((error) => {
          $(".waiter").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            }
          }
        });
    },
    // chart datas

    imageviewer(E) {
      var previewer = document.getElementById("previewer");

      var img = E.target;
      img = img.src;
      previewer.src = img;
      $("#con-close-modal").modal("show");
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formattedTime(created_at) {
      const dateObject = new Date(created_at);
      // Check if the date is valid
      if (isNaN(dateObject.getTime())) {
        return "";
      }
      const options = { hour: "numeric", minute: "numeric", second: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(dateObject);
    },

    admit_patient() {
      $(".inloader").show();

      this._admit_patient({
        appointmentId: this.appointmentId,
        payload: { type: "admit" },
      })
        .then((response) => {
          $(".inloader").hide();
          localStorage.setItem("type", "in_patient");
          this.stat = "Admitted";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success("Patient admitted");
            admit.disabled = true;
            admit.checked = true;
            this.admit_message = "Patient Admitted";
            localStorage.setItem("patient_type", "in_patient");
            this.toggle("toggleContainer-admitted");
            // discharge.checked = false;
            // discharge.disabled = false;
            // $(".dischargemsg").text("Discharge patient");
          }
        })
        .catch((error) => {
          // remove if not working
          // admit.checked = false;
          // admit.disabled = false;
          // discharge.checked = true;
          // discharge.disabled = true;

          $(".inloader").hide();
          console.log(error);

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    discharge_patient() {
      $(".inloader").show();

      this._admit_patient({
        appointmentId: this.appointmentId,
        payload: { type: "discharge" },
      })
        .then((response) => {
          $(".inloader").hide();
          localStorage.setItem("type", "out_patient");
          this.stat = "Discharged";

          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success("Patient Discharged");
            admit.checked = false;
            admit.disabled = false;
            this.admit_message = "Patient Discharged";
            localStorage.setItem("patient_type", "out_patient");

            this.toggle("toggleContainer-discharged");

            // window.location = "Doctor-Accepted-Appointments";
          }
        })
        .catch((error) => {
          // removeif not working
          // admit.disabled = true;
          // admit.checked = true;
          // discharge.checked = false;
          // discharge.disabled = false;

          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(id);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
      } else {
        container.classList.remove("on");
        container.classList.add("off");
      }
    },
    check() {
      const admit = document.getElementById("admit");
      // const discharge = document.getElementById("discharge");
      // alert(this.type)
      if (this.type == "unattended") {
        admit.checked = false;
        // discharge.checked = true;
        this.admit_message = "Out Patient";
        // $(".dischargemsg").text("Out Patient");
        this.stat = "Unattended";
      } else if (this.type == "out_patient") {
        admit.checked = false;
        // discharge.checked = true;
        this.admit_message = "Out Patient";
        // $(".dischargemsg").text("Patient discharged");
        this.stat = "Out Patient";
      } else if (this.type == "in_patient") {
        admit.checked = true;
        // discharge.checked = false;
        // $(".dischargemsg").text("Discharge patient");
        this.stat = "Admitted";
        this.admit_message = "Patient admitted";
      }
    },
    cancel_check() {
      this.check();
    },

    //copied from  to power doctor collaboration
    updateDoc(index, value) {
      this.tempDOC[index] = value;
      this.doc_id = this.tempDOC[index].id;
      this.doc_doc_name =
        this.tempDOC[index].first_name + " " + this.tempDOC[index].last_name;
    },
    cat() {
      this._cat()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    getdoctors(data) {
      $(".confirm_doctor_assign").modal("show");
      $(".invite_modal").modal("hide");

      this.category_id = data.id;
      this.category_cat = data.category;
      this._getdoctors(data.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.doctors = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching docors", error);
        });
    },
    patientBiodata() {
      $(".waiter").show();

      this._patientBoidata(this.patientId)
        .then((response) => {
          $(".waiter").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            // var timeline = this.main_data
            this.result = this.main_data.patient_result;
            this.rad = this.main_data.radiology;
            this.lab = this.main_data.lab;
            this.administeredPrescriptions =
              this.main_data.administered_prescriptions;
            localStorage.setItem("result", JSON.stringify(this.result));
            localStorage.setItem("lab", JSON.stringify(this.lab));
            localStorage.setItem("rad", JSON.stringify(this.rad));
            localStorage.setItem(
              "checkins",
              JSON.stringify(this.main_data.checkins)
            );
            localStorage.setItem(
              "print_history",
              JSON.stringify(this.main_data.print_history)
            );
            localStorage.setItem(
              "view_report_history",

              JSON.stringify(this.main_data.view_report_history)
            );
            localStorage.setItem(
              "patientMiscellaneousData",
              JSON.stringify(this.main_data.miscellaneous)
            );

            localStorage.setItem(
              "administeredPrescriptions",
              JSON.stringify(this.administeredPrescriptions)
            );

            // this.administeredPrescriptions = this.main_data.administered_prescriptions;
            this.patientResults = this.main_data.patient_result.slice(0, 2);
            this.radiologyReport = this.main_data.radiology.slice(0, 2);
            this.lapReports = this.main_data.lab.slice(0, 2);

            // this.main_data_reserve = this.main_data.patient_result;
            // this.main_data_reserve = this.main_data_reserve.slice(0, 2);

            // external radiology, laboratory and refferal note
            const labArray = [];
            const radiologyArray = [];
            const referralArray = [];

            this.result.map((entry) => {
              const {
                lab_result,
                radiology_result,
                referral,
                doctor_name,
                created_at,
                ...rest
              } = entry;

              // Add to labArray only if lab_text is not null
              if (lab_result && lab_result.lab_text !== null) {
                labArray.push({
                  ...lab_result,
                  doctor_name,
                  created_at,
                });
              }

              // Add to radiologyArray only if radiology_text is not null
              if (
                radiology_result &&
                radiology_result.radiology_text !== null
              ) {
                radiologyArray.push({
                  ...radiology_result,
                  doctor_name,
                  created_at,
                });
              }

              // Add to referralArray only if referral_text is not null
              if (referral && referral.referral_text !== null) {
                referralArray.push({
                  ...referral,
                  doctor_name,
                  created_at,
                });
              }

              return rest;
            });

            localStorage.setItem(
              "external_rad",
              JSON.stringify(radiologyArray)
            );
            localStorage.setItem("external_lab", JSON.stringify(labArray));
            localStorage.setItem("referral", JSON.stringify(referralArray));
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getPatientDashboardData() {
      // $(".waiter").show();

      this._getPatientDashboardData(this.appointmentId)
        .then((response) => {
          $(".waiter").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.patient_dash_data = this.response.data;

            this.request_data_sent = this.patient_dash_data.request;
            localStorage.setItem(
              "request_data_sent",
              JSON.stringify(this.request_data_sent)
            );
            localStorage.setItem(
              "patient_info",
              JSON.stringify(this.patient_dash_data.patient_info)
            );
            // this.request_data = this.patient_dash_data.request
            this.request_data = this.patient_dash_data.request;
            this.request_data = this.request_data.slice(0, 3);

            console.log(this.patient_dash_data);

            this.chart.series = [
              this.patient_dash_data.oxygen,
              this.patient_dash_data.carbon,
              this.patient_dash_data.hydrogen,
              this.patient_dash_data.nitrogen,
              this.patient_dash_data.lung,
            ];

            var h = this.patient_dash_data.body_mass_index_height / 100;
            // var h = this.patient_dash_data.body_mass_index_height / 10;
            //  alert(h)
            var hh = h ** h;

            this.average_bmi =
              this.patient_dash_data.body_mass_index_weight / hh;

            this.average_bmi = this.average_bmi.toFixed(2);
            // this.average_bmi = 18;

            // bmi conditionals#
            if (this.average_bmi > 25 || this.average_bmi < 18) {
              $(".wb").addClass("weight-blk2").removeClass("weight-blk");
            } else if (this.average_bmi >= 18 && this.average_bmi <= 25) {
              $(".wb").addClass("weight-blk").removeClass("weight-blk2");
            }

            // this.chart2.options.xaxis.categories = Object.keys(chartData);
            // this.chart2.options.xaxis.categories = ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            // const chartData = this.patient_dash_data.temperature_chart;
            // this.chart2.series[0].data = Object.values(chartData).map((value) =>
            //   value === null ? 0 : value
            // );

            // this.chart2.series.data = values_array
            // alert(this.chart2.options.xaxis.categories)
            // const chartData2 = this.patient_dash_data.heart_rate_chart;
            // this.chart3.series[0].data = Object.values(chartData2).map(
            //   (value) => (value === null ? 0 : value)
            // );

            // const chartData3 = this.patient_dash_data.blood_pressure_chart;

            // const month_array = Object.keys(chartData3);

            // const lbp = [];
            // const hbp = [];

            // // Iterate through the months
            // for (const month in chartData3) {
            //   if (chartData3.hasOwnProperty(month)) {
            //     const monthData = chartData3[month];

            //     if (monthData.hasOwnProperty("lbp")) {
            //       lbp.push(monthData["lbp"]);
            //     }

            //     if (monthData.hasOwnProperty("hbp")) {
            //       hbp.push(monthData["hbp"]);
            //     }
            //   }
            // }
            // this.chart4.series[0].data = hbp.map((value) =>
            //   value === null ? 0 : value
            // );
            // this.chart4.series[1].data = lbp.map((value) =>
            //   value === null ? 0 : value
            // );

            // alert(month_array)
            // alert(lbp.map((value) => value === null ? 0 : value))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    date_seperate(dateTimeString) {
      if (dateTimeString) {
        const dateTimee = new Date(dateTimeString);
        const date = dateTimee.toISOString().split("T")[0];
        return date;
      }
    },
    time_seperate(dateTimeString) {
      if (dateTimeString) {
        const dateTimee = new Date(dateTimeString);
        const time = dateTimee.toISOString().split("T")[1].split(".")[0];
        return time;
      }
    },
    date_time(dateString) {
      if (dateString) {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

        return formattedDate;
      }
    },
    clic_fun() {
      $("#discharge_modal").modal("show");
    },
    showTab(tabId) {
      // Hide all tab content
      const contents = document.querySelectorAll(".tab-content");
      contents.forEach((content) => content.classList.remove("active"));

      // Remove active class from all buttons
      const buttons = document.querySelectorAll(".tab-button");
      buttons.forEach((button) => button.classList.remove("active"));

      // Show the selected tab and set the corresponding button to active
      document.getElementById(tabId).classList.add("active");
      event.currentTarget.classList.add("active");
    },
    formatNumberWithCommas(number) {
      if (number) {
        // Convert the number to a string
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    add_clinical_record(target) {
      $(".medical_info_modal").modal("show");
      this.clinic_record_target = target;
    },
    edit_clinical_record(target, data) {
      $(".edit_medical_info_modal").modal("show");
      this.clinic_record_target = target;
      this.clinic_record_obj = data;
    },
    fetchData() {
      this.getPatientDashboardData();
      this.patientBiodata();
      this.temp_chart();
      this.blood_pressure();
      this.heart_rate_chart();
    },
  },
  computed: {
    temperatureStyle() {
      if (this.patient_dash_data.temperature < 35) {
        return { color: "#00BFFF" }; // Deep Sky Blue for Hypothermia
      } else if (
        this.patient_dash_data.temperature >= 35 &&
        this.patient_dash_data.temperature <= 36.4
      ) {
        return { color: "#87CEFA" }; // Light Sky Blue for Low/Below Normal
      } else if (
        this.patient_dash_data.temperature >= 36.5 &&
        this.patient_dash_data.temperature <= 37.5
      ) {
        return { color: "#32CD32" }; // Lime Green for Normal
      } else if (
        this.patient_dash_data.temperature > 37.5 &&
        this.patient_dash_data.temperature <= 38
      ) {
        return { color: "#FFD700" }; // Gold for Mild Fever
      } else if (
        this.patient_dash_data.temperature > 38 &&
        this.patient_dash_data.temperature <= 39
      ) {
        return { color: "#FFA500" }; // Orange for Moderate Fever
      } else if (
        this.patient_dash_data.temperature > 39 &&
        this.patient_dash_data.temperature <= 40
      ) {
        return { color: "#FF4500" }; // Orange Red for High Fever
      } else if (this.patient_dash_data.temperature > 40) {
        return { color: "#FF0000" }; // Red for Hyperpyrexia (Severe)
      }
    },
  },
  mounted() {
    // record activity
    this.$store.dispatch("recordActivity", {
      name: activities.patientDashboard,
      action: activities.viewed,
      patientId: this.patientId,
    });
    jQuery(document).ready(function ($) {
      //Use this inside your document ready jQuery
      $(window).on("popstate", function () {
        location.reload(true);
      });
    });

    setInterval(() => {
      this.isChecked1 = false;
      this.isChecked2 = false;
    }, 1500);
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    // console.log(this.biodata)
    if (this.whoisLoggedin == "Admin" || "Doctor") {
      this.check();
    }
    $(".discharge_check").click(function () {
      $("#discharge_modal").modal("show");
      alert("klll");
    });
    $(".admit_check").click(function () {
      $(".admit_modal").modal("show");
    });

    $(".make_a_request").click(function () {
      $(".request_option").modal("show");
    });
    $(".goto_lab").click(function () {
      $(".lab_request").modal("show");
    });

    $(".goto_radiology").click(function () {
      $(".radiology_request").modal("show");
    });

    $(".open_vital").click(function () {
      $(".some_vitals").modal("show");
    });

    $(".cancel-vital_form").click(function () {
      $(".some_vitals").modal("hide");
    });

    $(".open_billing").click(function () {
      $(".add_billing").modal("show");
    });

    $(".cancel-billing_form").click(function () {
      $(".add_billing").modal("hide");
    });
    // alert(this.type)

    // slick
    if ($(".center").length > 0) {
      $(".center").slick({
        centerMode: true,
        arrows: false,
        centerPadding: "30px",
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 3,
            },
          },
        ],
      });
    }

    // new functions
    $(".open_fluid_chart_in").click(function () {
      $(".fluid_chart_modal_in").modal("show");
    });
    $(".open_fluid_chart_out").click(function () {
      $(".fluid_chart_modal_out").modal("show");
    });

    $(".open_pre_fluid_chart").click(function () {
      $(".pre_fluid_chart_modal").modal("show");
    });
    $(".open_observation_chart").click(function () {
      $(".observation_chart_modal").modal("show");
    });
    $(".add_nurse_note").click(function () {
      $(".nurse_note_modal").modal("show");
    });
    $(".add_operation_note").click(function () {
      $(".operation_note_modal").modal("show");
    });
    // $(".open_medical_info").click(function () {
    //   $(".request_option").modal("show");
    // });

    // setTimeout(() => {
    var institution = localStorage.getItem("institution");

    if (institution == "Admin") {
      // Get all checkboxes on the page
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');

      // Loop through each checkbox and disable it
      checkboxes.forEach(function (checkbox) {
        checkbox.disabled = false;
      });
    } else {
      // Get all checkboxes on the page
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');

      // Loop through each checkbox and disable it
      checkboxes.forEach(function (checkbox) {
        checkbox.disabled = true;
      });
    }
    // }, 1600);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prof-infoone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-top: 2%;
}

.profile-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/*  */
.view_more {
  font-size: 14px;
  text-align: right;
  color: #14a79e;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}

.topbadge1 {
  height: 50px;
  width: 23.9%;
  font-size: 16px;
  padding: 10px;
  float: right;
  margin-right: 5px;
}

.topbadge {
  height: auto;
  width: auto;
  font-size: 14px;
  padding: 2px;
  /* float: right; */
  /* margin-right: 5px; */
}

.dropdown-menu {
  width: 23.9%;
}

/* .topright {
  float: right;
} */
.prescription {
  list-style-type: none;
}

/* *::-webkit-scrollbar { 
	scrollbar-width: 20px; 
	}  */
/* .imgcont {
	overflow: auto;
	scrollbar-width: none;
	} */

.back-body {
  background-image: url(../../assets/assets/img/medical/body.png);
  background-position: top right;
  background-size: 100% auto;
  background-position-y: 10%;
  background-repeat: no-repeat;
  padding: 20px;
  height: 570px;
  box-shadow: 0px 2px 5px 0px rgba(19, 23, 38, 0.05);
}

.ib {
  display: inline-block;
}

.indicator_bar {
  height: 10px;
  border-radius: 5px;
  background-color: rgba(82, 82, 82, 0.269);
  margin-top: 20px;
}

.indicatorelement {
  /* background-color: red; */
  height: 100%;
  width: 19%;
  border-radius: 5px;
}

@media (min-width: 2400px) {
  .back-body {
    background-size: 70% auto;
    background-position-y: 10%;
    background-repeat: no-repeat;
    padding: 50px;
  }
}

/* .bodyimg{
  width:100%;
  height:450px;
  position:absolute;

} */
.bg-lightgreen {
  background-color: #a3b385;
}

.advnced_vital_link {
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.request_count {
  color: #14a79e;
}

.activity {
  height: 450px;
  overflow-y: scroll;
}

.ranger {
  height: 40px;
  padding-top: 5px;
  width: 40%;
  float: right;
  height: 40px;
}

.ib {
  display: inline-block;
}

.picker {
  border-color: red;
  text-align: center;
}

.ant-picker-cell-inner {
  text-align: center;
}

.date_details {
  color: #14a79e;
  padding-left: 10px;
  display: block;
  font-size: 14px;
}

.weight-blk .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.weight-blk .slick-slide div {
  text-align: center;
}

.weight-blk .slick-slide span {
  opacity: 0;
}

.weight-blk .slick-slide h4 {
  margin-bottom: 0;
  font-size: 14px;
}

.weight-blk .slick-current span {
  opacity: 1;
  font-size: 12px;
}

.weight-blk .slick-active {
  opacity: 0.5;
}

.weight-blk .slick-current {
  opacity: 1;
}

.max-blk {
  width: 50%;
}

.weight-blk {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 14px;
  padding: 25px 0px 20px;
  position: relative;
}

.report-head h4 {
  margin-bottom: 0;
  font-size: 18px;
}

.report-head img {
  width: 40px;
  height: 40px;
}

.weight-blk:before {
  background-image: url(../../assets/assets/img/icons/mass-icon.svg);
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  left: 50%;
  top: 0px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.weight-blk2 .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.weight-blk2 .slick-slide div {
  text-align: center;
}

.weight-blk2 .slick-slide span {
  opacity: 0;
}

.weight-blk2 .slick-slide h4 {
  margin-bottom: 0;
  font-size: 14px;
}

.weight-blk2 .slick-current span {
  opacity: 1;
  font-size: 12px;
}

.weight-blk2 .slick-active {
  opacity: 0.5;
}

.weight-blk2 .slick-current {
  opacity: 1;
}

.max-blk {
  width: 50%;
}

.weight-blk2 {
  border: 2px solid rgba(46, 55, 164, 0.1);
  border-radius: 14px;
  padding: 25px 0px 20px;
  position: relative;
}

.report-head h4 {
  margin-bottom: 0;
  font-size: 18px;
}

.report-head img {
  width: 40px;
  height: 40px;
}

.weight-blk2:before {
  background-image: url(../../assets/assets/img/icons/mass-icon2.svg);
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  left: 50%;
  top: 0px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.disable-t {
  position: absolute;
  opacity: 1;
}

.no_data {
  position: absolute;
  margin-top: 80px;
  font-size: 24px;
  margin-left: 30%;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.timeline_card {
  height: 560px;
}

/* new design for patient profile card */

.profile-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 560px;
  overflow-y: scroll;
  position: relative;
}

.profile-header {
  display: flex;
  padding: 15px;
  /* background-color: #4b4b4b; */
  background-color: #fff;

  color: #131313;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: #ddd;
  display: block;
}

.profile-photo_font {
  width: 100px;
  height: 100px;
  text-align: center;
  padding: 10px;
  font-size: 60px;
  background-color: #ddd;
  border-radius: 50%;
  padding-top: 20px;
}

.profile-info h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-info p {
  font-size: 12px;
}

.credit {
  color: #14a79e;
}

.followup-button {
  background-color: #14a79e;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  flex: 1;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #f9f9f9;
  color: #333;
  /* border-left: 1px solid #14a79e !important; */
  border-right: 1px solid #14a79e !important;
}

#alertbutt {
  border-right: none !important;
}

.tab-button.active {
  background-color: #14a79e !important;
  color: #fff !important;
  /* border-bottom: 5px solid #14a79e; */
  /* border: 1px solid #14a79e; */
}

.tab-content {
  display: none;
  padding: 15px;
}

.tab-content.active {
  display: block;
}

.vitals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
}

.vitals p {
  font-size: 16px;
  margin-bottom: 10px;
  flex: 1;
  margin-right: 19px;
}

.temperature {
  color: #d9534f;
}

.section {
  margin-bottom: 15px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: black;
  display: inline-block;
  margin-right: 10px;
}

.section_body {
  font-size: 12px;
}

.add-link {
  color: #14a79e;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #14a79e;
  border-radius: 8px;
  padding: 4px;
  margin-right: 5px;
}

.profile_action_btns {
  display: inline-block;
  float: right;
}

.add-link_fixed {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  font-size: 40px;
  cursor: pointer;
  /* border: 1px solid #14a79e; */
  border-radius: 50%;
  padding: 0px;
  z-index: 999;
  width: 60px;
  height: 60px;
  background-color: #14a79e;
  text-align: center;
  font-weight: bold;
}

.edit-icon {
  font-size: 12px;
  color: #14a79e;
  cursor: pointer;
}

.check-icon {
  color: #4caf50;
}

.link {
  color: #14a79e;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 20px;
  border: 1px solid #14a79e;
  width: 80%;
  padding: 5px;
  margin-left: 10%;
}

.timeline_card {
  height: 560px;
}

/* new design for patient profile card */

.profile-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: 560px;
  overflow-y: scroll;
  position: relative;
}

.profile-header {
  display: flex;
  padding: 15px;
  /* background-color: #4b4b4b; */
  background-color: #fff;

  color: #131313;
}

.profile-info h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-info p {
  font-size: 12px;
}

.credit {
  color: #14a79e;
}

.followup-button {
  background-color: #14a79e;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  flex: 1;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #f9f9f9;
  color: #333;
}

.tab-button.active {
  background-color: #fff;
  color: #14a79e;
  border-bottom: 2px solid #14a79e;
}

.tab-content {
  display: none;
  padding: 15px;
}

.tab-content.active {
  display: block;
}

.temperature {
  color: #d9534f;
}

.section {
  margin-bottom: 15px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: black;
  display: inline-block;
  margin-right: 10px;
}

.section_body {
  font-size: 12px;
}

.add-link {
  color: #14a79e;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #14a79e;
  border-radius: 8px;
  padding: 4px;
  float: right;
}

.add-link_fixed {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  font-size: 40px;
  cursor: pointer;
  /* border: 1px solid #14a79e; */
  border-radius: 50%;
  padding: 0px;
  z-index: 999;
  width: 60px;
  height: 60px;
  background-color: #14a79e;
  text-align: center;
  font-weight: bold;
  display: none;
}

.edit-icon {
  font-size: 17px;
  color: #14a79e;
  cursor: pointer;
}

.check-icon {
  color: #4caf50;
}

/* .link {
  color: #14a79e;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 20px;
  border: 1px solid #14a79e;
  width: 80%;
  padding: 5px;
  margin-left: 10%;
} */

.link {
  color: #14a79e;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid #14a79e;
  width: 40%;
  padding: 5px;
  margin-left: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sm_info_table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.sm_info_table-header {
  background-color: #f4f4f4;
  font-weight: bold;
}

.sm_info_table-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.sm_info_table-cell {
  flex: 1;
  padding: 10px;
  text-align: left;
}

.sm_info_table-cell:nth-child(even) {
  background-color: #f9f9f9;
}

.sm_info_table-cell:last-child {
  border-bottom: none;
}

.sm_info_table-body .sm_info_table-row:hover {
  background-color: #eaeaea;
}

#addington {
  margin-bottom: 10px;
}

/* #gbeses {
  height: 650px;
} */

@media (max-width: 1384px) {
  #addington {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .topbadge1 {
    width: 32%;
  }
}

@media (max-width: 1280px) {
  #addington {
    width: 100%;
    margin-bottom: 3%;
    margin-left: -8% !important;
  }

  #gearon {
    width: 100%;
  }

  .topbadge1 {
    width: 30%;
  }

  .back-body {
    background-position: center !important;
    /* margin-top: 20%; */
    background-size: contain;
  }

  #addingtontwo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  #allowide {
    width: 100%;
  }

  /* #onguard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  } */

  /* .col-xl-6 {
    width: 100%;
  } */

  .profile-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2%;
  }

  .ant-select {
    width: 50% !important;
  }
}

@media (max-width: 800px) {
  .profile-container {
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    max-height: 95vh;
  }

  #addington {
    width: 100%;
    margin-bottom: 3%;
    margin-left: -8% !important;
  }

  .topbadge1 {
    width: 30%;
  }

  #addingtontwo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-top: 10%;
  }

  #allowide {
    width: 100%;
  }

  #gearon {
    width: 100%;
  }

  .card {
    padding: 20px;
  }

  #gbeses {
    height: 650px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 4%;
  }

  .timeline_card {
    height: 600px;
  }
}

@media (max-width: 480px) {
  .profile-container {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 560px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    max-height: 95vh;
    position: relative;
  }

  /*  */
  .patient-info-table {
    font-size: 14px;
    /* overflow: scroll !important; 
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    max-height: 95vh; */
  }

  .profile-photo-container {
    width: 80px;
    /* Adjust width as needed */
  }

  tr {
    margin-bottom: 0;
    /* Remove default margin */
  }

  td {
    padding: 10px 0;
    /* text-align: center; */
    margin-bottom: -10px !important;
  }

  table,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  thead {
    display: none;
  }

  td,
  th {
    padding: 10px 0;
    /* text-align: center; */
  }

  td:before {
    content: attr(data-label);
    display: block;
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
  }

  /*  */

  .link {
    width: 100% !important;

    margin-left: 0 !important;
  }

  .prof-infoone {
    position: relative;
    margin-bottom: 40%;
  }

  .vitals {
    width: 100% !important;
    position: absolute;
    top: 30%;
    margin-left: -28%;
  }

  /*  */
  .back-body {
    background-position: center !important;
    /* margin-top: 20%; */
    background-size: contain;
  }

  #addington {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-left: 0% !important;
  }

  .topbadge1 {
    width: 100%;
    margin-right: 0px;
  }

  .dropdown-menu.show {
    transform: translate3d(-5.33333px, -52px, 0px) !important;
    width: 50% !important;
  }

  #addingtontwo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-top: 10%;
  }

  #allowide {
    width: 100%;
  }

  #gearon {
    width: 100%;
  }

  #discharge_modal {
    margin-left: 0px !important;
  }

  #save_modal {
    margin-left: 0px !important;
  }

  .timeline_card {
    height: 600px;
  }
}
</style>
