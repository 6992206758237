<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">GOPD </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">GOPD List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>GOPD Approved List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="" style="overflow: visible">
                <a-table :dataSource="List" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key == 'name'">
                      <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                          class="rounded-circle m-r-5" alt="" />
                        {{ record.full_name }}</a>
                    </template>
                    <template v-if="column.key == 'specialization'">
                      <div class="btn-group">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                          style="width: 180px" aria-haspopup="true" aria-expanded="false">
                          {{ tempSpec[index] || "Choose a Category" }}
                        </button>
                        <div v-if="category" class="dropdown-menu" style="
                            height: 100px;
                            overflow-y: scroll;
                            width: 180px;
                            border-radius: 8px;
                          ">
                          <span class="dropdown-item" href="#" v-for="data in category" :key="data.id"
                            @click="GOPDgetdoctors(index, data, record.id)">{{ data.category }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key == 'assign'">
                      <div class="btn-group">
                        <button type="button" class="btn dis dropdown-toggle docbtn" data-bs-toggle="dropdown"
                          id="docbtn" style="width: 180px" aria-haspopup="true" aria-expanded="false"
                          :activate_doc="`doc_btn${record.id}`" disabled>
                          {{
                            tempDOC[index]
                              ? `${tempDOC[index].first_name} ${tempDOC[index].last_name}`
                              : "Choose a Doctor "
                          }}
                        </button>
                        <div class="dropdown-menu" style="
                            height: 80px;
                            overflow-y: scroll;
                            width: 180px;
                            border-radius: 8px;
                          ">
                          <span class="dropdown-item" :value="data.id" v-for="(data, _index) in doctors" :key="_index"
                            @click="updateDoc(index, data)">
                            {{ data.first_name }} {{ data.last_name }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span>
                        {{ record.date }}
                      </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <button id="subbtn" class="waves-effect waves-light btn subbtn"
                        :activate_sub="`sub_btn${record.id}`" @click="transferPatient(record.id)" disabled>
                        Send
                      </button>
                    </template>
                    <template v-if="column.key == 'action2'">
                      <a>
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span class="dropdown-item" @click="open_biodata_data(record.patient_id)"><i
                                class="fa fa-heartbeat m-r-5"></i>Add Vitals</span>
                          </div>
                        </div>
                      </a>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- second modal for confirmation -->

    <div class="modal modal-lg animate__animated animate__zoomIn animate__faster confirm_doctor_assign"
      style="margin-top:0px;margin-left:100px;margin-right" id="save_modal" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Assign to a Doctor</h3>
              <p>
                Do you want to assign to a doctor in
                {{ category_cat }} department?
              </p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <button type="reset" data-bs-dismiss="modal" class="w-100 btn btn-primary paid-continue-btn"
                    @click="activate_doctor_list()">
                    Yes
                  </button>
                </div>
                <div class="col-6">
                  <button type="submit" data-bs-dismiss="modal" class="w-100 btn btn-primary paid-cancel-btn cancel"
                    @click="deactivate_doctor_list()">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-overlay" data-reff=""></div>

    <AddBiodata />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import AddBiodata from "../Patient/plugin/Observation Chart.vue";
// import AddObservationChart from "./plugin/Observation Chart.vue";
import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Specialization",
    key: "specialization",
  },
  {
    title: "Assign Doctor",
    key: "assign",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    key: "action",
  },
  {
    key: "action2",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    AddBiodata
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      reponse: "",
      specialty: "",
      doctors: "",
      DoctorinCat: "",
      List: "",
      tempDOC: {},
      tempSpec: {},
      reponse: "",
      main_data1: [],
      category_id: 0,
      category_cat: "",
      doc_id: null,
      locate_id: null,
      search: "",
      List_reserve: [],
      category: [],
      columns,
      shouldSelectDoctor: false
    };
  },
  computed: {
    buttonEnabled() {
      if (this.shouldSelectDoctor) {
        return !!this.doc_id;
      } else {
        return true
      }
    }
  },
  methods: {
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    updateDoc(index, value) {
      this.tempDOC[index] = value;
      this.doc_id = this.tempDOC[index].id;
      this.toggleBtnDisabled(`[activate_sub='sub_btn${this.locate_id}']`, false)
    },
    ...mapActions({
      _GOPDcat: "general/category",
      _GOPDgetdoctors: "general/getdoctors",
      _getGOPDList: "gopd/getGOPDList",
      _transferPatient: "gopd/transferPatient",
      _patientBoidata: "patient/patientBoidata",
    }),
    GOPDcat() {
      this._GOPDcat()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // console.log(this.response['message'])
            this.category = this.response.data;
            console.log(this.category);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    GOPDgetdoctors(index, data, main_data) {
      $(".confirm_doctor_assign").modal("show");

      this.tempSpec[index] = data.category;
      this.category_id = data.id;
      this.locate_id = main_data;
      this.category_cat = data.category;
      this._GOPDgetdoctors(data.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // console.log(this.response['message'])
            this.doctors = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching docors", error);
        });
    },

    getGOPDList() {
      this._getGOPDList({ type: "Completed" })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // console.log(this.response['message'])
            this.List = this.response.data;
            this.List_reserve = this.List;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching GOPD list", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },

    transferPatient(appointmentId) {
      $(".inloader").show();

      if (this.doc_id == null) {
        this._transferPatient({
          id: appointmentId,
          payload: { doctor_category_id: this.category_id },
        })
          .then((response) => {
            $(".inloader").hide();

            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              this.getGOPDList();
              tempSpec = {};
              // very very important
              this.List = this.List.filter(
                (apt) => apt.appointmentId == appointmentId
              );

              this.List;
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log(error);
            console.log("Error assigning doctor", error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
            $(".inloader").hide();
          });
      } else {
        this._transferPatient({
          id: appointmentId,
          payload: {
            doctor_id: this.doc_id,
            doctor_category_id: this.category_id,
          },
        })
          .then((response) => {
            $(".inloader").hide();

            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              this.getGOPDList();

              this.doc_id = null;
              this.tempDOC = {};
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("Error assigning doctor", error);
            $(".inloader").hide();
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
          });
      }
    },
    // patientBiodata(id, full_name, aptid) {
    //   this._patientBoidata(id, full_name).then(response => {
    //     this.response = response.data;
    //     if (this.response['status'] == true) {
    //       this.main_data1 = this.response.data
    //       var timeline = this.main_data1
    //       var bio_data = this.main_data1.patient_bio_data

    //       // var timeline = this.response

    //       var appointment = this.main_data1.appointment

    //       // var email = this.main_data1.email
    //       // var phone = this.main_data1.phone_number
    //       // var address = this.main_data1.address

    //       if (bio_data == null) {
    //         this.$toast.error("no data to view");
    //       }
    //       else {
    //         localStorage.setItem("patient_name", full_name);
    //         localStorage.setItem("apt_id", aptid);
    //         localStorage.setItem("BioData", JSON.stringify(bio_data));
    //         localStorage.setItem("appointment", JSON.stringify(appointment));

    //         // localStorage.setItem("patient_email", email);
    //         // localStorage.setItem("patient_phone", phone);
    //         // localStorage.setItem("patient_address", address);

    //         window.location = 'My-Patient-Dashboard'
    //       }
    //     }
    //   }).catch(error => {
    //     console.log(error);
    //     console.log('Error fetching data', error);
    //   });

    // },
    toggleBtnDisabled(query, disable = true) {
      const element = document.querySelector(query
      );
      if (disable) {
        element.setAttribute("disabled", "disabled");
        element.classList.remove("btn-primary");
      } else {
        element.removeAttribute("disabled");
        element.classList.add("btn-primary");
      }
    },
    activate_doctor_list() {
      this.shouldSelectDoctor = true;
      // $('.docbtn').removeAttr('disabled').addClass("btn-primary");
      // $('.subbtn').removeAttr('disabled').addClass("btn-primary");

      // alert(`[activate_doc='doc_btn${this.locate_id}']`)
      const activate_doc = document.querySelector(
        `[activate_doc='doc_btn${this.locate_id}']`
      );
      activate_doc.removeAttribute("disabled");
      activate_doc.classList.add("btn-primary");

      const activate_sub = document.querySelector(
        `[activate_sub='sub_btn${this.locate_id}']`
      );
      activate_sub.removeAttribute("disabled");
      activate_sub.classList.add("btn-primary");

      // disable send btn
      this.toggleBtnDisabled(`[activate_sub='sub_btn${this.locate_id}']`, true)
      // activate_doc.classList.remove('highlight');
      // activate_doc.disabled = true
    },
    deactivate_doctor_list() {
      this.shouldSelectDoctor = false;
      // $('.docbtn').attr('disabled', 'disabled').removeClass("btn-primary");
      // $('.subbtn').removeAttr('disabled').addClass("btn-primary");
      this.tempDOC = {};
      this.doc_id = null;
      const activate_sub = document.querySelector(
        `[activate_sub='sub_btn${this.locate_id}']`
      );
      activate_sub.removeAttribute("disabled");
      activate_sub.classList.add("btn-primary");

      const activate_doc = document.querySelector(
        `[activate_doc='doc_btn${this.locate_id}']`
      );
      activate_doc.setAttribute("disabled", "disabled");
      activate_doc.classList.remove("btn-primary");

      // const activate_sub = document.querySelector(`[activate_sub='sub_btn${this.locate_id}']`);
      // activate_sub.setAttribute('disabled', 'disabled');
      // activate_sub.classList.remove('btn-primary');
    },

    search_the_list() {
      if (this.search == "") {
        this.List = this.List_reserve;
      } else {
        this.List = this.List_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.List.length; i++) {
          var full_name = this.List[i].full_name.toLowerCase();
          var date = this.List[i].date;
          if (full_name.includes(searchLetter) || date.includes(searchLetter)) {
            searchResult.push(this.List[i]);
          }
          // if (searchResult == []) {
          //   this.List = this.List;
          // }
        }
      }
      if (this.search == "") {
        this.List = this.List_reserve;
      } else {
        this.List = searchResult;
      }
    },

    open_biodata_data(tran) {
      localStorage.setItem("bio_id", tran);
      localStorage.setItem("this_patient_id", tran);

      $(".observation_chart_modal").modal("show");


      // window.location.href = "Patient-Biodata";
    },
  },
  mounted() {
    setTimeout(() => {
      this.GOPDcat();
      this.getGOPDList();
    }, 3000);

    // var role = localStorage.getItem("role");
    // if (role == "Super Admin") {
    //   $(".not_for_Super_admin").hide();
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  height: 40px;
  overflow-y: auto;
}

option {
  overflow-y: scroll;
}

.none {
  display: none;
}

.dropdown-menu {
  scrollbar-color: black rgba(0, 211, 199, 0.85);
}

input:disabled {
  color: black;
}

@media (max-width: 800px) {
  .dropdown-menu {
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }
}


@media (max-width: 480px) {
  .dropdown-menu {
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }
}
</style>
