<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Room Allotment</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Room Type</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <SettingsMenu />

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Room Type</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <!-- /Table Header -->
                <a-table :dataSource="room_types" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'room_price'">
                      <span>
                        ₦ {{ formatNumberWithCommas(record.room_price) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'created_at'">
                      <span>
                        {{ formattedDate(record.created_at) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <span>
                        {{ formattedDate(record.created_at) }}
                      </span>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Room Type</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Name <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="e.g VIP" v-model="room_name" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Room Type <span class="login-danger">*</span></label>
                  <a-select type="text" name="cat" v-model:value="room_type">
                    <a-select-option value="" disabled>Select Room Type</a-select-option>
                    <a-select-option value="Single">Single</a-select-option>
                    <a-select-option value="Multiple">Multiple</a-select-option>
                    <!-- <option value="Syrup">Syrup</option> -->
                  </a-select>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Room Number <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="e.g 5B" v-model="room_number" required />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6" v-if="room_type == 'Single'">
                <div class="input-block local-forms">
                  <label>Price <span class="login-danger">*</span></label>
                  <input class="form-control" type="number" placeholder="e.g 5000" v-model="room_price" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6" v-else-if="room_type == 'Multiple'">
                <div class="input-block local-forms">
                  <label>Price Per Person<span class="login-danger">*</span></label>
                  <input class="form-control" type="number" placeholder="e.g 5000" v-model="room_price" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6" v-if="room_type == 'Multiple'">
                <div class="input-block local-forms">
                  <label>Number Of Occupants
                    <span class="login-danger">*</span></label>
                  <input class="form-control" type="number" placeholder="e.g 5" v-model="occupants" />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span type="submit" class="btn btn-primary submit-form me-2" @click="addRoomType()">
                    Submit
                  </span>
                  <button type="cancel" class="btn btn-primary cancel-form bg-danger text-light cancel-form">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Room_setting";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "room_name",
    key: "room_name",
  },
  {
    title: "Room Type",
    dataIndex: "room_type",
    key: "room_type",
  },
  {
    title: "Room Number",
    dataIndex: "room_number",
    key: "room_number",
  },
  {
    title: "Number Of Occupants",
    dataIndex: "occupants",
    key: "occupants",
  },
  {
    title: "Price",
    dataIndex: "room_price",
    key: "room_price",
  },
  {
    title: "Add By",
    dataIndex: "admin_name",
    key: "admin_name",
  },
  {
    title: "Date Added",
    dataIndex: "created_at",
    key: "created_at",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: false,
      room_types: [],
      reserve_room_types: [],
      room_type: "",
      room_name: "",
      room_price: "",
      room_number: "",
      occupants: "",
      room_type_avatar: null,
      search: "",
      columns,
    };
  },

  methods: {
    ...mapActions({
      _getRoomTypes: "roomAllotment/getRoomTypes",
      _addRoomType: "roomAllotment/addRoomType",
    }),

    getRoomTypes() {
      this.isLoading = true;
      this._getRoomTypes()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.room_types = this.response.data;
            this.reserve_room_types = this.room_types;
            // this.$toast.success(this.response["message"]);
            console.log(this.room_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    // handleImageChange(event) {
    //   this.drug_category_avatar = event.target.files[0];
    // },

    addRoomType() {
      this._addRoomType({
        room_type: this.room_type,
        room_name: this.room_name,
        room_price: this.room_price,
        occupants: this.occupants,
        room_number: this.room_number,
      })
        .then((response) => {
          this.getRoomTypes();
          this.response = response.data;
          // console.log(response);
          // if (this.response["status"] == true) {
          this.$toast.success(this.response["message"]);
          $("#con-close-modal").modal("hide");
          this.room_type = "";
          this.room_name = "";
          this.room_price = "";
          this.room_number = "";
          this.occupants = "";
          // }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.room_types = this.reserve_room_types;
      } else {
        this.room_types = this.reserve_room_types;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.room_types.length; i++) {
          var room_name = this.room_types[i].room_name.toLowerCase();
          var room_price = this.room_types[i].room_price;
          var occupants = this.room_types[i].occupants.toLowerCase();
          var room_number = this.room_types[i].room_number;
          if (
            room_name.includes(searchLetter) ||
            room_price.includes(searchLetter) ||
            occupants.includes(searchLetter) ||
            room_number.includes(searchLetter)
          ) {
            searchResult.push(this.room_types[i]);
          }
          // if (searchResult == []) {
          //   this.room_types = this.room_types;
          // }
        }
      }
      if (this.search == "") {
        this.room_types = this.reserve_room_types;
      } else {
        this.room_types = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },

  mounted() {
    setTimeout(()=>{
      $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    this.getRoomTypes();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<!-- <div class="input-block local-forms">
  <label
    >Add Type Image<span class="login-danger"
      >*</span
    ></label
  >
  <input
    class="form-control"
    type="file"
    placeholder=""
    accept="*"
    @change="handleImageChange"
    required
  />
</div> -->
