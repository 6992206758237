<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="patients.html">Patients </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">Edit Patient</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Patients Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>First Name <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="text" v-model="first_name" requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Last Name <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="text" v-model="last_name" requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>User Name <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="text" v-model="user_name" requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="text" v-model="phone" @input="limitInput(this)"
                        maxlength="11" id="phone" requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Email </label>
                      <input class="form-control inputs" required type="email" v-model="email" requ="yes" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Date Of Birth
                        <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="date" v-model="dob" :max="current_date"
                        requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" v-model="gender" value="Male" />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" v-model="gender" value="Female" />Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Next of Kin First Name<span class="login-danger">*</span></label>
                      <input class="form-control inputs" type="text" placeholder="" v-model="nok_first_name" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Next of Kin Last name<span class="login-danger">*</span></label>
                      <input class="form-control inputs" type="text" placeholder="" v-model="nok_last_nane" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>NOK Relationship<span class="login-danger">*</span></label>
                      <input class="form-control inputs" type="text" placeholder="" v-model="nok_relationship" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>NOK Mobile Number<span class="login-danger">*</span></label>
                      <input class="form-control inputs" type="number" placeholder="" v-model="nok_phone_number" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <textarea class="form-control inputs" requ="yes" rows="3" cols="30" v-model="address"></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Country <span class="login-danger">*</span></label>
                      <a-select showSearch class="w-100" v-model:value="country" title="Select Country">
                        <!-- <a-select-option key="N" value="N"></a-select-option> -->
                        <a-select-option disabled>Select Country</a-select-option>
                        <a-select-option value="Afghanistan">Afghanistan</a-select-option>
                        <a-select-option value="Åland Islands">Åland Islands</a-select-option>
                        <a-select-option value="Albania">Albania</a-select-option>
                        <a-select-option value="Algeria">Algeria</a-select-option>
                        <a-select-option value="American Samoa">American Samoa</a-select-option>
                        <a-select-option value="Andorra">Andorra</a-select-option>
                        <a-select-option value="Angola">Angola</a-select-option>
                        <a-select-option value="Anguilla">Anguilla</a-select-option>
                        <a-select-option value="Antarctica">Antarctica</a-select-option>
                        <a-select-option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </a-select-option>
                        <a-select-option value="Argentina">Argentina</a-select-option>
                        <a-select-option value="Armenia">Armenia</a-select-option>
                        <a-select-option value="Aruba">Aruba</a-select-option>
                        <a-select-option value="Australia">Australia</a-select-option>
                        <a-select-option value="Austria">Austria</a-select-option>
                        <a-select-option value="Azerbaijan">Azerbaijan</a-select-option>
                        <a-select-option value="Bahamas">Bahamas</a-select-option>
                        <a-select-option value="Bahrain">Bahrain</a-select-option>
                        <a-select-option value="Bangladesh">Bangladesh</a-select-option>
                        <a-select-option value="Barbados">Barbados</a-select-option>
                        <a-select-option value="Belarus">Belarus</a-select-option>
                        <a-select-option value="Belgium">Belgium</a-select-option>
                        <a-select-option value="Belize">Belize</a-select-option>
                        <a-select-option value="Benin">Benin</a-select-option>
                        <a-select-option value="Bermuda">Bermuda</a-select-option>
                        <a-select-option value="Bhutan">Bhutan</a-select-option>
                        <a-select-option value="Bolivia">Bolivia</a-select-option>
                        <a-select-option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </a-select-option>
                        <a-select-option value="Botswana">Botswana</a-select-option>
                        <a-select-option value="Bouvet Island">Bouvet Island</a-select-option>
                        <a-select-option value="Brazil">Brazil</a-select-option>
                        <a-select-option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </a-select-option>
                        <a-select-option value="Brunei Darussalam">
                          Brunei Darussalam
                        </a-select-option>
                        <a-select-option value="Bulgaria">Bulgaria</a-select-option>
                        <a-select-option value="Burkina Faso">Burkina Faso</a-select-option>
                        <a-select-option value="Burundi">Burundi</a-select-option>
                        <a-select-option value="Cambodia">Cambodia</a-select-option>
                        <a-select-option value="Cameroon">Cameroon</a-select-option>
                        <a-select-option value="Canada">Canada</a-select-option>
                        <a-select-option value="Cape Verde">Cape Verde</a-select-option>
                        <a-select-option value="Cayman Islands">Cayman Islands</a-select-option>
                        <a-select-option value="Central African Republic">
                          Central African Republic
                        </a-select-option>
                        <a-select-option value="Chad">Chad</a-select-option>
                        <a-select-option value="Chile">Chile</a-select-option>
                        <a-select-option value="China">China</a-select-option>
                        <a-select-option value="Christmas Island">
                          Christmas Island
                        </a-select-option>
                        <a-select-option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </a-select-option>
                        <a-select-option value="Colombia">Colombia</a-select-option>
                        <a-select-option value="Comoros">Comoros</a-select-option>
                        <a-select-option value="Congo">Congo</a-select-option>
                        <a-select-option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </a-select-option>
                        <a-select-option value="Cook Islands">Cook Islands</a-select-option>
                        <a-select-option value="Costa Rica">Costa Rica</a-select-option>
                        <a-select-option value="Cote D'ivoire">Cote D'ivoire</a-select-option>
                        <a-select-option value="Croatia">Croatia</a-select-option>
                        <a-select-option value="Cuba">Cuba</a-select-option>
                        <a-select-option value="Cyprus">Cyprus</a-select-option>
                        <a-select-option value="Czech Republic">Czech Republic</a-select-option>
                        <a-select-option value="Denmark">Denmark</a-select-option>
                        <a-select-option value="Djibouti">Djibouti</a-select-option>
                        <a-select-option value="Dominica">Dominica</a-select-option>
                        <a-select-option value="Dominican Republic">
                          Dominican Republic
                        </a-select-option>
                        <a-select-option value="Ecuador">Ecuador</a-select-option>
                        <a-select-option value="Egypt">Egypt</a-select-option>
                        <a-select-option value="El Salvador">El Salvador</a-select-option>
                        <a-select-option value="Equatorial Guinea">
                          Equatorial Guinea
                        </a-select-option>
                        <a-select-option value="Eritrea">Eritrea</a-select-option>
                        <a-select-option value="Estonia">Estonia</a-select-option>
                        <a-select-option value="Ethiopia">Ethiopia</a-select-option>
                        <a-select-option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </a-select-option>
                        <a-select-option value="Faroe Islands">Faroe Islands</a-select-option>
                        <a-select-option value="Fiji">Fiji</a-select-option>
                        <a-select-option value="Finland">Finland</a-select-option>
                        <a-select-option value="France">France</a-select-option>
                        <a-select-option value="French Guiana">French Guiana</a-select-option>
                        <a-select-option value="French Polynesia">
                          French Polynesia
                        </a-select-option>
                        <a-select-option value="French Southern Territories">
                          French Southern Territories
                        </a-select-option>
                        <a-select-option value="Gabon">Gabon</a-select-option>
                        <a-select-option value="Gambia">Gambia</a-select-option>
                        <a-select-option value="Georgia">Georgia</a-select-option>
                        <a-select-option value="Germany">Germany</a-select-option>
                        <a-select-option value="Ghana">Ghana</a-select-option>
                        <a-select-option value="Gibraltar">Gibraltar</a-select-option>
                        <a-select-option value="Greece">Greece</a-select-option>
                        <a-select-option value="Greenland">Greenland</a-select-option>
                        <a-select-option value="Grenada">Grenada</a-select-option>
                        <a-select-option value="Guadeloupe">Guadeloupe</a-select-option>
                        <a-select-option value="Guam">Guam</a-select-option>
                        <a-select-option value="Guatemala">Guatemala</a-select-option>
                        <a-select-option value="Guernsey">Guernsey</a-select-option>
                        <a-select-option value="Guinea">Guinea</a-select-option>
                        <a-select-option value="Guinea-bissau">Guinea-bissau</a-select-option>
                        <a-select-option value="Guyana">Guyana</a-select-option>
                        <a-select-option value="Haiti">Haiti</a-select-option>
                        <a-select-option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </a-select-option>
                        <a-select-option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </a-select-option>
                        <a-select-option value="Honduras">Honduras</a-select-option>
                        <a-select-option value="Hong Kong">Hong Kong</a-select-option>
                        <a-select-option value="Hungary">Hungary</a-select-option>
                        <a-select-option value="Iceland">Iceland</a-select-option>
                        <a-select-option value="India">India</a-select-option>
                        <a-select-option value="Indonesia">Indonesia</a-select-option>
                        <a-select-option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </a-select-option>
                        <a-select-option value="Iraq">Iraq</a-select-option>
                        <a-select-option value="Ireland">Ireland</a-select-option>
                        <a-select-option value="Isle of Man">Isle of Man</a-select-option>
                        <a-select-option value="Israel">Israel</a-select-option>
                        <a-select-option value="Italy">Italy</a-select-option>
                        <a-select-option value="Jamaica">Jamaica</a-select-option>
                        <a-select-option value="Japan">Japan</a-select-option>
                        <a-select-option value="Jersey">Jersey</a-select-option>
                        <a-select-option value="Jordan">Jordan</a-select-option>
                        <a-select-option value="Kazakhstan">Kazakhstan</a-select-option>
                        <a-select-option value="Kenya">Kenya</a-select-option>
                        <a-select-option value="Kiribati">Kiribati</a-select-option>
                        <a-select-option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </a-select-option>
                        <a-select-option value="Korea, Republic of">
                          Korea, Republic of
                        </a-select-option>
                        <a-select-option value="Kuwait">Kuwait</a-select-option>
                        <a-select-option value="Kyrgyzstan">Kyrgyzstan</a-select-option>
                        <a-select-option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </a-select-option>
                        <a-select-option value="Latvia">Latvia</a-select-option>
                        <a-select-option value="Lebanon">Lebanon</a-select-option>
                        <a-select-option value="Lesotho">Lesotho</a-select-option>
                        <a-select-option value="Liberia">Liberia</a-select-option>
                        <a-select-option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </a-select-option>
                        <a-select-option value="Liechtenstein">Liechtenstein</a-select-option>
                        <a-select-option value="Lithuania">Lithuania</a-select-option>
                        <a-select-option value="Luxembourg">Luxembourg</a-select-option>
                        <a-select-option value="Macao">Macao</a-select-option>
                        <a-select-option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </a-select-option>
                        <a-select-option value="Madagascar">Madagascar</a-select-option>
                        <a-select-option value="Malawi">Malawi</a-select-option>
                        <a-select-option value="Malaysia">Malaysia</a-select-option>
                        <a-select-option value="Maldives">Maldives</a-select-option>
                        <a-select-option value="Mali">Mali</a-select-option>
                        <a-select-option value="Malta">Malta</a-select-option>
                        <a-select-option value="Marshall Islands">
                          Marshall Islands
                        </a-select-option>
                        <a-select-option value="Martinique">Martinique</a-select-option>
                        <a-select-option value="Mauritania">Mauritania</a-select-option>
                        <a-select-option value="Mauritius">Mauritius</a-select-option>
                        <a-select-option value="Mayotte">Mayotte</a-select-option>
                        <a-select-option value="Mexico">Mexico</a-select-option>
                        <a-select-option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </a-select-option>
                        <a-select-option value="Moldova, Republic of">
                          Moldova, Republic of
                        </a-select-option>
                        <a-select-option value="Monaco">Monaco</a-select-option>
                        <a-select-option value="Mongolia">Mongolia</a-select-option>
                        <a-select-option value="Montenegro">Montenegro</a-select-option>
                        <a-select-option value="Montserrat">Montserrat</a-select-option>
                        <a-select-option value="Morocco">Morocco</a-select-option>
                        <a-select-option value="Mozambique">Mozambique</a-select-option>
                        <a-select-option value="Myanmar">Myanmar</a-select-option>
                        <a-select-option value="Namibia">Namibia</a-select-option>
                        <a-select-option value="Nauru">Nauru</a-select-option>
                        <a-select-option value="Nepal">Nepal</a-select-option>
                        <a-select-option value="Netherlands">Netherlands</a-select-option>
                        <a-select-option value="Netherlands Antilles">
                          Netherlands Antilles
                        </a-select-option>
                        <a-select-option value="New Caledonia">New Caledonia</a-select-option>
                        <a-select-option value="New Zealand">New Zealand</a-select-option>
                        <a-select-option value="Nicaragua">Nicaragua</a-select-option>
                        <a-select-option value="Niger">Niger</a-select-option>
                        <a-select-option value="Nigeria">Nigeria</a-select-option>
                        <a-select-option value="Niue">Niue</a-select-option>
                        <a-select-option value="Norfolk Island">Norfolk Island</a-select-option>
                        <a-select-option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </a-select-option>
                        <a-select-option value="Norway">Norway</a-select-option>
                        <a-select-option value="Oman">Oman</a-select-option>
                        <a-select-option value="Pakistan">Pakistan</a-select-option>
                        <a-select-option value="Palau">Palau</a-select-option>
                        <a-select-option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </a-select-option>
                        <a-select-option value="Panama">Panama</a-select-option>
                        <a-select-option value="Papua New Guinea">
                          Papua New Guinea
                        </a-select-option>
                        <a-select-option value="Paraguay">Paraguay</a-select-option>
                        <a-select-option value="Peru">Peru</a-select-option>
                        <a-select-option value="Philippines">Philippines</a-select-option>
                        <a-select-option value="Pitcairn">Pitcairn</a-select-option>
                        <a-select-option value="Poland">Poland</a-select-option>
                        <a-select-option value="Portugal">Portugal</a-select-option>
                        <a-select-option value="Puerto Rico">Puerto Rico</a-select-option>
                        <a-select-option value="Qatar">Qatar</a-select-option>
                        <a-select-option value="Reunion">Reunion</a-select-option>
                        <a-select-option value="Romania">Romania</a-select-option>
                        <a-select-option value="Russian Federation">
                          Russian Federation
                        </a-select-option>
                        <a-select-option value="Rwanda">Rwanda</a-select-option>
                        <a-select-option value="Saint Helena">Saint Helena</a-select-option>
                        <a-select-option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </a-select-option>
                        <a-select-option value="Saint Lucia">Saint Lucia</a-select-option>
                        <a-select-option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </a-select-option>
                        <a-select-option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </a-select-option>
                        <a-select-option value="Samoa">Samoa</a-select-option>
                        <a-select-option value="San Marino">San Marino</a-select-option>
                        <a-select-option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </a-select-option>
                        <a-select-option value="Saudi Arabia">Saudi Arabia</a-select-option>
                        <a-select-option value="Senegal">Senegal</a-select-option>
                        <a-select-option value="Serbia">Serbia</a-select-option>
                        <a-select-option value="Seychelles">Seychelles</a-select-option>
                        <a-select-option value="Sierra Leone">Sierra Leone</a-select-option>
                        <a-select-option value="Singapore">Singapore</a-select-option>
                        <a-select-option value="Slovakia">Slovakia</a-select-option>
                        <a-select-option value="Slovenia">Slovenia</a-select-option>
                        <a-select-option value="Solomon Islands">Solomon Islands</a-select-option>
                        <a-select-option value="Somalia">Somalia</a-select-option>
                        <a-select-option value="South Africa">South Africa</a-select-option>
                        <a-select-option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </a-select-option>
                        <a-select-option value="Spain">Spain</a-select-option>
                        <a-select-option value="Sri Lanka">Sri Lanka</a-select-option>
                        <a-select-option value="Sudan">Sudan</a-select-option>
                        <a-select-option value="Suriname">Suriname</a-select-option>
                        <a-select-option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </a-select-option>
                        <a-select-option value="Swaziland">Swaziland</a-select-option>
                        <a-select-option value="Sweden">Sweden</a-select-option>
                        <a-select-option value="Switzerland">Switzerland</a-select-option>
                        <a-select-option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </a-select-option>
                        <a-select-option value="Taiwan">Taiwan</a-select-option>
                        <a-select-option value="Tajikistan">Tajikistan</a-select-option>
                        <a-select-option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </a-select-option>
                        <a-select-option value="Thailand">Thailand</a-select-option>
                        <a-select-option value="Timor-leste">Timor-leste</a-select-option>
                        <a-select-option value="Togo">Togo</a-select-option>
                        <a-select-option value="Tokelau">Tokelau</a-select-option>
                        <a-select-option value="Tonga">Tonga</a-select-option>
                        <a-select-option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </a-select-option>
                        <a-select-option value="Tunisia">Tunisia</a-select-option>
                        <a-select-option value="Turkey">Turkey</a-select-option>
                        <a-select-option value="Turkmenistan">Turkmenistan</a-select-option>
                        <a-select-option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </a-select-option>
                        <a-select-option value="Tuvalu">Tuvalu</a-select-option>
                        <a-select-option value="Uganda">Uganda</a-select-option>
                        <a-select-option value="Ukraine">Ukraine</a-select-option>
                        <a-select-option value="United Arab Emirates">
                          United Arab Emirates
                        </a-select-option>
                        <a-select-option value="United Kingdom">United Kingdom</a-select-option>
                        <a-select-option value="United States">United States</a-select-option>
                        <a-select-option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </a-select-option>
                        <a-select-option value="Uruguay">Uruguay</a-select-option>
                        <a-select-option value="Uzbekistan">Uzbekistan</a-select-option>
                        <a-select-option value="Vanuatu">Vanuatu</a-select-option>
                        <a-select-option value="Venezuela">Venezuela</a-select-option>
                        <a-select-option value="Viet Nam">Viet Nam</a-select-option>
                        <a-select-option value="Virgin Islands, British">
                          Virgin Islands, British
                        </a-select-option>
                        <a-select-option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </a-select-option>
                        <a-select-option value="Wallis and Futuna">
                          Wallis and Futuna
                        </a-select-option>
                        <a-select-option value="Western Sahara">Western Sahara</a-select-option>
                        <a-select-option value="Yemen">Yemen</a-select-option>
                        <a-select-option value="Zambia">Zambia</a-select-option>
                        <a-select-option value="Zimbabwe">Zimbabwe</a-select-option>
                      </a-select>


                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3" v-if="country == 'Nigeria'">
                    <div class="input-block local-forms">
                      <label>State <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="state_id" @change="getLGA()" title="Select State"
                        showSearch :filterOption="(input, option) => filterObject(option, input, 'key')">
                        <a-select-option disabled>Select State</a-select-option>
                        <a-select-option v-for="_state in state_list" v-bind:key="_state.name" :value="_state.id">
                          {{ _state.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3" v-if="country == 'Nigeria'">
                    <div class="input-block local-forms">
                      <label>LGA <span class="login-danger">*</span></label>
                      <a-select showSearch :filterOption="(input, option) => filterObject(option, input, 'key')"
                        class="w-100" v-model:value="lga" title="Select Local Government">
                        <a-select-option disabled>Select Local Government</a-select-option>
                        <a-select-option v-for="_lga in lga_list" v-bind:key="_lga.name" :value="_lga.id">
                          {{ _lga.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>City <span class="login-danger">*</span></label>
                      <input class="form-control inputs" required type="text" v-model="city" requ="yes" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Zip Code <span class="login-danger">*</span></label>
                      <input class="form-control inputs" type="number" v-model="zip_code" maxLength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Patient ID/Folder Number </label>
                      <input class="form-control" type="text" v-model="custom_id" />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-top-form">
                          <label class="local-top"
                            >Avatar <span class="login-danger">*</span></label
                          >
                          <div class="settings-btn upload-files-avator">
                            <input
                              type="file"
                              accept="image/*"
                              name="image"
                              id="file"
                              class="hide-input"
                            />
                            <label for="file" class="upload">Choose File</label>
                          </div>
                        </div>
                      </div> -->

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="addPatient()"
                        v-if="Filled">Submit</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light"
                        @click="$router.go(-1)">Cancel</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() {
    // uploadForm.addEventListener('submit', function(e) {
    // 	var uploadFiles = document.getElementById('js-upload-files').files;
    // 	e.preventDefault();
    // 	startUpload(uploadFiles);
    // });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
