<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/Schedule">Doctor Schedule </router-link>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Schedule List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Schedule List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="/Add-Schedule"
                            class="btn btn-primary add-pluss ms-2"
                          >
                            <img
                              src="../../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th v-if="role == 'Admin' || role == 'Super Admin'">
                        Doctor Name
                      </th>
                      <th v-if="role == 'Admin' || role == 'Super Admin'">
                        Department
                      </th>
                      <th>Date</th>
                      <th>Available Time</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in main_data" :key="index">
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image" v-if="role == 'Admin'">
                        <a href="profile.html"
                          ><img
                            width="28"
                            height="28"
                            src="../../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />{{ data.doctor }}</a
                        >
                      </td>
                      <td v-if="role == 'Admin'">{{ data.specialization }}</td>
                      <td>{{ data.date }}</td>
                      <td>{{ data.start_time }} - {{ data.end_time }}</td>
                      <td>
                        <button
                          class="custom-badge status-green"
                          v-if="data.isActive == 1"
                        >
                          Active
                        </button>
                        <button
                          class="custom-badge status-red"
                          v-if="data.isActive == 0"
                        >
                          In Active
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 15%"
            v-if="main_data == '' || main_data == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 150px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../plugins/Header";
import Sidebar from "../../plugins/Side_bar";
import Loader from "../../plugins/Loader.vue";
import Inloader from "../../plugins/inloader";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: false,
      reponse: "",
      main_data: [],
      role: localStorage.getItem("role"),
      search: "",
      main_data_reserve: [],
    };
  },

  methods: {
    ...mapActions({
      _getSheduleList: "general/getSheduleList",
    }),
    getSheduleList() {
      this.isLoading = true;
      this._getSheduleList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            this.main_data_reserve = this.main_data;
            // Schedules;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var doctor = this.main_data[i].doctor.toLowerCase();
          var specialization = this.main_data[i].specialization.toLowerCase();
          var date = this.main_data[i].date;
          if (
            doctor.includes(searchLetter) ||
            specialization.includes(searchLetter) ||
            date.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.getSheduleList();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
