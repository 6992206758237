<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div id="con-close-modal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster fluid_chart_modal_out" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Fluid Balance (Output)</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" id="timecy">
                    <label>Time</label>
                    <input class="form-control" type="time" v-model="fluidOut.fluid_output_time" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Urine</label>
                    <input class="form-control" type="text" v-model="fluidOut.fluid_output_urine" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Drain</label>
                    <input class="form-control" type="text" v-model="fluidOut.fluid_output_drain" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>NG Tube</label>
                    <input class="form-control" type="text" v-model="fluidOut.fluid_output_ng_tube" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Vomit</label>
                    <input class="form-control" type="text" v-model="fluidOut.fluid_output_vomit" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Feaces</label>
                    <input class="form-control" type="text" v-model="fluidOut.fluid_output_faeces" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-12 col-sm-6"></div>

                <div class="col-6" id="subcanme">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="submitForm()">
                      Submit
                    </span>
                    <span class="btn btn-primary cance_fluid_chart_out bg-danger text-light">Cancel</span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  props: { record: Object },
  data() {
    return {
      fluidOut: {
        fluid_output_drain: "",
        fluid_output_urine: "",
        fluid_output_time: "",
        fluid_output_ng_tube: "",
        fluid_output_faeces: "",
        fluid_output_vomit: "",
      },
      isUpdating: false,
    };
  },

  methods: {
    ...mapActions({
      _add_bioData: "patient/add_bioData",
      _update_bioData: "patient/update_bioData",
    }),
    submitForm() {
      this.isUpdating ? this.updatePatientBiodata() : this.add_patient_bio_data();
    },
    updatePatientBiodata() {
      $(".inloader").show();

      this._update_bioData({
        payload: this.fluidOut,
        type: "fluid_balance_output",
        bioDataId: this.fluidOut.id,
      })
        .then((response) => {
          this.$emit("updated", { id: this.fluidOut.id, data: response.data.data });
          $(".inloader").hide();
          this.$toast.success(response.data.message);


        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            if (status != 500) {
              this.$toast.error(error.response.data.message);
            } else {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    add_patient_bio_data() {
      $(".inloader").show();
      $(".open_fluid_chart_out").modal("hide");
      // if (this.whoisLoggedin == "Doctor" || this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      // if (this.whoisLoggedin == "Front Desk" ||this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      let id = localStorage.getItem("this_patient_id");
      this._add_bioData({ id: id, payload: this.fluidOut })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);

            // this.$router.push({ name: "Patients" });
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },
    goto(route) {
      window.location = route;
      // this.$router.push({ name: route })
    },
    separator() {
      var input = document.getElementById("bp");
      input.value = input.value.replace(/[^\d/]/g, "");
      if (input.value.length > 3 && input.value.length < 5) {
        if (input.value.charAt(3) !== "/") {
          input.value = input.value.slice(0, 3) + "/" + input.value.slice(2);
        }
      }
    },
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.fluidOut = newVal;
          this.isUpdating = true;
        } else {
          this.isUpdating = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    $(".cance_fluid_chart_out").click(function () {
      $(".fluid_chart_modal_out").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 480px) {
  #timecy {
    margin-top: 10%;
    height: 40px;
  }

  /* #hrtrat {
    margin-top: 3%;
  } */

  #con-close-modal {
    margin-left: 0px !important;
    margin-top: 90px !important;
    margin-top: 200px;
    margin-left: 100px;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  #subcanme {
    width: 100% !important;
  }
}
</style>
