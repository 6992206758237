export const formatAmount = (amount, addNairaSign = false) => {
  return (
    (addNairaSign ? "₦" : "") +
    parseFloat(amount || 0).toLocaleString("en-Us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export function formatDate(timestamp, showTime = false, allDigit = false) {
  const options = allDigit
    ? {}
    : {
        year: "numeric",
        month: "long",
        day: "numeric",

        ...(showTime
          ? {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }
          : {}),
      };
  return timestamp
    ? new Date(timestamp).toLocaleString("en-US", options)
    : "--";
}

export function capitalize(string) {
  const _string = string || "";
  return _string.charAt(0).toUpperCase() + _string.slice(1);
}
