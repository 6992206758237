<template>
    <button class="vibrating-button" @click="$emit('click')">
        {{ label }}
    </button>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.vibrating-button {
    background-color: var(--bs-primary);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: vibrate 0.5s infinite;
}

@keyframes vibrate {
    0% {
        transform: translate(0, 0);
    }

    20% {
        transform: translate(2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, -2px);
    }

    80% {
        transform: translate(-2px, 2px);
    }

    100% {
        transform: translate(0, 0);
    }
}
</style>