<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->
  <!-- style="margin-top: 200px; margin-left: 100px" -->
  <div
    id="patient-timeline-modal"
    class="modal modal-xl animate__animated animate__zoomIn animate__faster add-meidcal_history-modal"
    :style="{
      display: showModal ? 'block' : 'none',
      marginTop: '100px',
      marginLeft: '100px',
    }"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="mb-4 modal-title">
            Edit {{ selectedData.type }} for
            <strong>{{ selectedData.patientName }}</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- Tabs -->
          <ul class="nav nav-tabs" id="notesTab" role="tablist">
            <li class="nav-item">
              <button
                class="nav-link active"
                id="note-tab"
                data-bs-toggle="tab"
                data-bs-target="#note"
                role="tab"
              >
                {{ selectedData.type }} <span class="blinker"></span>
              </button>
            </li>
          </ul>

          <!-- Tab Contents -->
          <div class="tab-content mt-4" id="notesTabContent">
            <!-- Note Section -->
            <div class="tab-pane fade show active" id="note" role="tabpanel">
              <label for="note-textarea" class="form-label">{{
                selectedData.type
              }}</label>
              <textarea
                class="form-control input-field"
                id="note-textarea"
                rows="4"
                v-model="data.text"
              ></textarea>

              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-top-form">
                  <label class="local-top"
                    >Upload Files (Allow Multiple)<span class="login-danger"
                      >*</span
                    ></label
                  >
                  <div
                    class="settings-btn upload-files-avator upload"
                    for="file"
                    style="background-color: #f1f2f3"
                  >
                    <span v-if="images.length > 0"
                      >{{ images.length }} file(s) was selected</span
                    >
                    <input
                      type="file"
                      accept=""
                      name="note"
                      id="file"
                      multiple
                      hidden
                      @change="handleFileInput($event, data.type)"
                    />
                    <label
                      for="file"
                      class="upload"
                      style="background-color: #14a79e; color: white"
                      >Browse</label
                    >
                  </div>
                </div>

                <!-- Sample preview -->
                <div style="text-align: center">
                  <template v-if="images.length == 0">
                    <img
                      src="../../../assets/assets/img/imgpreview.png"
                      width="400px"
                      height="200px"
                    />
                  </template>
                  <div class="preview-container">
                    <div
                      class="preview-item"
                      v-for="(file, index) in images"
                      :key="index"
                    >
                      <!-- {{ file }} -->
                      <button @click="deleteFile(file.id)" class="delete-btn">
                        ×
                      </button>
                      <FileViewer
                        :blob="file.avatar"
                        :isTemp="file.isTemp"
                        v-if="typeof file.avatar === 'object'"
                      />
                      <FileViewer
                        :url="file.avatar"
                        :isTemp="file.isTemp"
                        :downloadable="false"
                        v-else
                      />
                    </div>
                  </div>
                </div>

                <!-- preview end -->
              </div>
            </div>
          </div>
          <br />
          <div class="col-12">
            <div class="doctor-submit text-end">
              <span
                type="submit"
                class="btn btn-primary submit-form me-2 view-modal"
                @click="editNote()"
                >Submit</span
              >
              <button
                type="submit"
                class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                data-bs-dismiss="modal"
                @click="closeModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /.modal -->
  </div>
</template>

<script>
// import Inloader from "../plugins/inloader";

import FileViewer from "@/components/_General/FileViewer.vue";
import jquery from "jquery";
import { mapActions } from "vuex";

export default {
  name: "past_medical_history",
  components: { FileViewer },
  data() {
    return {
      showModal: false,
      data: {
        text: null,
        type: null,
        images: [],
      },
      note: null,
      examination: null,
      diagnosis: null,
      radiology_text: null,
      lab_text: null,
      referral: null,
      medication: null,
      previews: {
        note: [],
        examination: [],
        diagnosis: [],
        lab: [],
        radiology: [],
        referral: [],
        medication: [],
      },
      FileforUpload: [], // Consolidated array for server upload
    };
  },
  computed: {
    noteImages() {
      return this.FileforUpload.filter((file) => file.type == "note");
    },

    images() {
      return this.FileforUpload.filter((file) => file.type == this.data.type);
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selectedData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      _editNote: "doctor/Editnote",
    }),
    deleteFile(id) {
      // Find the correct files array and remove the file at the specified index
      const fileIndex = this.FileforUpload.findIndex((file) => file.id == id);
      if (fileIndex !== -1) {
        this.FileforUpload.splice(fileIndex, 1);
      }
    },

    handleFileInput(event, type) {
      const files = event.target.files;
      for (let file of files) {
        // Check if file is already uploaded
        if (
          this.FileforUpload.some(
            (uploadedFile) =>
              uploadedFile.type === type &&
              uploadedFile.avatar.name === file.name
          )
        ) {
          alert(`The file "${file.name}" is already uploaded.`);
          continue;
        }

        this.FileforUpload.push({
          type: type,
          avatar: file,
          isTemp: false,
          id: `${file.name}-${type}`,
        });

        this.data.images.push(file);
        // alert(this.data.images);
      }
    },

    openModal() {
      this.showModal = true; // Show modal
    },

    closeModal() {
      this.showModal = false; // Hide modal
    },

    editNote() {
      $(".inloader").show();
      let images = [];
      let existingImages = [];
      this.FileforUpload.forEach((file) => {
        if (file.avatar instanceof File) {
          images.push(file.avatar); // Send only files to the backend
        } else {
          existingImages.push(file.avatar); // Retain existing URLs
        }
      });
      this._editNote({
        id: this.data.id,
        payload: {
          text: this.data.text,
          type: this.data.type,
          images: images,
          existingImages: existingImages,
        },
      })
        .then((response) => {
          this.response = response.data;

          if (this.response["status"] == true) {
            // alert(this.response)
            $(".inloader").hide();
            $("#add-meidcal_history-modal").modal("hide");
            this.FileforUpload = [];
            this.$toast.success(this.response["message"]);
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },
  },

  watch: {
    selectedData: {
      immediate: true, // Sync when the component is initialized
      handler(newVal) {
        this.data = {
          id:
            newVal?.note?.id ||
            newVal?.examination?.id ||
            newVal?.diagnosis?.id ||
            "", // Default to empty string if missing
          text:
            newVal?.note?.note_text ||
            newVal?.examination?.examination_text ||
            newVal?.diagnosis?.diagnosis_text ||
            "", // Default to empty string if missing
          images:
            newVal?.note?.note_images ||
            newVal?.examination?.examination_images ||
            newVal?.diagnosis?.diagnosis_images ||
            [], // Default to empty array if missing
          type: newVal?.type || "",
        };

        for (let image of this.data.images) {
          let count = 1;
          // console.log("Image", image.name);
          this.FileforUpload.push({
            type: this.data.type,
            avatar: image.avatar,
            isTemp: false,
            id: `${count}-${this.data.type}`,
          });
          count++;
        }
      },
    },
  },

  mounted() {
    // this.loadFileInputs();

    // Add event listeners to text areas
    document.querySelectorAll(".input-field").forEach((textarea) => {
      if (
        textarea instanceof HTMLInputElement ||
        textarea instanceof HTMLTextAreaElement
      ) {
        textarea.addEventListener("input", function () {
          const tabId = textarea.id.replace("-textarea", "-tab"); // Match tab ID
          const parentTab = document.querySelector(`#${tabId}`); // Tab corresponding to the textarea
          const blinker = parentTab.querySelector(".blinker"); // Blinker inside the corresponding tab

          if (textarea.value.trim() !== "") {
            // Show blinker and set the correct color
            blinker.style.display = "inline-block";
            if (parentTab.classList.contains("active")) {
              blinker.style.backgroundColor = "black"; // Black for active tab

              document.querySelectorAll(".blinker").forEach((otherBlinker) => {
                const otherTab = otherBlinker.closest(".nav-link");
                if (otherTab.id !== tabId) {
                  otherBlinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
                }
              });
            } else {
              // Default color for inactive tabs
              // blinker.style.backgroundColor = '#14a79e';
            }
          } else {
            // Hide blinker if textarea is empty
            blinker.style.display = "none";
          }
        });
      } else {
        console.warn(
          "Expected an input or textarea element, but got:",
          textarea
        );
      }
    });

    // Add event listeners to tabs for click functionality
    document.querySelectorAll(".nav-link").forEach((tab) => {
      tab.addEventListener("click", function () {
        const tabId = tab.id; // Tab ID
        const textareaId = tabId.replace("-tab", "-textarea"); // Match textarea ID
        const textarea = document.querySelector(`#${textareaId}`); // Get corresponding textarea
        const blinker = tab.querySelector(".blinker"); // Blinker inside the tab

        if (
          textarea &&
          (textarea instanceof HTMLInputElement ||
            textarea instanceof HTMLTextAreaElement)
        ) {
          if (textarea.value.trim() !== "") {
            // Show blinker and set the correct color
            blinker.style.display = "inline-block";
            if (tab.classList.contains("active")) {
              blinker.style.backgroundColor = "black"; // Black for active tab

              document.querySelectorAll(".blinker").forEach((otherBlinker) => {
                const otherTab = otherBlinker.closest(".nav-link");
                if (otherTab.id !== tabId) {
                  otherBlinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
                }
              });
            } else {
              blinker.style.backgroundColor = "#14a79e"; // Default color for inactive tabs
            }
          } else {
            // Hide blinker if textarea is empty
            blinker.style.display = "none";
          }
        } else {
          console.warn(
            `Expected a valid textarea element but found none for ID: ${textareaId}`
          );
        }
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background-color: #f8f9fa;
}

.custom-container {
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link {
  background-color: #000;
  color: #f8f9fa;
  position: relative;
  margin-right: 5px;
  border-radius: 4px;
  border: none;
}

.nav-tabs .nav-link:hover {
  background-color: #000;
  color: #f8f9fa;
  position: relative;
  margin-right: 5px;
  border-radius: 4px;
  border: none;
}

/* Circular Blinker */
.nav-link .blinker {
  position: absolute;
  top: 2px;
  right: -2px;
  height: 8px;
  width: 8px;
  background-color: #14a79e;
  border-radius: 50%;
  display: none;
  /* Hidden by default */
  animation: blinker 1s infinite;
  z-index: 999;
}

textarea {
  margin-bottom: 20px;
}

@keyframes blinker {
  /* 0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  } */

  100% {
    transform: scale(2.5);
  }
}

.nav-link.active .blinker {
  display: none;
  /* Hide on active tabs */
}

.nav-tabs .nav-link.active {
  background-color: #14a79e;
  color: #fff;
}

/* preview design */
.preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.preview-item {
  position: relative;

  text-align: end;
}

.badge {
  position: absolute;
  top: 5px;
  left: 5px;
  background: #007bff;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
}

.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  line-height: 25px;
  z-index: 1;
}

.delete-btn:hover {
  background: darkred;
}

.file-name {
  margin-top: 10px;
  font-size: 12px;
  word-wrap: break-word;
}

@media (max-width: 800px) {
  .nav-tabs {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px !important;
  }

  .nav-item {
    width: 48% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }
}

@media (max-width: 620px) {
  .nav-tabs {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    gap: 10px !important;
  }

  .nav-item {
    width: 48% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }

  .modal-content {
    width: 565px !important;
  }
}

@media (max-width: 480px) {
  .nav-tabs {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    gap: 20px !important;
  }

  .nav-item {
    width: 100% !important;
  }

  .nav-link {
    width: 100% !important;
  }

  .local-top-form {
    margin-top: 40px;
  }

  .modal-content {
    width: auto !important;
  }
}
</style>
