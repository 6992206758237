<template>
  <div class="radar">
    <i class="fa fa-phone call-icon"></i>
    <div class="radar-circle"></div>
    <div class="radar-circle"></div>
    <div class="radar-circle"></div>
  </div>
</template>
<style>
/* Radar container */
.radar {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
}

/* Center the call icon */
.call-icon {
  font-size: 32px;
  color: var(--bs-primary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

/* Radar circles */
.radar-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid var(--bs-primary);
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  animation: radar-pulse 2s infinite;
}

/* Delay each circle */
.radar-circle:nth-child(2) {
  animation-delay: 0.5s;
}

.radar-circle:nth-child(3) {
  animation-delay: 1s;
}

/* Animation for radar circles */
@keyframes radar-pulse {
  0% {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
</style>
