<template>
    <div :id="id" class="sidebar-menu">
        <ul>
            <!-- <li class="menu-title">Main</li> -->
            <li class="submenu" style="cursor: pointer">
                <a><span class="menu-side"><i class="fa fa-dashboard"></i></span>
                    <span id="dashhere"> Dashboard </span> <span class="menu-arrow"></span></a>
                <ul>
                    <li class="only_for_super_admin">
                        <router-link to="/Hospital-Dashboard"> Super Dashboard </router-link>
                    </li>
                    <li class="nnavhide admin_nav">
                        <router-link to="/Hospital-Dashboard">
                            Hospital Dashboard
                        </router-link>
                    </li>

                    <li class="nnavhide clinic_nav not_for_Super_admin">
                        <router-link to="/Clinic-Dashboard"> Clinic Dashboard </router-link>
                    </li>
                    <li class="nnavhide doc_nav not_for_Super_admin">
                        <router-link to="Doctor-Dashboard" class="">Doctor Dashboard</router-link>
                    </li>
                    <li class="nnavhide frontdesk_nav1 hospital none">
                        <router-link to="Frontdesk-Dashboard" class="">Frontdesk Dashboard</router-link>
                    </li>
                    <li class="nnavhide frontdesk_nav2 clinic none">
                        <router-link to="Clinic-FrontDesk-Dashboard" class="">Frontdesk Dashboard</router-link>
                    </li>
                    <!-- <li class="nnavhide pat_nav" @click="goto('Patient-Dashboard')">
                <a class="">Patient Dashboard</a>
              </li> -->
                    <li class="nnavhide GOPD_nav">
                        <router-link to="GOPD-Dashboard" class="">GOPD Dashboard</router-link>
                    </li>
                    <li class="nnavhide fin_nav">
                        <router-link to="Finance-Dashboard" class="">Finance Dashboard</router-link>
                    </li>
                    <li class="nnavhide pha_nav">
                        <router-link to="Pharmacy-Dashboard" class="">Pharmacy Dashboard</router-link>
                    </li>
                    <li class="nnavhide lab_nav">
                        <router-link to="Laboratory-Dashboard" class="">Lab Dashboard</router-link>
                    </li>
                    <li class="nnavhide rad_nav">
                        <router-link to="Radiology-Dashboard" class="">Radiology Dashboard</router-link>
                    </li>
                    <li class="nnavhide nurse_nav">
                        <router-link to="Nurse-Dashboard" class="">Nurse Dashboard</router-link>
                    </li>
                </ul>
            </li>
            <!-- <li class="nnavhide rad_nav" @click="goto('Radiology-Dashboard')"> -->

            <li class="submenu navhide" v-if="$store.getters.canAccess(['add_patient', 'admitted_patients'])">
                <a href="#"><span class="menu-side"><i class="fa fa-plus-square"></i></span>
                    <span>Patients </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li v-if="$store.getters.canAccess(['add_patient'])">
                        <router-link to="/Patients">Patients List</router-link>
                    </li>
                    <li v-if="$store.getters.canAccess(['add_patient'])" class="not_for_Super_admin">
                        <router-link to="/Add-Patient">Add Patients</router-link>
                    </li>

                    <!-- <li class="not_for_Super_admin">
                <router-link to="/Edit-Patient">Edit Patient</router-link>
              </li> -->
                    <li v-if="$store.getters.canAccess(['add_patient'])" class="not_for_Super_admin">
                        <router-link to="/Transfer-Patient">Transfer Patients</router-link>
                    </li>
                    <li v-if="$store.getters.canAccess(['add_patient'])" class="hide_for_now">
                        <router-link to="/Patient-Profile">Patient Profile</router-link>
                    </li>

                    <li class="not_small">
                        <router-link to="In-Out-Patients" v-if="$store.getters.canAccess(['admitted_patients'])">In/Out
                            Patient</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="$store.getters.canAccess(['quick_services'])">
                <a href="" @click.preventDefault=""><span class="menu-side"><i
                            class="fa fa-folder-open"></i></span>
                    <span>Quick Services</span>
                    <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Add-Booking">Add Booking</router-link>
                    </li>
                    <li>
                        <router-link to="/Booking-List">Booking List</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="$store.getters.canAccess(['add_funds'])">
                <a href="#"><span class="menu-side"><i class="fas fa-chart-line"></i> </span>
                    <span> Accounts </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Service-Charges" class="">Service Charges</router-link>
                    </li>
                    <li>
                        <router-link to="/Pending-Payments">Pending Payments</router-link>
                    </li>
                    <li>
                        <router-link to="/Add-Payment" class="not_for_Super_admin">Add Payment</router-link>
                    </li>

                    <li><router-link to="/Payments">Payments</router-link></li>
                    <li class="hide_for_now">
                        <router-link to="/Invoices">Invoices</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Expenses">Expenses</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Taxes">Taxes</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Provident-Funds">Provident Fund</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="
                $store.getters.canAccess([
                    'view_pending_appointment',
                    'view_accepted_appointment',
                    'view_category_appointment',
                ])
            ">
                <a href="#"><span class="menu-side"><i class="fa fa-calendar"></i></span>
                    <span> Appointments </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <!-- doctor @click="goto('Doctor-Accepted-Appointments')" -->
                    <li class="not_small">
                        <router-link to="Unpaid-Appointments"
                            v-if="$store.getters.canAccess(['unpaid_appointment'])">Unpaid
                            Appointments</router-link>
                    </li>
                    <li class="not_small">
                        <router-link to="/Category-GOPD"
                            v-if="$store.getters.canAccess(['view_category_appointment'])">Patient
                            Queue</router-link>
                    </li>

                    <li class="not_small">
                        <router-link to="Doctor-Pending-Appointments"
                            v-if="$store.getters.canAccess(['view_pending_appointment'])">Pending</router-link>
                    </li>
                    <li>
                        <router-link to="Doctor-Accepted-Appointments"
                            v-if="$store.getters.canAccess(['view_accepted_appointment'])">Accepted</router-link>
                    </li>
                    <li class="not_small">
                        <router-link to="Doctor-Rejected-Appointments"
                            v-if="$store.getters.canAccess(['view_rejected_appointment'])">Rejected</router-link>
                    </li>

                    <!--  -->
                    <!--admin  -->
                    <!-- <li @click="goto('Appointments')" class="navhide">
                <router-link to="">Appointment List</router-link>
              </li> -->
                    <!-- <li class="navhide not_for_admin">
                <router-link to="/Book-Appointment">Book Appointment</router-link>
              </li> -->
                    <li class="navhide not_for_admin hide_for_now">
                        <router-link to="/Edit-Appointment">Edit Appointment</router-link>
                    </li>
                </ul>
            </li>

            <!-- <li
            class="navhide"
            v-if="$store.getters.canAccess(['view_category_appointment'])"
          >
            <router-link to="/Category-GOPD">
              <span class="menu-side"><span class="fa fa-stethoscope"></span></span
              ><span>Patient Queue</span></router-link
            >
          </li> -->

            <li class="submenu navhide" v-if="$store.getters.canAccess(['view_gopd_appointment'])">
                <a href="#"><span class="menu-side"><i class="fa fa-user-md"></i></span>
                    <span> GOPD </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/GOPD-Approved-List">GOPD Approved List
                        </router-link>
                        <router-link to="/GOPD-Pending-List">GOPD Pending List </router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="$store.getters.canAccess(['view_staff'])">
                <a href="#"><span class="menu-side"><i class="fa fa-group">&#xf0c0;</i></span>
                    <span> Staffs </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li><router-link to="/Staffs">Staff List</router-link></li>
                    <!-- <li><router-link to="/Doctors">Doctor List</router-link></li> -->
                    <li class="not_for_Super_admin">
                        <router-link to="/Add-Staff">Add Staff</router-link>
                    </li>
                    <!-- <li><router-link to="/Staff-Profile">Staff Profile</router-link></li> -->
                    <li class="hide_for_now">
                        <router-link to="/Staff-Leave">Leaves</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Staff-Attendance">Holidays</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Staff-Holiday">Attendance</router-link>
                    </li>
                    <li class="hide_for_now">
                        <router-link to="/Add-Category">Settings</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu only_for_super_admin">
                <a href="#"><i class="fa fa-h-square"></i> <span> Health Care PR</span>
                    <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Hospitals">List HealthCare PR</router-link>
                    </li>
                    <li class="">
                        <router-link to="/Add-Hospital">Add HealthCare PR</router-link>
                    </li>
                    <!-- <li><router-link to="/Hospital-Profile">Hospital Profile</router-link></li> -->
                </ul>
            </li>
            <li class="submenu only_for_super_admin">
                <a href="#"><i class="fa fa-box-open"></i> <span> Offline Box</span>
                    <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Offline-Hospitals">List Box</router-link>
                    </li>
                    <li class="">
                        <router-link to="/Add-Offline-Hospital">Add Offline Box</router-link>
                    </li>
                    <!-- <li><router-link to="/Hospital-Profile">Hospital Profile</router-link></li> -->
                </ul>
            </li>
            <li class="submenu only_for_super_admin">
                <a href="#"><i class="fa fa-bell"></i> <span> Subscription</span>
                    <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Plans">Plans</router-link>
                    </li>
                    <li class="">
                        <router-link to="/Subscriber-List">Subscriber's List</router-link>
                    </li>
                    <li class="">
                        <router-link to="/Add-Subscription">Add Subscription</router-link>
                    </li>
                    <li class="">
                        <router-link to="/Custom-Subscription">Custom Subscription</router-link>
                    </li>
                </ul>
            </li>
            <li class="submenu navhide" v-if="$store.getters.canAccess(['working_hours'])">
                <a href="#"><span class="menu-side"><i class="fas fa-calendar-plus"></i></span>
                    <span>Schedules </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Schedules">Schedule List</router-link>
                    </li>
                    <li class="not_for_Super_admin">
                        <router-link to="/Add-Schedule">Add Schedule</router-link>
                    </li>
                    <li class="not_for_Super_admin hide_for_now">
                        <router-link to="/Edit-Schedule">Edit Schedule</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="
                $store.getters.canAccess([
                    'add_drug_category',
                    'add_drug_type',
                    'add_drug',
                ])
            ">
                <a href="#"><span class="menu-side"><i class="fas fa-tablets"></i></span>
                    <span> Pharmacy </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Add-Medicine">Add Medicine</router-link>
                    </li>
                    <li>
                        <router-link to="/Medicine-List">Medicine List </router-link>
                    </li>

                    <li>
                        <router-link to="/Dispensory">Dispensory </router-link>
                    </li>
                    <li>
                        <router-link to="/Dispensed-Drugs">Dispensed Drugs</router-link>
                    </li>

                    <li>
                        <!-- <router-link to="/Pharmacy_Category">Settings</router-link> -->
                    </li>
                </ul>
            </li>

            <!-- <li class="submenu navhide">
            <a href="#"><span class="menu-side"><i class="fa fa-pills"></i></span>
              <span> Administer Drugs </span> <span class="menu-arrow"></span></a>
            <ul style="display: none">
              <li>
                <router-link to="/Administer-Drugs">Administer Drugs
                </router-link>
                
              </li>
            </ul>
          </li> -->
            <li class="submenu navhide" v-if="$store.getters.canAccess(['lab_list'])">
                <a href="#"><span class="menu-side"><i class="fas fa-microscope"></i></span>
                    <span> Laboratory </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Specimen-Hub">Specimen hub</router-link>
                    </li>
                    <li>
                        <router-link to="/Lab-Department-list">Department List</router-link>
                    </li>
                    <li>
                        <router-link to="/Scientist-List">Scientist List</router-link>
                    </li>
                    <li>
                        <router-link to="/Laboratory-Reports">Reports</router-link>
                    </li>
                    <li>
                        <router-link to="/Lab-History">History</router-link>
                    </li>

                    <li>
                        <router-link to="/Add-Laboratory-Type">Settings</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide" v-if="$store.getters.canAccess(['radiology_list'])">
                <a href="#"><span class="menu-side"><i class="fas fa-x-ray"></i></span>
                    <span> Radiology </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Specimen-Hub-Radiology">Specimen hub</router-link>
                    </li>
                    <li>
                        <router-link to="/Radiology-Department-list">Department List</router-link>
                    </li>
                    <li>
                        <router-link to="/Radiologist-List">Radiologist List</router-link>
                    </li>
                    <li>
                        <router-link to="/Radiology-Reports">Reports</router-link>
                    </li>
                    <li>
                        <router-link to="/Radiology-History">History</router-link>
                    </li>

                    <li>
                        <router-link to="/Add-Radiology-Type">Settings</router-link>
                    </li>
                </ul>
            </li>
            <li class="submenu navhide" v-if="$store.getters.canAccess(['specialization_list'])">
                <a href="#"><span class="menu-side"><i class="fas fa-vials"></i></span>
                    <span> Specializations </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <!-- <li>
                    <router-link to="/Specimen-Hub">Specimen hub</router-link>
                  </li> -->
                    <!-- <li>
                      <router-link to="/Lab-Department-list"
                        >Department List</router-link
                      >
                    </li> -->
                    <!-- <li>
                      <router-link to="/Scientist-List"
                        >Scientist List</router-link
                      >
                    </li> -->
                    <li>
                        <router-link to="/Specialization-Requests">Requests</router-link>
                    </li>
                    <li>
                        <router-link to="/Specialization-History">History</router-link>
                    </li>

                    <li>
                        <router-link to="/Specialization-Settings">Settings</router-link>
                    </li>
                </ul>
            </li>
            <!-- TEMPLATES -->
            <li class="submenu navhide">
                <a href="#"><span class="menu-side"><i class="fas fa-book"></i></span>
                    <span> Templates </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Template-List">Template List</router-link>
                    </li>

                    <!-- <li>
                    <router-link to="/Lab-History">Request Template</router-link>
                  </li> -->
                </ul>
            </li>

            <li class="submenu navhide" v-if="$store.getters.canAccess(['add_room_type'])">
                <a href="#"><span class="menu-side"><i class="fas fa-procedures"></i></span>
                    <span>Allotments </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Allotted-rooms">Allotted Rooms </router-link>
                    </li>
                    <li>
                        <router-link to="/Room_Category">Settings</router-link>
                    </li>
                </ul>
            </li>
            <li class="submenu navhide" v-if="$store.getters.canAccess(['register_death', 'register_birth'])">
                <a href="#"><span class="menu-side"><i class="fas fa-file-medical"></i></span>
                    <span> Records </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Birth-Records">Birth Records</router-link>
                    </li>
                    <li>
                        <router-link to="/Death-Records">Death Records</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><i class="fa fa-ambulance"></i></span>
                    <span> Ambulance </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Ambulance-Call-List">Ambulance Call List</router-link>
                    </li>
                    <li>
                        <router-link to="/Ambulance-List">Ambulance List</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><img src="../../assets/assets/img/icons/shopping_bag.svg"
                            alt="" /></span>
                    <span> Inventory </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Stock-List">Item Stock List </router-link>
                    </li>
                    <li>
                        <router-link to="/Issued-Items">Issued Items</router-link>
                    </li>
                </ul>
            </li>
            <!-- #636675 -->

            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><img src="../../assets/assets/img/icons/menu-icon-06.svg"
                            alt="" /></span>
                    <span> Departments </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Departments">Department List</router-link>
                    </li>
                    <li>
                        <router-link to="/Add-Department">Add Department</router-link>
                    </li>
                    <li>
                        <router-link to="/Edit-Department">Edit Department</router-link>
                    </li>
                </ul>
            </li>

            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><img src="../../assets/assets/img/icons/menu-icon-09.svg"
                            alt="" /></span>
                    <span> Payroll </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Employee-Salary"> Employee Salary </router-link>
                    </li>
                    <li><router-link to="/Pay-Slip">Payslip </router-link></li>
                </ul>
            </li>
            <li class="navhide hide_for_now">
                <router-link to="/Chat"><span class="menu-side"><i class="far fa-comment-alt"></i></span>
                    <span>Chat</span></router-link>
            </li>
            <li class="submenu navhide hide_for_now">
                <!-- v-if="$store.getters.canAccess(['defult'])" -->
                <a href="#"><span class="menu-side"><i class="fas fa-phone"></i></span>
                    <span> Calls</span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Voice-call">Voice Call</router-link>
                    </li>
                    <li>
                        <router-link to="/Video-call">Video Call</router-link>
                    </li>
                    <!-- <li>
                <router-link to="/Incoming-call">Incoming Call</router-link>
              </li> -->
                </ul>
            </li>
            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><img src="../../assets/assets/img/icons/menu-icon-12.svg"
                            alt="" /></span>
                    <span> Email</span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Compose-Email">Compose Mail</router-link>
                    </li>
                    <li><router-link to="/Inbox">Inbox</router-link></li>
                    <li>
                        <router-link to="/Mail-view">Mail View</router-link>
                    </li>
                </ul>
            </li>
            <!-- <li class="navhide">
						<router-link to="/Patient-Bio-Data"><i class="fa fa-heartbeat"></i> <span>Patient Bio Data</span></router-link>
					</li> -->
            <li class="navhide hide_for_now" v-if="$store.getters.canAccess(['default'])">
                <router-link to="/Assets"><i class="fa fa-cube"></i> <span>Assets</span></router-link>
            </li>
            <li class="navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <router-link to="/Activities"><span class="menu-side">
                        <img src="../../assets/assets/img/icons/menu-icon-14.svg" alt="" /></span>
                    <span>Activities</span></router-link>
            </li>
            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><i class="fa fa-flag"></i> <span> Reports </span>
                    <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Expense-Report"> Expense Report </router-link>
                    </li>
                    <li>
                        <router-link to="/Invoice-Report"> Invoice Report </router-link>
                    </li>
                </ul>
            </li>
            <li class="submenu navhide hide_for_now" v-if="$store.getters.canAccess(['defult'])">
                <a href="#"><span class="menu-side"><img src="../../assets/assets/img/icons/menu-icon-15.svg"
                            alt="" /></span>
                    <span> Invoice </span> <span class="menu-arrow"></span></a>
                <ul style="display: none">
                    <li>
                        <router-link to="/Invoice-List"> Invoices List </router-link>
                    </li>
                    <li>
                        <router-link to="/Invoice-Grid"> Invoices Grid</router-link>
                    </li>
                    <li>
                        <router-link to="/Add-Invoice">Add Invoices</router-link>
                    </li>
                    <li>
                        <router-link to="/Edit-Invoice"> Edit Invoices</router-link>
                    </li>
                    <li>
                        <router-link to="/View-Invoice">Invoices Details</router-link>
                    </li>
                    <li>
                        <router-link to="/Invoice-Settings"> Invoices Settings</router-link>
                    </li>
                </ul>
            </li>

            <li class="only_for_super_admin">
                <router-link to="/Template-List">
                    <spa class="menu-side"><i class="fa fa-book"></i></spa><span>Template List</span>
                </router-link>
            </li>
            <li class="navhide" v-if="$store.getters.canAccess(['assign_permission'])">
                <router-link to="/Settings"><span class="menu-side"><i
                            class="fa fa-gear"></i></span><span>Settings</span></router-link>
            </li>
            <li class="only_for_super_admin" v-if="$store.getters.canAccess(['assign_permission'])">
                <router-link to="/View-Roles"><span class="menu-side"><i
                            class="fa fa-gear"></i></span><span>Settings</span></router-link>
            </li>
            <li class="">
                <a to="" @click.prevent="openPPTX()" style="cursor: pointer"><span class="menu-side"><i
                            class="fa fa-question"></i></span><span>Help (F1)</span></a>
            </li>

            <li class="logout-btn" @click="logout()" style="cursor: pointer">
                <a><span class="menu-side"><i class="fa fa-sign-out"></i></span>
                    <span>Logout</span></a>
            </li>
        </ul>

        <div class="noti-content" v-if="isInstitution == 'true'">
              <div class="noti-header">
                <span class="fas fa-hourglass-half icon"> </span>
              </div>
              <div class="noti-body">
                <!-- {{ trial_period }} -->
                <p class="main-text" v-if="trial_period > 0">Trial Period</p>
                <p class="main-text" v-else>{{plan?.plan?.plan_name}}</p>
                <p class="sub-text" v-if="trial_period > 0">{{ trial_period }} Days Remaining</p>
                <p class="sub-text" v-else>{{plan?.remaining_days}} Days Remaining</p>
              </div>
              <div class="noti-footer">
                <button class="request-button" @click="showSubscriptionModal()" v-if="trial_period > 0">
                  Subscribe Now
                </button>
                <button class="request-button" @click="showSubscriptionModal()" v-else>
                  Extend Subscription
                </button>
              </div>
            </div>
        <br />
        <br />
        <br />
        <br />
    </div>
</template>


<script>
import { store } from "@/store";
// import Subscription from "./Subscription.vue";
import { flutterEventKeys, postFlutterEvent } from "@/utils/flutterEvents";

export default {
    name: "SideBar",
    props: {
        id: String
    },

    data() {
        return {
            trial_period: localStorage.getItem("trial_period"),
            isInstitution: localStorage.getItem("isInstitution"),
            has_subscription:localStorage.getItem("has_active_subscription"),
            plan:null
        };
    },

    components: {
        // Subscription,
    },

    methods: {
        // new function
        handleKeyDown(event) {
            if (event.key === "F1") {
                event.preventDefault(); // Prevent the default F1 help dialog
                this.openPPTX();
            }
        },

        openPPTX() {
            // Change the path to match your PPTX file's location in the public directory
            const pptxUrl = `${process.env.BASE_URL}Help/StellaSync-Docs.mp4`;
            window.open(pptxUrl, "_blank");
        },

        logout() {
            if (this.$store.state.isOnFlutter) {
                postFlutterEvent(flutterEventKeys.logout)
            } else {
                localStorage.clear();
                this.$router.push({ name: "Welcome" });
            }
            // this.$router.push({ name: "Login" });
        },
        goto(route) {
            window.location = route;
            // this.$router.push({ name: route })
        },

        // canAccess(permissions) {
        // 	return store.getters.canAccess(permissions);
        // },
        /**
         * This Hides all Sidebar the Admin does not have access to
         */

        controlSidebar() {
            let sidebarLinks = document.querySelectorAll(".sidebar a[href]");
            if (sidebarLinks.length <= 0) {
                this.controlSidebar();
            } else {
                sidebarLinks.forEach((sidebar) => {
                    let routePermission = store.getters.getRoutePermission(
                        sidebar.getAttribute("href")
                    );

                    if (routePermission) {
                        if (!store.getters.adminHasPermission(routePermission)) {
                            sidebar.remove();
                        }
                    }
                });
            }
        },

        // controlSidebar() {
        //   let sidebarLinks = document.querySelectorAll(".sidebar a[href]");

        //   if (sidebarLinks.length > 0) {
        //     sidebarLinks.forEach((sidebar) => {
        //       let routePermission = this.getRoutePermission(
        //         sidebar.getAttribute("href")
        //       );

        //       if (routePermission) {
        //         if (!this.adminHasPermission(routePermission)) {
        //           sidebar.remove(); // Remove links based on permissions
        //         }
        //       }
        //     });
        //   } else {
        //     console.warn("No sidebar links found.");
        //   }
        // },

        showSubscriptionModal() {
            $(".sub_cont").modal("show");
        },
    },
    computed: {
        // ...mapGetters(["getRoutePermission", "adminHasPermission"]),
        isAnyRouteActive() {
            // Check if any route within the <ul> is active
            return this.$route.matched.some((route) => route.path.startsWith("/Schedules"));
            // Add more conditions for additional routes as needed
        },
    },

    mounted() {
        // this permission control issubject to re-editing sooner or later
        var role = localStorage.getItem("role");
        var institution = localStorage.getItem("institution");

        if (role == "Super Admin") {
            $(".navhide,.nnavhide").hide();
            $(".only_for_super_admin").show();
        }

        if (role == "Admin") {
            // $(".admin_nav,.nnavhide").show();
            $(".admin_nav").show();
            // $(".not_for_Super_admin").hide();
        } else if (role == "Admin") {
            $(".admin_nav").show();
        } else if (role == "Doctor") {
            $(".doc_nav").show();
        }
        if (role == "Finance") {
            $(".fin_nav").show();
        } else if (role == "Hospital") {
            $(".admin_nav").show();
        } else if (role == "GOPD") {
            $(".GOPD_nav").show();
        } else if (role == "Pharmacy") {
            $(".pha_nav").show();
        } else if (role == "Lab") {
            $(".lab_nav").show();
        } else if (role == "Radiology") {
            $(".rad_nav").show();
        } else if (role == "Nurse") {
            $(".nurse_nav").show();
        } else if (role == "Front Desk") {
            if (institution == "Admin") {
                $(".frontdesk_nav1").show();
                $(".frontdesk_nav2").hide();
            } else if (institution == "Clinic") {
                $(".frontdesk_nav2").show();
                $(".frontdesk_nav1").hide();
            }
        } else if (role == "Clinic") {
            $(".clinic_nav").show();
        }


        const elementId = this.$props.id;
        // Sidebar
        var Sidemenu = function () {
            this.$menuItem = $(`#${elementId} a .menu-side`);
        };
        function init() {
            var $this = Sidemenu;
            $(`#${elementId} a`).on("click", function (e) {
                if ($(this).parent().hasClass("submenu navhide")) {
                    e.preventDefault();
                }
                if (!$(this).hasClass("subdrop")) {
                    $("ul", $(this).parents("ul:first")).slideUp(350);
                    $("a", $(this).parents("ul:first")).removeClass("subdrop");
                    $(this).next("ul").slideDown(350);
                    $(this).addClass("subdrop");
                } else if ($(this).hasClass("subdrop")) {
                    $(this).removeClass("subdrop");
                    $(this).next("ul").slideUp(350);
                }
            });
            $(`#${elementId} ul li.submenu navhide a.active`)
                .parents("li:last")
                .children("a:first")
                .addClass("active")
                .trigger("click");
            // .css("color", "red");
        }
        // Sidebar Initiate
        init();
        this.controlSidebar();

        if (role != "Super Admin"&&localStorage.getItem("accessToken")!=='') {
        this.plan=JSON.parse(localStorage.getItem("current_plan"))
        }
    },
};
</script>